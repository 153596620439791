@import 'style-constants.scss';

.export-options-popover {
    border-radius: 4px;
    box-shadow: 0px 1px 6px $grey-darker-button;

    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 0px;
    }

    .popover-list {
        width: 238px;
        padding: 13px 0px;
    }

    .popover-list-item {
        padding: 7px 30px;
        color: $grey-dark-text;

        &:hover {
            color: $purple-dark;
            background-color: rgba($primary-color-700, 0.06);

            .list-item-text span {
                font-weight: 500;
            }
        }

        .list-item-text {
            margin: 0px;
        }

        .list-item-text span {
            font-size: $small;
            line-height: 21px;
            letter-spacing: 0.24px;
        }
    }
}
