@import "style-constants.scss";

.company-basic-information {
    .company-information-container {
        display: flex;
        justify-content: space-between;
    }

    .company-basic-information-form {
        width: 440px;
        margin-right: 40px;

        &.client {
            display: flex;
            flex-direction: column;
        }
    }

    .basic-settings-logo {
        display: flex;
        justify-content: center;

        .uploaded-picture {
            width: 100%;
        }
    }
}
