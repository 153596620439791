@import "style-constants.scss";
.expected-salary-dialog {
    .MuiDialog-container {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        .MuiPaper-root {
            width: 28.875rem;
            .col-1of2 {
                width: 23.875rem;
                margin-top: 0.2rem;
            }
            .col-2of2 {
                margin-top: 1.25rem;
            }
            .dialog-title-text {
                letter-spacing: 0.11px;
                font-weight: 500;
            }
            .MuiButton-label {
                font-weight: 500;
            }
        }
    }
}
