@import 'style-constants.scss';

.search-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .search-bar {
        width: 410px;
        height: 32px;
        background-color: $grey-light;
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin-top: 12px;
        
        .search-field {
            width: 100%;

            .search-icon {
                margin-left: 9px;
                margin-right: 18px;
            }

            input {
                color: $grey-normal-title;
                font-size: 14px;
                letter-spacing: 0.24px;
            }
  
            &.Mui-focused {
                border: 1px solid $primary-color-700;
                border-radius: 4px;

                .search-icon {
                    color: $primary-color-700;
                }
            }
        }
    }

    .search-results-dropdown {
        position: absolute;
        max-height: 70vh;
        width: 410px;
        overflow: hidden;
        box-shadow: 0px 1px 10px $grey-darker-button;
        background: $white;
        padding: 19px 30px 5px 30px;
        top: calc($header-height - 44px);
        z-index: 1000;

        .loader {
            text-align: center;
            padding: 20px;
            p {
                border: 4px solid #f3f3f3;
                border-radius: 50%;
                border-top: 4px solid #5066e7;
                width: 32px;
                height: 32px; 
                animation: spin 2s linear infinite;
                display: inline-block;
              }

              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
        }

        .people-results-list {
            margin-bottom: 15px;
        }

        .result-list-item {

            &:before {
                content: '';
                display: block;
                height: 10px;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            .MuiListItem-root {
                padding: 0px 0px 11px 0px;
            }

            .MuiListItemText-primary {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.18px;
                color: $primary-color-900;
            }
    
            .MuiListItemText-secondary {
                font-size: 10px;
                letter-spacing: 0.34px;
                color: $grey-dark;
            }

            .MuiListItemAvatar-root {
                margin-left: 6px;
            }
            
            .MuiListItem-button:hover {
                background-color: unset;
            }
        }

        .MuiList-padding {
            padding-bottom: unset;
        }
    }
}
