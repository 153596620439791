@import 'style-constants.scss';

.table-content-wrapper {
    position: relative;
    min-height: 150px;
    border-radius: 10px;
    scroll-margin-top: 170px;
    overflow: initial;
    overflow-x: scroll;
    display: grid;
    grid-auto-flow: row;

    .table-loader {
        position: absolute;
        top: 55%;
        left: 50%;
        z-index: 1;

        svg {
            color: $purple-dark;
        }
    }

    .table-checkbox {
        width: min-content;
        height: min-content;
        padding: 0;

        &.Mui-checked {
            svg {
                color: $purple-dark;
            }
        }
    }

    .MuiTable-root {
        table-layout: fixed;
    }

    .MuiTableCell-head {
        position: relative;
        color: $grey-dark;
        font-size: $medium-title;
        text-transform: uppercase;
        letter-spacing: 0.12px;
        padding: 20px 20px;
        padding-right: 5px;
        border-bottom: none;
    }

    .MuiTableCell-body {
        color: $grey-dark;
        font-size: $medium;
        letter-spacing: 0.11px;
        border-bottom: none;
        padding: 7.5px 20px;
        padding-right: 5px;
        text-decoration: none;
        overflow-wrap: break-word;
        vertical-align: top;

        .with-checkboxes {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        * {
            overflow-wrap: anywhere;
        }
    }

    .MuiTableRow-root {
        &:first-child .MuiTableCell-body {
            padding-top: 15px;
        }

        &:last-child .MuiTableCell-body {
            padding-bottom: 15px;
        }
    }

    .MuiTableSortLabel-active {
        color: $grey-dark;

        svg {
            fill: $purple-dark;
        }
    }

    .table-horizontal-separator {
        border: 1px solid $grey-lighter;
        opacity: 0.5;
        position: absolute;
        width: 96%;
        margin-left: 2%;
    }

    .end-alignment {
        justify-content: flex-end;
        text-align: right;
    }

    .legend-items-wrapper,
    .legend-item {
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
        align-items: center;
        margin: auto;
    }

    .table-loader-wrapper {
        min-height: 200px;
    }

    .table-pagination-wrapper {
        padding: 5px 29px 19px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .table-pagination-navigation {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 25px;
        }

        .table-pagination-controls {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 24px;

            svg {
                &:hover {
                    cursor: pointer;
                }
            }

            svg.arrow-disabled {
                &:hover {
                    cursor: auto;
                }

                fill: $grey-dark;
            }
        }

        .MuiTypography-body2 {
            letter-spacing: 0.14px;
            color: $purple-dark;

            span {
                font-weight: bold;
            }
        }
    }

    .empty-table-text {
        text-align: center;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .last-column {
        p {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}