@import "style-constants.scss";

.candidate-upcoming-interviews-wrapper {
    margin-top: 30px;

    .candidate-upcoming-interviews-list {
        padding-top: 20px;
        display: grid;
        gap: 20px;

        .candidate-upcoming-interview-card {
            position: relative;
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 3px $grey-darker-button;
            border-radius: 4px;
            padding: 50px 45px 30px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .candidate-upcoming-interview-job-position {
            font-size: $medium-title;
            line-height: 22px;
            font-weight: 500;
            letter-spacing: 0.61px;
            color: $purple-dark;
        }

        .candidate-info {
            padding: 20px 0px 30px;
            display: flex;
            align-items: center;

            .candidate-avatar {
                width: 52px;
                height: 52px;
                margin-right: 20px;
            }

            .candidate-name {
                font-size: $medium;
                line-height: 19px;
                letter-spacing: 0.54px;
                color: $purple-dark;
            }
        }

        .candidate-upcoming-interview-location {
            font-size: $medium;
            line-height: 19px;
            letter-spacing: 0.15px;
            color: $medium-dark-shade-blue;

            span {
                text-decoration: underline;
            }
        }

        .candidate-upcoming-interview-time-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-end;
            height: 100%;
        }

        .candidate-upcoming-interview-day-month-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;

            .day {
                font-size: 30px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.3px;
                color: $purple-dark;
                margin-bottom: 7px;
            }

            .month {
                font-size: $medium;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.3px;
                color: $purple-dark
            }
        }

        .candidate-upcoming-interview-hours-range {
            font-size: $medium;
            line-height: 19px;
            letter-spacing: 0.15px;
            color: $medium-dark-shade-blue;
        }
    }
}
