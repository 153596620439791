@import 'style-constants.scss';

.projects-overview-container {
    .project-boxes-wrapper {
        display: grid;
        gap: 30px;
        grid-auto-flow: column;
    }
}

