@import "style-constants.scss";

.kpi-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 5px;

    .kpi-item {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 30% 70%;
        gap: 10px;
        background-color: $white;
        border-radius: 15px;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 36px;

        .kpi-item-loader {
            position: absolute;
            left: 50%;
        }

        .kpi-item-title,
        .kpi-item-percentage-change-text {
            color: $grey-lighter;
            line-height: 16px;
        }

        .kpi-item-amount-wrapper {
            display: grid;
            grid-auto-flow: column;
            gap: 5px;
            align-items: center;
            justify-content: flex-start;
        }

        .kpi-item-amount {
            font-weight: bold;
            line-height: 16px;
            letter-spacing: 0.35px;
        }

        .kpi-item-percentage-change-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            &.green {
                .kpi-item-percentage-change-icon {
                    color: $secondary-color;
                }
            }

            &.red {
                .kpi-item-percentage-change-icon {
                    color: $red;
                }
            }
        }
    }

    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .kpi-item {
            padding: 10px 20px;

            .kpi-item-title,
            .kpi-item-percentage-change-text,
            .kpi-item-percentage-change-icon {
                font-size: $medium;
            }

            .kpi-item-amount {
                font-size: $large-title;
            }
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .kpi-item {
            padding: 10px 20px;

            .kpi-item-title,
            .kpi-item-percentage-change-text,
            .kpi-item-percentage-change-icon {
                font-size: $small;
            }

            .kpi-item-amount {
                font-size: $medium-title;
            }
        }
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .kpi-item {
            padding: 10px 20px;

            .kpi-item-title,
            .kpi-item-percentage-change-text,
            .kpi-item-percentage-change-icon {
                font-size: $tiny;
            }

            .kpi-item-amount {
                font-size: $medium;
            }
        }
    }
}
