@import 'style-constants.scss';

.job-type-selection-wrapper {
    display: flex;
    flex-flow: column;

    .primary-button {
        width: fit-content;
        align-self: center;
    }

    .showcase-banner-wrapper {
        position: relative;
        display: flex;
        max-width: $paper-element-sm;
        margin: 7px auto 0;
        border: 1px solid rgba($medium-dark-shade-blue, 0.5);

        .col-1of2 {
            padding: 40px 0px 0px 30px;
            display: flex;
            flex-direction: column;

            h3 {
                font-size: $medium;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: 0.54px;
                color: $purple-dark;
                padding-top: 60px;
            }

            .motion-software-logo-icon {
                width: 30px;
                height: 16px;
            }
        }

        .showcase-image {
            max-width: 236.5px;
        }

        &.mobile {
            max-width: 100%;
            width: 100%;
            height: 140px;
            margin: -50px 0px 30px;
            border: none;

            .col-1of2 {
                width: 100%;
                padding: 0 50px;
                z-index: 1;
                align-self: center;

                h3 {
                    padding: 0;
                    text-align: center;
                    color: $white;
                }
            }

            .col-2of2 {
                position: absolute;
                width: 100%;
                height: 100%;

                .showcase-image {
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}
