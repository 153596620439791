@import "style-constants.scss";

.profile-cv-sections {

    .candidate-cv {
        display: flex;
        flex-direction: column;
        margin: 1.67rem auto auto;
        padding-top: 1.87rem;
        border-radius: 10px;

        .cv-completion-page {
            margin: auto;
            box-shadow: 0px 1px 3px $grey-darker-button;
            border-radius: 10px;

            &.mobile {
                margin: 0 15px;
            }
        }
    }

    .upload-cv-wrapper {
        .raw-cv-profile-content,
        .import-pdf-container {
            width: 100%;
        }
    }
}
