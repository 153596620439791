@import 'style-constants.scss';

.performance-bar {
    display: flex;
    justify-content: space-between;
    width: inherit;
    height: inherit;

    .bar-cell {
        width: 18.6%;
        height: 100%;
        background:#EAF0F4;

        &.cell-pos-first {
            border-radius: 10px 0px 0px 10px;
        }

        &.cell-pos-last {
            border-radius: 0px 10px 10px 0px;
        }

        &.cell-color-green {
            background: $secondary-color-100;
        }

        &.cell-color-pale-red {
            background:#FFCCD8;
        }

        &.cell-color-red {
            background:#DC083A;
        }
    }
}
