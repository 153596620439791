@import 'style-constants.scss';

.profiles-table-wrapper {
    .profiles-table-container {
        .logo-name-wrapper {
            display: inline-flex;
            align-items: center;
            pointer-events: none;

            .avatar-logo-image {
                width: 30px;
                height: 30px;
                margin-right: 11px;
                background-color: $grey-avatar-background;

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .MuiAvatar-colorDefault {
                background-color: $grey-avatar-background;
            }
        }
    }
}
