@import "style-constants.scss";

.candidate-details-page-wrapper {
    position: relative;
    width: 100%;

    .application-video-introduction-content-wrapper {
        margin-top: 30.51px;
        padding: 30px 40px;

        .video-wrapper {
           height: auto;
        }
    }

    .historical-notes-wrapper {
        margin: 30px 0px;
    }

    .application-cv-information-wrapper {
        margin-top: 30.18px;
    }
    .candidate-application-page-loader {
        position: absolute;
        top: 250px;
        left: 50%;
    }

    .previous-button {
        margin-bottom: 35px;
    }

    .rate-candidate-banner-wrapper {
        margin-top: 60px;
    }
}
