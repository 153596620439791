@import 'style-constants.scss';

.section-banner-wrapper {
    display: flex;
    flex-direction: column;

    .section-banner-content {
        padding: 40px;
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        align-items: center;
        flex-grow: 1;
    }

    .section-banner-text-wrapper {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
    }

    .section-banner-text {
        font-size: $medium-title;
        font-weight: 400;
        letter-spacing: 0.31px;
        color: $purple-dark;
    }

    .section-banner-action {
        display: flex;
        margin-left: auto;
    }
}
