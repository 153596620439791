@import "style-constants.scss";

.candidate-invitation-link {
    margin: 0px auto 30px;
    width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .header-text {
        font-size: $medium;
        line-height: 19px;
        letter-spacing: 0.11px;
        font-weight: 500;
        color: $purple-dark;
        margin-bottom: 6.7px;
    }

    .sub-header-text {
        font-size: $small;
        letter-spacing: 0px;
        color: $purple-dark;
        margin-bottom: 13px;
    }

    .link-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 367px;
        height: 30px;

        .link-container-text-wrapper {
            border: 1px solid $actions-border;
            border-radius: 4px;
            width: 307px;
            height: 100%;
            padding: 0px 30px 0px 10px;
            display: flex;
            align-items: center;

            p {
                font-size: 12px;
                letter-spacing: 0.08px;
                color: $grey-dark-text;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .primary-button {
            padding: 2px 20px;
            min-width: 60px;
            width: 60px;
            margin-left: -10px;

            &:hover {
                background-color: $purple-dark;
            }
        }
    }
}
