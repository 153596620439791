@import "style-constants.scss";

.applications-and-interviews-wrapper {
    position: relative;
    margin: auto;

    .applications-and-interviews-loader {
        position: absolute;
        top: 250px;
        left: 50%;
    }

    .find-a-match-button {
        display: flex;
        width: fit-content;
        margin: 0 auto 30px;
    }
}
