@import "style-constants.scss";

.personal-info-content {

    .personal-info-wrapper {
        width: 100%;

        .actions-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 10px 0;

            .personal-form-save-button,
            .basic-info-save-button,
            .contract-information-save {
                margin-right: 20px;
            }
        }

        .personal-info-content {
            padding: 40px;
            margin: 0px auto;
            width: 100%;
        }

        .personal-info-form-field {
            margin-bottom: 34px;
            text-align: left;

            &.last {
                margin-bottom: 0;
            }
        }
    }

    .historical-notes-wrapper {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .personal-info-loader {
        position: absolute;
        top: 250px;
        left: 54%;
    }
}
