@import "style-constants.scss";

.subsection-wrapper {
    display: flex;
    padding: 30px 0;

    .subsection-container {
        margin-left: 10px;
    }

    .subsection-title {
        color: $purple-dark;
        font-size: $medium;
        letter-spacing: 0.28px;
        word-break: break-all;
    }

    .subsection-header-wrapper {
        display: grid;
        grid-auto-flow: column;
        align-items: baseline;
        justify-content: flex-start;
    }

    .subsection-content {
        color: $purple-dark;
        font-size: $medium;
        letter-spacing: 0px;
        opacity: 0.8;
        word-break: break-all;
    }

    .icon {
        min-width: 24px;
        min-height: 24px;
    }
}
