@import 'style-constants.scss';

.submenu {
    width: calc($submenu-width + 15px);
    margin-left: -15px;
    position: fixed;
    left: $navigation-width;
    top: 0;
    height: 100%;
    z-index: 1002;
    transition: all 0.3s;

    &.closed-navigation {
        left: $navigation-width-closed;
    }

    .MuiDrawer-paperAnchorDockedLeft {
        border: none;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        overflow: hidden;
    }

    .profile {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 30px 4.5px 0px 25px;

        .avatar {
            width: 33px;
            height: 33px;
            background-color: $grey-avatar-background;

            .MuiAvatar-img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .MuiAvatar-colorDefault {
            background-color: $grey-avatar-background;
        }

        .profile-name {
            font-size: $small;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0.24px;
            max-width: 100px;
            color: $purple-dark;
            margin-left: 20px;
            white-space: break-spaces;
            word-break: break-word;
        }

        .profile-label {
            width: 100%;
            font-size: $smallest;
            line-height: 16px;
            letter-spacing: 0.21px;
            color: $purple-dark;
            margin-left: 20px;
        }

        .profile-divider-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 6px;
            padding-bottom: 7px;
            padding-left: 5px;

            .profile-divider {
                width: 146px;
                height: 1px;
                background-color: $purple-dark;
            }
        }
    }

    .submenu-list {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .training-title-content {
        width: 90%;

        .training-title-employee {
            margin-bottom: 30px;
        }

        .training-title-employee,
        .training-title {
            color: $primary-color-400;
            font-weight: 500;
            text-align: left;
            white-space: pre-line;
            line-height: initial;
            margin-left: 15px;
        }

        .training-general-settings {
            margin: 30px 15px 15px 15px;
            text-align: start;
            color: $grey-lighter;
        }
    }

    .MuiDrawer-paper {
        position: relative;
        white-space: nowrap;
        background-color: transparent;

        & > div {
            background-color: $white;
        }
    }

    .list-item {
        padding: 17.5px 15px 17.5px 29px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        transition: none;

        .MuiListItemText-root {
            margin: 0px;
        }

        span {
            font-size: $small;
            line-height: 17px;
            letter-spacing: 0.24px;
            color: $purple-dark;
        }

        &.Mui-selected {
            background-color: $purple-darker;

            span {
                color: $white;
            }
        }

        .notification-badge {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: $red-darker;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge-text {
                font-size: 10px;
                line-height: 1;
                color: $white;
            }
        }
    }

    .submenu-action-buttons-wrapper {
        position: sticky;
        z-index: 1;
        display: grid;
        grid-auto-flow: row;
        gap: 10px;
        padding-top: 25px;
        padding-left: 29px;
        border-bottom-right-radius: 15px;
    }

    @media screen and (max-width: $mobile) {
        display: none;
    }
}
