@import "style-constants.scss";

.know-your-team-wrapper {
    margin: 0px auto;
    width: 100%;

    .actions-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        width: 100%;
    }

    .know-your-team-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
    }

    .view-all-btn,
    .view-all:hover {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-top: 25px;
    }
}
