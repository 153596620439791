@import 'style-constants.scss';

.job-status {
    text-transform: capitalize;
    background-color: transparent;
    border-radius: 4px;
    padding: 4px 17px;
    max-width: 120px;
    text-align: center;
    cursor: pointer;

    &.job-status-open {
        background-color: $secondary-color-150;
        color: $actions-green;
    }

    &.job-status-closed {
        background-color: $red-light;
        color: $red-darker;
    }

    &.job-status-profiles-sent-to-client {
        background-color: $secondary-color-150;
        color: $actions-green;
    }

    &.job-status-interview-scheduled {
        background-color: $secondary-color-150;
        color: $actions-green;
    }

    &.job-status-awaiting-interview-feedback {
        background-color: $secondary-color-150;
        color: $actions-green;
    }

    &.job-status-won {
        background-color: $red-light;
        color: $red-darker;
    }

    &.job-status-lost {
        background-color: $red-light;
        color: $red-darker;
    }

    &.job-status-expired {
        background-color: $red-light;
        color: $red-darker;
    }
}
