@import 'style-constants.scss';

.your-profile-wrapper {
    display: flex;
    align-items: center;
    flex-flow: column;
    
    .invalid-field-wrapper {
        display: flex;
        align-items: center;
        color: $red-darker;
        padding-top: 11px;
        margin-top: -25px;

        .error-icon {
            width: 22px;
            height: 22px;
            margin-right: 9px;
        }

        span {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.25px;
        }
    }
}
