@import "style-constants.scss";

.client-project-wrapper {
    .client-project-title {
        color: $purple-dark;
        font-size: $medium;
        letter-spacing: 0.11px;
        margin-bottom: 20px;
    }

    .client-project-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000033;
        border-radius: 10px;
        padding: 30px 40px;

        &.clickable {
            &:hover {
                cursor: pointer;
            }
        }

        .client-project-header-wrapper {
            display: flex;
            justify-content: space-between;

            .flex-aligned-wrapper {
                display: flex;
                align-items: center;
            }

            .project-title-container {
                display: flex;
                justify-content: flex-start;
                font-size: $large-title;
                font-weight: 500;
                color: $purple-dark;
                letter-spacing: 0.26px;
            }

            .project-type-container {
                display: flex;
                justify-content: flex-start;
                font-weight: 500;
                white-space: nowrap;
                width: fit-content;
                padding: 2px 9px;
                border-radius: 4px;
                margin-left: 10px;

                &.billable-project {
                    background-color: rgba($secondary-color, 0.1);
                    color: $secondary-color;
                }

                &.non-billable-project {
                    background-color: rgba($red-darker, 0.1);
                    color: $red-darker;
                }

                &.bench-activity {
                    background-color: rgba($grey-dark, 0.1);
                    color: $grey-dark;
                }
            }

            .calendar-project-date-container {
                margin-top: auto;
                margin-bottom: auto;
                width: 220px;
                font-size: 14px;

                .calendar-project-date-wrapper {
                    display: flex;
                    justify-content: space-between;

                    .date-container {
                        display: flex;

                        .calendar-icon-container {
                            padding-top: 2px;

                            .calendar-icon {
                                width: 25px;
                                height: 16px;
                            }

                            .calendar-icon.start {
                                color: #b3edd2;
                            }

                            .calendar-icon.end {
                                color: #ffccd8;
                            }
                        }

                        .date-content {
                            color: $purple-dark; 
                            margin-top: auto;
                            margin-bottom: auto;
                            letter-spacing: 0.24px;
                            font-weight: 400;
                            margin-left: 5px; 
                        }
                    }
                }
            }
        }

        .calendar-project-summary-container {
            font-size: 14px;
            color: #7e7e7e;
            padding-bottom: 19px;
            font-weight: 400;
            border-bottom: 1px solid $grey-border;
            letter-spacing: 0.24px;

            .client-name-paragraph {
                margin-top: 12px;
                margin-bottom: 12px;
            }

            .description-paragraph {
                margin-top: 30px;
                margin-bottom: 22px;
                letter-spacing: 0.28px;
                font-size: $medium;
                color: $purple-dark; 
            }

            .client-name-link {
                color: $primary-color-400;
                text-decoration: underline;
            }
        }

        .outcome-spending-container {
            display: flex;

            .spending-item-wrapper {
                display: flex;
                justify-content: space-between;
                margin-right: 30px;

                .item-label {
                    color: $purple-dark;
                    font-size: $small;
                    font-weight: 400; 
                    letter-spacing: 0.18px;
                }

                .amount {
                    color: $purple-dark;
                    font-size: $small;
                    font-weight: 500;
                    margin-left: 10px;
                    letter-spacing: 0.18px;
                }
            }
        }

        .contacts-container {
            display: flex;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $grey-border;

            .contact-item-container {
                margin-right: 50px;

                .profile-container {
                    .contact-item-icon-container {
                        padding-top: 4px;

                        .contact-item-icon {
                            width: 17px;
                            height: 17px;
                        }
                    }
                }

                .email-container {
                    .contact-item-icon-container {
                        padding-top: 4px;

                        .contact-item-icon {
                            width: 20px;
                            height: 19px;
                        }
                    }
                }

                .phone-container {
                    .contact-item-icon-container {
                        padding-top: 4px;

                        .contact-item-icon {
                            width: 20px;
                            height: 18px;
                        }
                    }
                }

                .contact-item-wrapper {
                    display: flex;
                    color: #7e7e7e;

                    .contact-item-icon-container {
                        .contact-item-icon {
                        }
                    }

                    .contact-item-content {
                        margin-left: 10px;
                        margin-top: auto;
                        margin-bottom: auto;
                        color: $purple-dark;
                        font-size: $small;
                        letter-spacing: 0.24px;  
                    }
                }
            }
        }

        .people-container {
            padding-top: 20px;

            .people-container-members {
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                gap: 6px;
            }

            .add-person-button {
                padding: 0px;
                min-width: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-left: 6px;
                color: #173FCB;
                border: 2px solid #173FCB;
            }

            .profile-image {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

            .people-title-container {
                color: $purple-dark;
                font-size: $small;
                letter-spacing: 0.18px; 
                padding-bottom: 14px;
                font-weight: 500;

                .people-label-icon.all-employees {
                    width: 100%;
                    height: 65%;
                    color: #7e7e7e;
                    position: relative;
                }

                .people-label-text {
                    font-size: 9px;
                    text-align: center;
                    color: #7e7e7e;
                }
            }
        }

        .client-project-container-btns-wrapper {
            margin-top: 10px;
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            justify-content: center;
        }

        .action-button.hidden {
            display: none;
        }

        .employees {
            hr {
                margin-top: 20px;
                border: 1px solid $grey-border;
            }
        }
    }
}
