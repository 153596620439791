@import 'style-constants.scss';

.primary-button {
    color: $white;
    background-color: $purple-dark;
    border-radius: 10px;
    border: 1px solid;
    border-color: transparent;
    padding: 10px;
    font-weight: 400;
    letter-spacing: 1.07px;
    line-height: 16px;
    text-transform: none;

    &.light {
        background-color: $white;
        color: $purple-dark;
        border: none;
        padding: 11px;
    }

    &:hover:not(.light) {
        background-color: #e4e4e4;
        color: $purple-dark;
        border: 1px solid $purple-dark;

        svg {
            fill: $purple-dark;
        }
    }

    &.white-border {
        border: 1px solid $white;
    }

    .MuiButton-label {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 10px;
    }

    svg {
        fill: $white;
    }

    @media screen and (max-width: $small-desktop) {
        font-size: $tiny;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        font-size: $small;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        font-size: $small;
    }
}
