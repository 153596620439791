@import 'style-constants.scss';

.project-details-wrapper {
    margin-top: 30px;

    .project-details-loader {
        position: absolute;
        top: 250px;
        left: 54%;
    }
}