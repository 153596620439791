@import 'style-constants.scss';

.company-information-wrapper {

    .company-account-manager-form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 36px;
    }

    .company-account-manager-form > * {
        width: 100%;
    }
}
