.bank-details-wrapper {
    .inner-content-wrapper {
        padding: 40px 30px 6px 30px;

        .bank-details-form {
            .cols { display: -moz-flex; display: -ms-flex; display: -o-flex; display: flex; flex-wrap: wrap; margin: 0 -10px; }
            .col { width: 100%; padding: 0 10px 34px 10px; }
            .col-1of2 { width: 50%; }
            .col-1of3 { width: 33.33%; }
            .col-2of3 { width: 66.66%; }
            .col-1of4 { width: 25%; }
            .col-2of4 { width: 50%; }
            .col-3of4 { width: 75%; }

            .MuiFormControl-root {
                width: 100%;
                color: #7E7E7E;
            }
        }
    }
}
