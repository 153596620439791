@import "style-constants.scss";

.section-actions-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    min-height: 38px;
    gap: 8px;

    .no-break-button {
        inline-size: max-content;
    }

    .MuiTypography-h1 {
        color: $purple-dark;
        font-size: $medium;
        font-family: Roboto;
        font-weight: bold;
        letter-spacing: 0.11px;
        text-transform: uppercase;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-h1 {
            font-size: $smallest;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h1 {
            font-size: $medium;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h1 {
            font-size: $medium;
        }
    }
}