@import "style-constants.scss";

.cultural-fit-rejection-banner-wrapper {
    background-color: $primary-color-light;
    box-shadow: 0px 1px 3px #79797929;
    margin: auto;
    padding: 30px 40px;
    margin-bottom: 30px;
    border-radius: 4px;

    .cultural-fit-rejection-banner-container {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .cultural-fit-rejection-icon-wrapper {
        margin-bottom: 16px;
    }

    .cultural-fit-rejection-banner-title {
        color: $purple-dark;
        letter-spacing: 0.61px;
        font-size: $medium-title;
        line-height: 22px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .cultural-fit-rejection-banner-message {
        font-size: $medium;
        color: $purple-dark;
        letter-spacing: 0.14px;
        line-height: 24px;
        text-align: center;
    }

    .cultural-fit-rejection-banner-action-wrapper {
        margin-top: 10px;
    }

    .box-wrapper {
        position: relative;
        display: inline-flex;

        .circular-progress {
            &.background { 
                svg {
                    color: $grey-disabled;
                }
            }

            &.foreground { 
                position: absolute;
                left: 0;

                svg {
                    color: $purple-dark;
                }
            }
        }

        .days-left-box {
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $purple-dark;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.28px;
    
            .days-left {
                font-size: $large-title;
                line-height: 20px;
                letter-spacing: 0.4px;
                font-weight: bold;
            }
        }
    }
}
