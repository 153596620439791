@import "style-constants.scss";


.cv-profile-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $grey-dark;
    margin-bottom: 20px;

    .intro-container h1 {
        color: $purple-dark;
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0.31px;
        margin-bottom: 10px;
        line-height: 24px;
        
        @media screen and (max-width: $mobile) {
            font-size: 18px;
            letter-spacing: 0.21px;
            margin-bottom: 5px;            
        }
    }
    
    .intro-container {
        max-width: 60%;
    }
    
    .intro-container h3 {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.23px;
        margin-bottom: 8px;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            letter-spacing: 0.21px;     
        }
    }
    
    .intro-container p {
        font-size: 13px;
        letter-spacing: 0.14px;

        @media screen and (max-width: $mobile) {
            font-size: 12px;
            letter-spacing: 0.15px;            
        }
    }

    .avatar-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;

        @media screen and (max-width: $mobile) {
            width: 65px;          
            height: 65px;          
        }
    }
}
