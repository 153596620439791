@import "style-constants.scss";

.upload-files-container {
    .attach-contract-label {
        margin-bottom: 20px;
        color: $grey-dark;
        font-size: 16px;
        letter-spacing: 0.15px;
    }

    .dropzone {
         outline: none;

        .files-upload {
            display: none;
        }

        .inner-container {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%237E7E7E66' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 4px;
            border: 1px solid $white;
        
            padding: 15px 0px 30px 0px;

            .upload-files-message {
                display: flex;
                justify-content: center;
                margin-bottom: 15px;
        
                h4 {
                    letter-spacing: 0.11px;
                    color: $grey-dark;
                    font-size: 16px;
                }
            }
        
            .upload-files-or-message {
                display: flex;
                justify-content: center;
                margin-bottom: 25px;
                letter-spacing: 0.11px;
                color: $grey-dark;
                font-size: 14px;
            }
        
            .browse-files-wrapper {
                display: flex;
                justify-content: center;
            }

            &.variant-new { 
                background-color: $grey-cv-box;
                display: grid;
                justify-content: center;
                gap: 10px;

                span {
                    margin: auto;
                    color: $grey-dark;
                    font-size: $small;
                    letter-spacing: 0.13px;
                }

                .browse-button {
                    border: 0px;
                    background-color: transparent;
                    color: #0D37C9;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .accepted-formats {
            color: $purple-dark;
            font-size: $small;
            opacity: 0.8;
        }

        .inner-container.drop {
            background-color: rgba(0, 0, 0, 0.04);
            opacity: 0.5;
        }
    }
}

.upload-files-container.with-files-attached {
    height: inherit;
}
