@import 'style-constants.scss';

.external-job-id-icon {
    width: 25px;
    height: 25px;
}

.external-job-id-label-custom-tooltip {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0px 1px 3px $grey-shadow;
    color: $grey-dark-text;
    background-color: $white;
    font-size: $small;
    font-weight: 300;
    letter-spacing: 0.24px;
    min-width: 106px;
    padding: 15px 0px;
    margin: 7px 0px;
    left: -94px;
    z-index: 1;

    .tooltip-content {
        display: grid;
        gap: 5px;
        align-items: center;

        .external-job-id {
            padding: 0px 15px;
        }

        .copy-btn {
            border-radius: 0px;
            color: $grey-dark-text;
            font-size: $small;
            font-weight: 300;
            letter-spacing: 0.24px;
            text-transform: none;
            padding: 0px 15px;

            .MuiButton-label {
                display: grid;
                justify-content: flex-start;
                align-items: center;
                grid-auto-flow: column;
                gap: 5px;

                .MuiButton-startIcon {
                    margin: 0;

                    svg path {
                        fill: $grey-dark-text;
                    }
                }
            }
        }
    }
}
