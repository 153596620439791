@import 'style-constants.scss';

.mobile-header {
    display: none;

    @media screen and (max-width: $mobile) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 32px;
        position: sticky;
        top: 0;
        left: 0;
        height: 70px;
        background-color: $purple-dark;
        z-index: 1300;

        .mobile-header-left {
            display: flex;
            align-items: center;
        }

        .btn-icon-mobile {
            &.menu-icon {
                margin-right: 16px;
            }
            
            svg {
                color: $purple-light-nav; 
                width: 30px;
                height: auto;
            }
        } 
        
        .motion-logo {
            width: 52px;
            height: auto;
        }

        &.hidden {
            display: none;
        }

        .header-mobile-search {
            margin-left: 20px;

            &.search-wrapper {
                height: auto;
                width: 100%;
                max-width: 420px;

                .search-bar {
                    height: 40px;
                    width: 100%;
                    margin-top: 0;
                }

                .search-field {
                    padding-right: 12px;

                    .MuiInputAdornment-positionStart {
                        margin-right: 12px;

                        .search-icon {
                            display: none;
                        }
                    }

                    &.Mui-focused {
                        border: none;
                    }
                }

                .search-results-dropdown {
                    top: 56px;
                    width: 450px;
                    max-height: calc(100vh - 70px);
                    overflow: auto;
                    padding: 8px 20px 5px;

                    & > button {
                        padding-left: 0px;

                        .MuiIconButton-label {
                            align-items: flex-end;
                        }

                        .search-icon {
                            color: $primary-color-700;
                            width: 16px;
                            height: auto;
                            margin-right: 8px;
                        }
                    }

                    .all-results-title {
                        font-size: 14px;
                        color: $primary-color-700;
                    }

                    .search-menu h5 {
                        color: $grey-dark;
                        font-size: 14px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile-nav) {
        padding-left: 2px;
        padding-right: 18px;

        .header-mobile-search {
            &.search-wrapper {
                .search-results-dropdown {
                    width: calc(100% + 78px);
                    left: -60px;
                }
            }
        }
    }
}
