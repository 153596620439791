
.overtime-banner-wrapper {
    .overtime-banner-points-wrapper {
        p {
            margin-bottom: 20px;
        }

        ul {
            list-style-type: none;

            p {
                margin-bottom: 2px;
            }
        }
    }
}
