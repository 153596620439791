@import "style-constants.scss";

.agency-note-container {
    margin-top: 18.7px;
    .note-header {
        display: flex;
        h4 {
            margin-left: 10.71px;
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;
            font-weight: 500;
        }
    }
    .note-body {
        margin-top: 20px;
        .note-content {
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;
            opacity: 0.8;
            font-family: "Roboto", sans-serif;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }
}
