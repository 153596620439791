@import "style-constants.scss";

.start-candidate-journey-mobile-wrapper {
    .start-candidate-journey-steps-container {
        .start-candidate-journey-header {
            color: $purple-dark;
            font-size: $medium-title;
        }

        .start-candidate-journey-sub-header {
            color: $purple-dark;
            font-size: $medium;
        }

        .candidate-job-loader {
            margin: auto;
        }

        .seen-job-wrapper {
            width: 100%;
        }

        .seen-job-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 20px 0;
            min-height: 121px;

            .job-position {
                padding: 10px 20px;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;

                h3 {
                    text-align: left;
                    font: normal normal bold $medium Roboto;
                    letter-spacing: 0.21px;
                    color: $purple-dark;
                    opacity: 1;
                }

                p {
                    text-align: left;
                    font: normal normal normal $medium Roboto;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                    opacity: 1;
                    line-height: 21px;
                }
            }

            .job-logo {
                height: 53px;
                width: auto;
            }

            .job-location {
                display: flex;
                justify-content: flex-start;

                p {
                    margin: 0 11px;
                    text-align: left;
                    align-self: center;
                    font: normal normal normal $medium Roboto;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                    opacity: 1;
                }
            }
        }
    }
}