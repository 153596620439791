@import 'style-constants.scss';

.table-aligned-items {
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 20px;
    min-height: 38px;

    .left-aligned {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 17px;
        justify-content: flex-start;
    }

    .right-aligned {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 17px;
        justify-content: flex-end;
    }

    .MuiTypography-h1 {
        color: $purple-dark;
        font-size: $medium;
        font-family: Roboto;
        font-weight: bold;
        letter-spacing: 0.11px;
        text-transform: uppercase;
    }

    .MuiTypography-body1 {
        font-size: $small;
        line-height: 16px;
    }

    .table-filter {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        align-items: center;
        padding: 10px 13px;
        background-color: $white;
        color: $purple-dark;
        border-radius: 10px;

        svg {
            stroke: $purple-dark;
            stroke-width: 1;
            width: 14px;
            height: 14px;
        }

        &.selected,
        &:hover {
            background-color: $purple-dark;
            color: $white;

            svg {
                stroke: $white;
                fill: $white;
            }
        }

        &:hover {
            cursor: pointer;

            svg {
                stroke: $white;
                fill: $white;
            }
        }
    }

    .table-date-filter {
        min-width: 135px;
    }

    .bold-text {
        font-weight: bold;
    }

    .search-term-field {
        font-size: $small;
        border: 1px solid;
        border-collapse: separate;
        border-radius: 10px;
        border-color: rgba(0, 0, 0, 0.23);
        padding: 9px 10px;

        .MuiInputBase-input {
            padding: 0;
        }

        .search-icon {
            color: #00000099;
        }
    }
}
