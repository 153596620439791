@import "style-constants.scss";

.upload-image-container {
    h3 {
        font-size: $medium;
        font-weight: 500;
        letter-spacing: 0.54px;
        color: $purple-dark;
        margin-bottom: 15px;
    }

    .dropzone {
        outline: none;

        .image-upload {
            display: none;
        }

        .inner-container {
            display: grid;
            justify-content: center;
            padding: 20px 115px;
            background-color: rgba($actions-border, 0.3);
            border-radius: 4px;
            border: 1px dashed rgba($grey-dashed-border, 0.3);
            margin-bottom: 7px;
            max-width: 375px;
            max-height: 135px;

            &.image-selected {
                padding: 0;
            }

            .image-container {
                width: 375px;
                height: 135px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }

            .upload-icon {
                margin: 0px auto 20px;
            }

            span {
                margin: 0 auto;
                color: $grey-dark;
                font-size: $medium;
                line-height: 19px;
                letter-spacing: 0.14px;
                text-align: center;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }

            .browse-button {
                border: 0px;
                background-color: transparent;
                color: $primary-color-700;

                &:hover {
                    cursor: pointer;
                }
            }

            &.drop {
                background-color: rgba(0, 0, 0, 0.04);
                opacity: 0.5;
            }
        }

        .accepted-formats {
            color: $purple-dark;
            font-size: $small;
            font-weight: 300;
        }
    }

    .attached-image-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-top: 15px;
        margin: -3px 0px;

        .image-name-wrapper {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 12px;
        }

        .image-name {
            font-size: $medium;
            line-height: 19px;
            letter-spacing: 0.11px;
            color: $purple-dark;
        }

        .delete-attached-file-btn {
            font-size: $medium-title;
            padding: 3px;
            margin-right: -3px;

            .MuiSvgIcon-root {
                font-size: $medium-title;
            }
        }
    }
}
