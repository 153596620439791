@import 'style-constants.scss';

.video-interview-profile-wrapper {
    margin-bottom: 20px;

    .video-interview-profile-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 194px;
        padding: 0 50px;
        background: #eeeffc;

        .video-interview-text {
            display: flex;
            flex-flow: wrap column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 28px 0;

            h2 {
                color: $primary-color-400;
                margin: 0px 0 5px 0;
                font-size: 30px;
            }

            p {
                color: $grey-lighter;
                margin: 5px 0px;
            }

            .primary-button {
                margin: 5px 0px;
            }
        }

        .video-interview-image {
            display: flex;
        }
    }
}
