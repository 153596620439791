@import "style-constants.scss";

.client-performance-analytics-wrapper {
    padding: 30px 50px;

    .performance-analytics-title {
        font-size: $medium;
        color: $purple-dark;
        text-align: center;
        padding-bottom: 10px;
    }

    .recharts-legend-wrapper {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-right: -40px;
            margin-bottom: -40px;
        }

        li {
            width: fit-content;
            display: flex;
            align-items: center;
            margin-right: 40px;
            margin-bottom: 40px;

            .legend-item-icon {
                width: 24px;
                height: 10px;
                border-radius: 8px;
                margin-right: 10px;
            }

            span {
                font-size: 13px;
                font-weight: 300px;
                line-height: 15px;
                letter-spacing: 0.44px;
                color: $grey-dark;
            }
        }
    }
}

.performance-review-sub-title {
    text-align: center;
    color: #29325B;
    font-size: $medium;
    letter-spacing: 0.11px;

    span {
        text-decoration: underline;
        font-weight: 500;

        &:hover {
            cursor: pointer;
        }
    }
}
