@import 'style-constants.scss';

.profile-languages-info-wrapper {

    .profile-languages-form {
        width: 100%;
    }

    .buttons-wrapper {
        flex-grow: 0;
    }

    .profile-info-content div:last-child {
        margin-bottom: 0; 
    }
}
