.employee-contract-information-wrapper {
    width: 100%;

    .inner-content-wrapper {
        padding: 40px 30px 6px 30px;

        .contract-information-form {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
            position: relative;

            .contract-information-input {
                width: 50%;
                padding: 0 10px 34px 10px;
            }

            .MuiFormControl-root {
                width: 100%;
                color: #7e7e7e;
            }
        }
    }
}
