@import "style-constants.scss";

.candidate-preferences {
    margin: auto;
    padding: 30px 40px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 10px;
    position: relative;

    &.background-white {
        background-color: $white;
    }

    .upload-input {
        display: none;
    }

    .candidate-avatar.darker {
        -webkit-filter: brightness(80%);
    }

    .candidate-avatar-wrapper {
        position: relative;

        .avatar-actions {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .icon-button {
                width: 18px;
                height: 18px;
                padding: 0;
            }

            .avatar-delete-button {
                background-color: $grey-dark;
                color: $white;

                .close-icon {
                    width: 15px;
                    height: 15px;
                    margin-bottom: 5px;
                }
            }

            .avatar-edit-button {
                background-color: $white;
                border: 2px $purple-dark solid;
                color: $purple-dark;
                margin-left: 10px;

                .edit-sharp-icon {
                    width: 12px;
                    height: 12px;
                    margin-bottom: 6px;
                }
            }
        }
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;

        .MuiAvatar-root {
            width: 106px;
            height: 106px;
        }
        .header {
            display: grid;
            gap: 2rem;
            grid-auto-flow: column;
            justify-content: flex-start;
            .header-info {
                margin-top: 1rem;
                .candidate-name {
                    font-size: 1.125rem;
                    font-weight: 500;
                    letter-spacing: 0.31px;
                    color: $purple-dark;
                }
                .candidate-email {
                    margin-top: 0.874375rem;
                    display: grid;
                    gap: 0.74rem;
                    align-items: center;
                    grid-auto-flow: column;
                    .MuiSvgIcon-root {
                        font-size: 1.5625rem;
                        color: $grey-dark;
                    }
                    .candidate-email-address {
                        font-size: 1rem;
                        letter-spacing: 0.15px;
                        color: $purple-dark;
                    }
                }
            }
        }
    }

    .preferences-row-wrapper {
        position: relative;
        z-index: 1;
        .preferences-row {
            display: flex;
            justify-content: space-between;
            padding-top: 1.5rem;
            .row-title {
                flex-basis: 30%;
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                align-items: center;
                gap: 0.878125rem;
                svg {
                    width: 25.33px;
                    path {
                        fill: #29325b;
                        stroke: none;
                    }
                }
                .preferences-title {
                    flex-basis: 80%;
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
            .row-value {
                flex-basis: 50%;
                p {
                    font-size: 1rem;
                    letter-spacing: 0.15px;
                    color: $medium-dark-shade-blue;
                }
            }

            .job-edit-icon-container {
                width: 24px;
                height: 24px;

                .job-edit-button {
                    background-color: $grey-light;
                    width: 24px;
                    height: 24px;
                    padding: 0;

                    .edit-sharp-icon {
                        width: 15px;
                        height: 15px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .divider,
        .row-divider {
            width: 100%;
            margin: 0;
            margin-top: 1.2rem;
            border: 1px solid $grey-light-amount;
        }

        .row-divider {
            margin-top: 0;
        }
        .last {
            margin-bottom: 2.25rem;
        }
    }
}
