@import 'style-constants.scss';

.navigation-footer-mobile {
    padding-bottom: 34px;

    .navigation-footer-list {
        padding-top: 4px;
    }

    .logout-list-item {
        padding: 8px 48px;
        justify-content: flex-end;
    }

    .logout-list-item-text {
        flex-grow: 0;

        span {
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            color: $purple-light-nav;
        }
    }

    .logout-list-icon {
        color: $purple-light-nav;
        margin-right: 8px;
    }

    .navigation-footer-list-item {
        padding: 10px 16px 10px 48px;
    }

    .list-item-text {
        span {
            font-size: 18px;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            color: $purple-light-nav;
        }
    }

    .divider-wrapper {
        padding: 8px 48px;
    }

    .MuiDivider-root {
        background-color: $grey-light-medium;
    }
}
