@import "style-constants.scss";

.send-profiles-autocomplete-wrapper {
	margin-bottom: 10px;

	.search-field {
		height: 32px;
		color: $grey-dark;
		background-color: $grey-light;
		border-radius: 4px;
		display: flex;
		align-items: center;

		.search-icon {
			margin-right: 19px;
			margin-left: 11px;
		}

		input {
			font-size: $small;
			letter-spacing: 0.24px;
		}

		&.Mui-focused {
			outline: 1px solid $purple-dark;

			.search-icon {
				color: $purple-dark;
			}

			input {
				color: #43425d;
			}
		}
	}

	.invalid-field-message {
		padding-top: 10px;
		color: $red-darker;
		font-size: $small;
		line-height: 24px;
		letter-spacing: 0.25px;
	}
}

.send-profiles-dropdown-list {
	padding: 15px 30px;

	&::-webkit-scrollbar {
		width: 3px;
	}

	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:hover {
		background: $purple-light;
	}

	.MuiAutocomplete-option {
		display: flex;
		padding: 0;

		&:not(:last-child) {
			border-bottom: 1px solid $grey-border;
		}

		.send-profiles-list-item .profile-info-wrapper {
			padding: 10px 0px;
		}
	}
}

.send-profiles-autocomplete-loader {
	display: flex;
	margin: auto;
}
