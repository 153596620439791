@import 'style-constants.scss';

.performance-overall {
    
    .performance-content-wrapper {
        display: flex;
        padding: 25px 85px 25px 30px;
        margin: 20px auto;
        width: 100%;
    
        .overall-performance {
            display: flex;
            flex-direction: column;
    
            .overall-performance-title {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.26px;
                line-height: 1.6;
                color: $primary-color-400;
                margin-bottom: 12px;
            }
    
            .overall-performance-score {
                font-size: 40px;
                font-weight: 500;
                letter-spacing: 0.52px;
                color: $primary-color-400;
                margin-bottom: 12px;
            }
        }
    
        .performance-criteria {
            display: flex;
            flex-wrap: wrap;
            margin-left: 140px;
    
            .performance-criterion {
                margin-bottom: 27px;
                margin-right: 100px;
                width: 100px;
                
                .performance-criterion-title {
                    font-size: 14px;
                    letter-spacing: 0.24px;
                    color: #00000099;
                    white-space: nowrap;
                    margin-bottom: 8px;
                }
            }
            
        } 

        .performance-bar-container {
            &.large {
                width: 124px;
                height: 14px;
            }
        
            &.small {
                width: 93px;
                height: 10px;
            }
        }
    }
} 

