@import "style-constants.scss";

.time-tracking-container {
    margin-bottom: 20px;
    
    .hint-text {
        color: $grey-disabled;
        text-align: center;
    }

    .calendar-container {
        box-shadow: 0px 1px 3px #00000033;
        padding: 20px 24px 20px 25px;
        height: 630px;
        margin: 20px auto;
        width: 100%;

        .calendar-wrapper {
            height: 600;
        }
    
        .btns-wrapper {
            display: flex;
            justify-content: flex-end;

            .discard-btn-wrapper {
                padding-right: 15px;
            }
        }
    }

    .rbc-month-view {
        width: 100%;
    }
}

.time-tracking-container::-webkit-scrollbar {
    display: none;
}
