@import 'style-constants.scss';

.edit-invoice-status-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 12px;

    .additional-status-fields {
        width: 100%;
    }

    .edit-invoice-status-field {
        width: 100%;
        margin-bottom: 20px;
    }
}
