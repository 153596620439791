@import 'style-constants.scss';

.date-range-picker {
    .MuiPickersStaticWrapper-staticWrapperRoot {
        width: 100%;
        min-width: unset;

        .MuiPickersBasePicker-container {
            .MuiPickersBasePicker-pickerView {
                min-width: unset;
                max-width: unset;
                min-height: unset;
                width: 100%;

                .MuiPickersCalendarHeader-switchHeader {
                    margin-top: 0px;
                    margin-bottom: 16px;

                    .MuiPickersCalendarHeader-iconButton {
                        padding: 2px;
                        color: $black;

                        svg {
                            font-size: $small;
                        }
                    }
                }

                .MuiPickersCalendarHeader-daysHeader {
                    justify-content: space-between;

                    .MuiPickersCalendarHeader-dayLabel {
                        font-size: 0;
                        margin: 0;
                    }

                    .MuiPickersCalendarHeader-dayLabel::first-letter {
                        font-size: 11px;
                        color: #171717;
                    }
                }

                .MuiPickersCalendarHeader-transitionContainer {
                    height: 14px;

                    p {
                        text-align: center;
                        font-size: 13px;
                        line-height: 15px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        color: $black;
                    }
                }

                .MuiPickersCalendar-transitionContainer {
                    min-height: 181px;
                    margin-top: 16px;
                }

                .MuiPickersCalendar-week {
                    justify-content: space-between;
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    div {
                        color: $white;
                    }

                    div:last-child {
                        .MuiPickersDay-day.startDate::after {
                            display: none;
                        }
                    }

                    div:first-child {
                        .MuiPickersDay-day.endDate::after {
                            display: none;
                        }
                    }
                }

                .MuiPickersDay-day {
                    width: 22px;
                    height: 21px;
                    opacity: 1;
                    color: $purple-dark;
                    position: relative;
                    margin: 0;

                    &.MuiPickersDay-day.startDate.endDate::after {
                        display: none;
                    }

                    & > span {
                        z-index: 3;

                        p {
                            font-size: 12px;
                        }
                    }

                    &.MuiPickersDay-daySelected:not(.startDate):not(.endDate):not(.outside-of-month) {
                        color: #171717;
                        background-color: $grey-progress;
                        border-radius: 0;

                        &::before {
                            position: absolute;
                            content: '';
                            background-color: $grey-progress;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            z-index: 1;
                            margin-left: -8px;
                        }

                        &::after {
                            position: absolute;
                            content: '';
                            background-color: $grey-progress;
                            width: 100%;
                            height: 100%;
                            right: 0;
                            z-index: 1;
                            margin-right: -8px;
                        }
                    }

                    &.startDate,
                    &.endDate {
                        color: $white;
                        background-color: rgba(0, 0, 0, 0);
                    }

                    &.endDate::before,
                    &.startDate::before {
                        display: block;
                        position: absolute;
                        content: '';
                        background-color: $purple-dark;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        z-index: 2;
                    }

                    &.startDate::after {
                        display: block;
                        position: absolute;
                        content: '';
                        background-color: $grey-progress;
                        width: calc(50% + 8px);
                        height: 100%;
                        right: 0;
                        z-index: 1;
                        margin-right: -8px;
                    }

                    &.endDate::after {
                        display: block;
                        position: absolute;
                        content: '';
                        background-color: $grey-progress;
                        width: calc(50% + 8px);
                        height: 100%;
                        left: 0;
                        z-index: 1;
                        margin-left: -8px;
                    }

                    &.startDate.first-day-of-future-month::after,
                    &.startDate.last-day-of-month::after,
                    &.endDate.first-day-of-future-month::after,
                    &.endDate.last-day-of-month::after,
                    &.first-day-of-future-month:not(.startDate):not(.endDate)::before,
                    &.last-day-of-month:not(.startDate):not(.endDate)::after {
                        display: none;
                    }

                    &.outside-of-month-day {
                        color: $grey-light-amount;
                        background-color: $white;
                        visibility: hidden;
                    }
                }
            }
        }
    }
}
