@import "style-constants.scss";

.step-wrapper {
 
    .no-top-padding {
        padding-top: 0px;
    }

    .paper-element-description {
        margin-bottom: 12px;
        font: normal normal normal 16px/24px Roboto;
        letter-spacing: 0.27px;
        opacity: 1;
    }

    .input-container {
        margin-bottom: 20px;

        //set border to fieldset
        .base-input .MuiOutlinedInput-notchedOutline {
            border-radius: 4px;
            border: 1px solid $grey-dark-text;
        }

        //change border color of fieldset when input is focused or parent div is hovered
        .Mui-focused .MuiOutlinedInput-notchedOutline,
        .base-input:hover .MuiOutlinedInput-notchedOutline {
            border-color: $primary-color-900;
        }

        //set white background to label to prevent top border strikethrough effect on label
        .MuiFormLabel-root {
            background-color: white;
            color: $grey-dark-text;
        }

        //change font color of label when input is focused or parent div is hovered
        .Mui-focused.MuiFormLabel-root,
        .base-input:hover .MuiFormLabel-root {
            color: $primary-color-900;
            font-weight: 500;
        }

    }

    .camera-container {
        position: relative;
        background-color: $grey-light;

        video {
            width: 100%;
        }
    }
}
