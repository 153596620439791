@import 'style-constants.scss';

.add-recruiter-dialog {
    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }
}
