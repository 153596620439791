@import "style-constants.scss";

.add-skill-container {
    // overflow: hidden;
    .sort-list {
        display: flex;
        flex-wrap: wrap;
        letter-spacing: 0.25px;
    }

    .add-skill-form {
        display: flex;
        align-items: center;
        margin: 20px 0;

        .add-skill-input {
            position: relative;
        }

        .search-skills-loader {
            position: absolute;
            left: 86%;
            top: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .MuiCircularProgress-root .MuiCircularProgress-colorPrimary .MuiCircularProgress-indeterminate {
                width: 20px;
                height: 20px;
            }
        }

        @keyframes spinSkillsLoader {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        .MuiOutlinedInput-input {
            color: #7e7e7e;
            padding: 7.5px 15px;
            border: 1px solid $secondary-color;
            border-radius: 4px;
        }

        fieldset {
            display: none;
        }

        .add-skill-button {
            cursor: pointer;
            width: 62px;
            height: 36px;
            background-color: #16d696;
            border: 2px solid #16d696;
            color: white;
            border-radius: 5px;
            margin-left: 20px;
        }
        .add-skill-button:focus {
            outline: none;
        }
    }
}

.global-css-skill-box {
    display: flex;
    align-items: center;
    padding: 7px 14px;
    border: 1px solid $grey-dark;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-right: 15px;
    background-color: white;
    cursor: pointer;

    .skill-name {
        color: $grey-dark;
        width: calc(100% - 23px);
        display: inline;
        font-size: 14px;
    }
    .delete-skill-icon {
        margin-left: 3px;
        width: 20px;
        position: relative;
        z-index: 1;
        padding: 0;
        cursor: pointer;
        height: 20px;
        color: $grey-dark;
        background: none;
        border: 0;
        border-radius: 0;

        &:before {
            content: ''; 
            position: absolute;
            top: 9px;
            left: 3px;
            width: 79%;
            height: 2px;
            background: $grey-dark;
            transform: rotate(45deg);
        }

        &:after {
            content: ''; 
            position: absolute;
            top: 9px;
            left: 3px;
            width: 79%;
            height: 2px;
            background: $grey-dark;
            transform: rotate(-45deg);
        }

      

        &:hover:after {
            color: $primary-color;
            background: $primary-color;
        }
        &:hover:before {
            color: $primary-color;
            background: $primary-color;
        }
    }
}
