@import 'style-constants.scss';

.performance-review-history {
    width: 100%;
    .performance-review-cards-inner-wrapper{
        display: flex;
        flex-wrap: wrap;
    }
    .review-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
