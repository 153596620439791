@import 'style-constants';

.session-card-wrapper {
    width: $coaching-session-card-width;
    min-height: 202px;

    &.thin {
        width: $coaching-session-card-width-thin;
    }

    .header {
        padding: 30px 0px 0px 40px;

        &.with-background {
            padding: 11px 40px;
            background-color: $primary-color-light;
        }

        .day {
            color: $purple-dark;
            font-size: $extra-large;
            letter-spacing: 0.3px;
        }
        
        .month {
            color: $purple-dark;
            font-size: $medium;
            letter-spacing: 0.16px;
        }
    }

    .content {
        display: flex;
        padding: 40px 0px 30px 40px;

        .session-img {
            width: 52px;
            height: 52px;
            align-self: center;
        }

        .session-info {
            padding-left: 15px;
            align-self: center;

            .title {
                color: $purple-dark;
                font-size: $medium;
                letter-spacing: 0.28px;
            }

            .sub-title {
                color: $purple-dark;
                font-size: $medium;
                letter-spacing: 0.28px;
                opacity: 0.8;
                font-weight: normal;
                margin-bottom: 0;
            }
        }

        .time {
            display: flex;
            justify-content: flex-end;
            align-self: center;
            padding-right: 20px;
            flex-grow: 4;
            color: $purple-dark;
            font-size: $medium;
            letter-spacing: 0.16px;
            opacity: 0.8;
            font-weight: normal;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0 0 4px 4px;
        background-color: $grey-background-light;
        padding: 23px 40px;

        h1 {
            font-size: $medium-title;
            color: $purple-dark;
            letter-spacing: 0.61px;
            word-break: break-word;
        }
    }
}