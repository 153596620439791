@import "style-constants.scss";

.task-card {
    width: 33.33%;
    margin-bottom: 20px; 
    cursor: pointer;
    padding: 0 10px;
    border: 0;

    &.MuiPaper-elevation1 {
        background-color: rgba(0,0,0,0);
        box-shadow: none;
    }

    .task-card-inner {
        background: #fff;
        border-radius: 10px;
        height: 100%;
        padding: 17px 15px;
        box-shadow:   0px 1px 3px 0px rgb(0 0 0 / 12%);
        
        &:hover {
            box-shadow: 0px 1px 25px #00000033;
        }
    }


    .title-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .task-status {
        position: relative;
        top: -5px;
        right: -5px;
        width: 26px;
        .task-status-icon-incompleted {
            color: $grey-light-amount;
        }
        .task-status-icon-completed {
            color: $secondary-color-300;
        }
    }

    .task-status-actions {
        .task-status-popup {
            display: none;
        }
    }

    .task-status-actions:hover {
        position: relative;
        .task-status-popup {
            background-color: $grey-light-amount;
            width: 145px;
            border-radius: 5px;
            position: absolute;
            right: -40px;
            display: flex;
            justify-content: center;
        }
    }

    .task-name { 
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.39px;
        color: $primary-color-400;
    }

    .task-info {
        margin-top: 16px;
    }

    .info-row {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        align-items: flex-start;

        &.last {
            margin-bottom: 0;
            padding-right: 15px;
        }

        .task-date,
        .task-time {
            display: flex;
        }

        .task-time {
            margin-left: 25px;
        }
    }

    .info-icon {
        color: $grey-dark;
        margin-right: 8px;
    }

    .info-text {
        font-size: 14px;
        letter-spacing: 0.22px;
        color: $primary-color-400;
        word-break: break-all;
    }

    @media screen and (max-width: $tablet-nav) {
        width: 50%;
    }
}
