@import 'style-constants.scss';

.client-jobs-dashboard-flex-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 79% 19%;
    justify-content: space-between;
}

.client-jobs-dashboard-content-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-content: center;

    .client-jobs-dashboard-header {
        width: 100%;

        .client-jobs-dashboard-greetings {
            text-align: left;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.13px;
            color: $purple-dark;
            margin-bottom: 10px;
        }

        .client-jobs-dashboard-sub-greetings {
            text-align: left;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.11px;
            color: $purple-dark;
        }

        .job-type-filter-boxes-container {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            margin: 31px 0;
            grid-template-columns: 49% 49%;
        }
    }

    .client-jobs-dashboard-body {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
    }
}

.aside-container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding-top: 83px;

    .calendar {
        height: 459px;
    }

    .notifications {
        height: 100%;
        min-width: 160px;
    }
}