@import "style-constants.scss";

.cv-experience-wrapper {
    .header {
        display: flex;
        border-bottom: 1px $purple-dark solid;

        .title {
            margin-right: 10px;
            margin-bottom: 10px;
            color: $purple-dark;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.31px;
            text-transform: uppercase;
        }
        
        .item-edit-icon-wrapper {
            display: none;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            color: $grey-dark;
            background-color: $grey-light;
            border: 1px solid $grey-light;
            border-radius: 14px;
            cursor: pointer;
            margin-right: 7px;

            .item-edit-icon {
                width: 10px;
                height: 10px;
            }

            &:hover {
                border-color: $purple-dark;
                .item-edit-icon {
                    color: $purple-dark;
                }
            }
        }
    }

    &:hover {
        .item-edit-icon-wrapper {
            display: flex;
        }
    }

    .experience-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 20px 0px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            letter-spacing: 0.18px;
        }

        .row-heading {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.19px;
            color: $blue-darkest;

            @media screen and (max-width: $mobile) {
                font-size: 14px;
                letter-spacing: 0.18px;
            }
        }

        .row-subheading {
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.17px;
            margin-top: 8px;
            color: $grey-dark;

            @media screen and (max-width: $mobile) {
                font-size: 12px;
                letter-spacing: 0.15px;
                text-align: right;
            }
        }

        .dates-wrapper {
            display: flex;
            align-items: center;
            margin-left: 10px;
            white-space: nowrap;

            .material-icons {
                font-size: 18px;
                color: $blue-darkest;
            }

            .dates {
                font-size: 14px;
                margin-left: 8px;
                letter-spacing: 0.15px;
                color: $blue-darkest;
            }

            @media screen and (max-width: $mobile) {
                margin-left: 0px;
                margin-top: 2px;

                .dates {
                    font-size: 12px;
                    margin-left: 5px;
                }
            }
        }
    }

    .description {
        color: $grey-dark;
        font-size: 13px;
        letter-spacing: 0.12px;
        white-space: pre-line;
    }

    .skills-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: -16px;

        .skill-box {
            font-size: 15px;
            letter-spacing: 0.2px;
            border: 1px solid #dddddd;
            border-radius: 4px;
            padding: 7px 14px;
            max-width: fit-content;
            color: $blue-darkest;
            margin-right: 12px;
            margin-bottom: 16px;
        }
    }
}
