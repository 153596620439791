@import 'style-constants.scss';

.session-change-listener-dialog {
    width: 100%;

    .MuiDialog-paper {
        width: 400px;
        padding: 30px 30px;
    }

    .dialog-text {
        font-size: $medium;
        letter-spacing: 0.29px;
        color: $grey-dark-text;
    }

    .reload-btn {
        display: flex;
        margin: 20px auto 0px;
    }
}
