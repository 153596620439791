@import 'style-constants.scss';

.pwd-set-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px;
    max-width: 440px;
    width: 100%;

    .logo-icon {
        background-size: contain;
        width: 142px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: $mobile) {
        background: rgba(250, 250, 250, .9);
        border-radius: 8px;
    }

    .pwd-set-msg {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.15px;
        color: $grey-dark;
        margin-top: 40px;
    }

    .pwd-set-form {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .pwd-set-field {
            width: 370px;
            margin-top: 32px;
            margin-bottom: 0px;
        }

        .set-pwd-btn {
            align-self: center;
            margin-top: 28px;

            svg {
                color: $white;
            }
        }
    }
}
