@import "style-constants.scss";

.no-matches-found {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text-message {
        text-align: center;
        color: $purple-dark;
        opacity: 0.8;
        letter-spacing: 0px;
    }

    .content-svg {
        margin: auto;
        margin-top: 20px;
    }
}
