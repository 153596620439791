@import 'style-constants.scss';

.scheduled-interview-details-dialog {
    .MuiDialog-paper {
        width: $page-content-width;
        padding: 0 16px;
    }

    .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dialog-content {
        .upcoming-interview-information {
            margin-bottom: 15px;

            .upcoming-interview-information-field {
                color: $grey-dark;
                font-size: 14px;
                margin-right: 4px;
            }

            &-date-time {
                display: flex;
            }

            &-section {
                &.location,
                &.position,
                &.company {
                    flex-basis: 43%;
                    word-break: break-all;
                }

                &.date-section {
                    margin-right: 10px;
                }

                &.date-section,
                &.time-section {
                    display: flex;
                }

                &.location {
                    margin-bottom: 15px;
                }

                .upcoming-interview-text {
                    color: $primary-color-400;
                    font-size: 14px;
                }

                .upcoming-interview-icon {
                    rect,
                    path {
                        fill: white;
                        fill: $primary-color-500;
                    }
                }

                .upcoming-interview-icon.date {
                    width: 16px;
                    height: 16px;
                }

                .upcoming-interview-icon.hour {
                    width: 16px;
                    height: 18px;
                }
            }

            &-sections-wrapper {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                .date-time-wrapper {
                    flex-basis: 43%;
                    word-break: break-all;
                    padding-top: 1px;
                }
            }
        }

        .description-section {
            .job-description {
                padding: 17px 0 17px 0;
                white-space: pre-line;
            }
        }
    }

    .skills-container {
        border-top: 1px solid rgba(224, 224, 224, 1);
        padding-top: 14px;
        display: flex;
        flex-direction: column;

        .skills-section {
            margin-bottom: 20px;

            .skills-content {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                .skill-box {
                    font-size: 14px;
                    letter-spacing: 0.25px;
                    border: 1px solid $grey-light-amount;
                    border-radius: 5px;
                    padding: 7px 14px 6px;
                    margin-right: 20px;
                    margin-bottom: 9px;
                    max-width: 300px;
                    word-break: break-all;
                }
            }
        }
    }

    .dialog-header-title {
        color: $primary-color-400;
    }

    .dialog-content-title {
        color: $primary-color-500;
    }

    .details-text {
        font-size: 14px;
        color: $grey-dark;
    }
}
