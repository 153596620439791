
@import 'style-constants.scss';

.edit-start-date-dialog {
    .MuiDialogContent-root {
        padding: 0;
        overflow-y: visible;
    }

    .MuiDialog-paper {
        padding: 0 40px 0 40px; 
    }

    .dialog-title {
        padding: 22px 0px 33px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        letter-spacing: 0.11px;
        color: $grey-dark;
        font-size: 16px;
    }

    .dialog-close-btn {
        padding: 0;
    }

    .dialog-actions {
        margin-bottom: 22px;
        padding-right: 0;
    }
}
