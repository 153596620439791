@import "style-constants.scss";

.select-a-role-wrapper {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100vh;
    padding: 70px 110px;
    position: relative;

    .back-button-wrapper {
        position: absolute;
        height: fit-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: $medium;
        font-weight: 500;
        color: $purple-dark;

        span {
            margin-left: 5px;
        }
    }

    .select-a-role-content {
        width: fit-content;
        margin: 0 auto;
    }

    .logo-card-media-wrapper {
        margin: 0 auto;
        width: fit-content;
        padding-bottom: 70px;

        .logo-card-media {
            width: 95px;
            background-size: contain;
        }
    }

    .select-a-role-action-btn {
        width: 100%;
        margin-top: 30px;
    }

    &.mobile {
        padding: 90px 15px;
        display: grid;
        grid-auto-flow: row;
        background-color: $light-shade-blue;

        .role-selection-heading { 
            font-size: $medium;
            letter-spacing: 0px;
            color: $purple-dark;
            text-transform: uppercase;
            opacity: 1;
        }

        .role-selection-wrapper form {
            padding: 0;
        }

        .back-button-wrapper {
            top: 20px;
            left: 15px;
        }
    }
}
