@import 'style-constants.scss';

.slim-select {
    
    .MuiSelect-select {
        padding: 7px 32px 6px 12px;
        font-size: 14px;
        color: #00000099;
    }
}

//this class is global because the menu popup is higher in the DOM than the select
.select-menu {

    .MuiPaper-rounded {
        margin-top: 14px;
        color: #00000099;

        li {
            font-size: 14px;
        }
    }

    .selected {
        background-color: rgba($primary-color-700, 0.06);
    }
    
    .menu-item {
        display: flex;
        margin-top: 0;
        justify-content: space-between;
    }
}
