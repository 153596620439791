@import "style-constants.scss";

.employee-termination-dialog {
    .employee-termination-dialog-text {
        font-size: $medium;
        letter-spacing: 0.11px;
        color: $grey-dark;
        padding-bottom: 10px;
    }
}
