@import "style-constants.scss";

.login-card {
    background: none;
    border: 0;
    box-shadow: none !important;

    padding: 20px;
    max-width: 440px;
    width: 100%;

    @media screen and (max-width: $mobile) {
        background: rgba(250, 250, 250, 0.9);
        border-radius: 8px;
    }

    .login-card-media {
        width: 142px;
        background-size: contain;
        margin: auto;
        margin-bottom: 20px;
    }

    .login-card-msg {
        font-size: 16px;
        text-align: center;
        color: #4d4f5c;
        opacity: 0.5;
    }

    .login-card-actions {
        flex-direction: column;

        &.mobile {
            padding-top: 50px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .login-field {
            width: 100%;
            margin-top: 32px;
            margin-bottom: 0px;
        }

        .login-btns {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 420px) {
                flex-direction: column;
            }

            a,
            button {
                width: 45%;

                @media screen and (max-width: 420px) {
                    width: 100%;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &:only-child {
                    margin: 0 auto;
                }
            }
        }
    }
}

.login-card-prod-style {
    background: none;
    border: 0;
    box-shadow: none !important;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: 20px;
    max-width: 460px;
    width: 100%;

    max-height: 100%;

    .login-card-footer {
        color: $grey-dark;
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);

        h3 {
            font-size: 14px;
        }
    }
    @media screen and (max-width: $mobile) {
        background: rgba(250, 250, 250, 0.9);
        border-radius: 8px;
    }

    .login-card-header {
        .login-card-title {
            font-size: 20px;
            color: $purple-dark;
            text-align: center;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .login-card-subtitle {
            font-size: 16px;
            color: $purple-dark;
            text-align: center;
        }

        .signup-ref {
            text-decoration: none;
            color: $primary-color-700;
        }
    }

    .login-card-media {
        width: 142px;
        background-size: contain;
        margin: auto;
        margin-bottom: 20px;
    }

    .login-card-msg {
        font-size: 16px;
        text-align: center;
        color: #4d4f5c;
        opacity: 0.5;
    }

    .login-card-actions {
        flex-direction: column;

        &.mobile {
            padding-top: 50px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .login-field {
            width: 100%;
            margin-top: 32px;
            margin-bottom: 0px;
        }

        .login-btns {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 420px) {
                flex-direction: column;
            }

            a,
            button {
                width: 45%;

                @media screen and (max-width: 420px) {
                    width: 100%;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &:only-child {
                    margin: 0 auto;
                }
            }
        }
    }
}
