@import 'style-constants.scss';

.site-dashboard {    
    
    .container {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .projects-wrapper {
        margin-bottom: 20px;

        .actions-wrapper {
            padding-bottom: 10px;
        }
    }

    .dashboard-content-wrapper {
        width: 100%;
    }

    .btns-wrapper {
        margin-top: 20px;
        
        .MuiButton-root {
            margin: 0px 30px 20px 0px;
        }
    }

    .option-text {
        margin: 34px 0;
        text-align: center;
        color: $primary-color-400;
    }
}
