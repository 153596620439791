@import 'style-constants.scss';

.employment-info-wrapper {
    margin-bottom: 20px;

    .expand-hide-control {
        min-width: 220px;
        padding-right: 30px;
    }

    .date-picker-wrapper { 
        margin-left: 0;
    }

    .actions-wrapper {
        padding-bottom: 10px;
    }
}
