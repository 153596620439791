@import "style-constants.scss";

.profile-general {
    .profile-general-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .top-inputs-block {
        display: flex;
        justify-content: space-between;
    }

    .top-field {
        width: 100%;
    }

    .profile-type-field {
        width: 100%;
        .profile-type-dropdown {
            width: 100%;
        }
    }

    .top-left-field {
        margin-right: 20px;
    }

    .calendar-icon {
        cursor: pointer;
        color: $grey-dark;
    }
}
