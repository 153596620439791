@import 'style-constants.scss';

.upload-method-dialog {

    .MuiDialog-paper {
        width: 594px;
        height: 206px;
    }

    .dialog-header {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dialog-title-text {
            color: $grey-dark;
            font-size: 16px;
            letter-spacing: 0.11px;
            height: fit-content;
        }
    }

    .dialog-content {
        margin: 0 auto;
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        
        .upload-file-icon-button {
            color: $purple-dark;

            .MuiSvgIcon-root {
                width: 40px;
                height: 40px;
            }

            .upload-file-icon-label {
                color: rgba(0, 0, 0, 0.54);
            }
        }
        
        .upload-link-icon-button {
            .upload-link-icon {
                width: 40px;
                height: 40px;
            }
        }

        .upload-file-icon-button,
        .upload-link-icon-button {            
            background-color: transparent;

            .MuiIconButton-label {
                display: flex;
                flex-direction: column;
                gap: 10px;       
            }
        }
    }
}
