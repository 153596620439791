@import "style-constants.scss";

.share-linkedin-options-popover {
    border-radius: 4px;
    box-shadow: 0px 1px 10px $grey-darker-button;
    font-family: "Roboto", sans-serif;

    .popup-list {

        .react-share__ShareButton {
            width: 100%;
        }

        .popover-list-item {
            padding-left: 20px;

            .share-popover-icon {
                margin-right: 5px;
            }

            .share-popover-text {
                font-size: $small;
                color: $purple-dark;
                align-items: center;
            }
        }
    }
}
