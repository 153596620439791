@import 'style-constants.scss';

.raw-cv-new-profile-wrapper {
    margin-bottom: 20px;

    .raw-cv-new-profile-content {
        background: #eeeffc;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 100px;

        .action-header {
            .action-title {
                font-size: 22px;
                font-weight: 700;
                color: $primary-color-400;
            }

            .action-subtitle {
                color: $grey-normal-title;
                font-weight: 500;
            }
        }

        .raw-cv-new-action-button {
            margin-top: 18px;
        }
    }

    @media only screen and (max-width: $large-desktop) {
        .raw-cv-new-profile-content{
            padding-left: 15px;
        }
      }
}
