@import "style-constants.scss";

.send-profiles-list-item {
	.profile-info-wrapper {
		display: flex;
		align-items: center;
		padding: 15px 0px;
	}

	&:not(:last-child) {
		border-bottom: 1px solid $grey-border;
	}

	.avatar {
		width: 40px;
		height: 40px;
		margin-right: 8px;
	}

	.name {
		font-size: $small;
		font-weight: 500;
		letter-spacing: 0.18px;
		color: $purple-dark;
	}

	span:not(.MuiButton-label) {
		font-size: 12px;
		font-weight: 300;
		letter-spacing: 0.4px;
		color: $grey-dark;
	}

	.comment-field {
		margin: 5px 0px 20px;
	}

	.actions-wrapper {
		display: flex;
		align-items: center;
		margin-left: auto;

		.close-icon {
			cursor: pointer;
			color: $grey-dark;
			margin-left: 15px;
		}
	}

	.invalid-field-message {
		padding-top: 10px;
		color: $red-darker;
		font-size: $small;
		line-height: 24px;
		letter-spacing: 0.25px;
	}
}
