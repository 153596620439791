@import "style-constants.scss";

.interview-invite-form-view {
    .form-interview-time-slots-wrapper {
        color: $purple-dark;

        .title {
            font-size: $medium;
            font-weight: 500;
            letter-spacing: 0.15px;
        }

        .sub-title {
            padding-top: 10px;
            font-size: $small;
            opacity: 0.8;
        }

        .row-list {
            padding-top: 20px;
            display: grid;
            gap: 20px;
        }

        .slot-item {
            display: grid;
            gap: 20px;
        }

        .slot-item-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .slot-number {
                font-size: $medium;
                font-weight: 500;
                letter-spacing: 0.15px;
                color: $primary-color-700;
            }
        }

        .add-new-slot-button {
            margin: -6px 0px;
        }
    }
}
