@import 'style-constants.scss';

.additional-menu-popover {

    .MuiPaper-rounded {
        border-bottom-right-radius: 0px;
        margin-left: 8px; 
    }

    .popover-list {
        width: 144px; 
        height: auto;
        padding: 4px 22px;
    
        .popover-list-item {
            padding: 7px 0;
        }
    
        .list-item-text span {
            font-size: 12px;
            letter-spacing: 0px;
            color: #4D565C;
        }
    
        .bold-text span {
            font-weight: 700;
        }

        .popover-list-icon {
            min-width: 24px;
            color: $grey-dark;
            margin-right: 8px;
        }
    }

    @media screen and (max-width: $mobile) {
        display: none;
    }
}
