@import "style-constants.scss";

.table-footer-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 23px;
    font-weight: 500;
    .footer-paging-container {
        display: flex;
        width: 370px;
        .rolls-per-page-container {
            display: flex;
            color: #00000099;
            font-size: 14px;
            letter-spacing: 0.24px;
            font-style: 'Regular 14px/21px','Roboto';
            .rolls-per-page-title {
                margin: auto;
            }
            .page-count-select {
                .page-count-select-container {
                    color: #00000099;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 4px;
                    .MuiInput-input {
                        background-color: white;
                        line-height: normal;
                    }
                    svg {
                        color: #43425D;
                        font-size: 23px;
                    }
                }
            }
        }
        .arrows-container {
            display: flex;
            .arrow-wrapper {
                min-width: 0px;                   
                .arrow { 
                    font-size: 14px;
                    color: $grey-dark-text;
                    cursor: pointer;
                }
            }
        }
        .show-pages-container {
            margin: auto;
            width: 135px;
            margin-left: 23px;
            padding-left: 12px;
            .pages-info {
                color: #00000099;
                letter-spacing: 0.5px;
                font-family: 'Roboto', 'Regular';
                font-size: 14px;
            }
        }
    }
}
