@import "style-constants.scss";

.rate-candidate-banner-wrapper {
    margin-top: 40px;
    
    .rate-candidate-container {
        display: flex;
        padding: 30px 40px;
        position: relative;

        .rate-candidate-data {
            align-self: flex-end;
            width: 80%;

            .text {
                margin-bottom: 20px;
                color: $purple-dark;
                font-size: 16px;
                font-family: Roboto;
            }
        }

        .rate-candidate-img {
            position: absolute;
            left: calc(100% - 150px);
            top: -20px;
        }
    }

    .rate-candidate-button-wrapper {
        margin-top: 14px;
    }
}
