@import "style-constants.scss";

.candidate-journey-upload-file {
    height: 100%;

    .allowed-formats-text {
        margin-top: 10px;
        color: $grey-dark-text;
        font-size: 14px;
        font-weight: 300;
    }

    .import-pdf-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $grey-cv-box;
        width: 399px;

        @media screen and (max-width: $mobile) {
            width: 100%;
        }
        
        height: 260px;
        border: 1px dashed $grey-dashed-border;
        border-radius: 4px;
        vertical-align: middle;
        text-align: center;

        & > span:not(:last-child) {
            margin-bottom: 10px;
        }

        & span {
            color: $grey-dark;
            font-size: 14px;
        }

        .browse-button {
            border: none;
            color: $primary-color-700;
            font-size: 14px;
            background-color: transparent;
            cursor: pointer;
        }

        .MuiButton-label {
            color: $primary-color-700;
            font-size: 14px;
            border: none;
            text-transform: lowercase;
            background-color: transparent;
            margin-left: 5px;
            cursor: pointer;
            padding: 0px;
            margin: 0;
            width: 0px;
            height: 0px;
            padding-bottom: 1px;
            font-weight: 400;
        }

        .MuiButton-root:hover {
            background-color: transparent;
        }
    }

    .file-hover-animation {
        @keyframes bouncingCloudIcon {
            0% {top: 0px;}
            50% {top: -20px;}
            100% {top: 0px;}
        }
        
        .cloud-icon {
            position: relative;
            top: 0px;
            animation-name: bouncingCloudIcon;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
        }
    }    
}
