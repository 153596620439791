@import "style-constants.scss";

.raw-cv-profile-wrapper {
    margin-bottom: 20px;

    .raw-cv-profile-content {
        padding: 40px;
        margin-bottom: 100px;
        background: #eeeffc;
    }

    .raw-cv-wrapper {
        background-color: #eeeffc;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
    .raw-cv-wrapper img {
        width: 221px;
        height: 210px;
    }
    .raw-cv-wrapper {
        padding: 40px;
    }

    .step-number {
        width: 44px;
        height: 39px;
        color: #fff;
        background-color: $primary-color;
        font-size: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .text-info {
        ul {
            padding: 0;
            list-style-position: inside;
        }
    }

    .cols {
        display: flex;
        margin: 0 -30px;

        .col-1of3 {
            width: 33.33%;
            padding: 0 30px;

            border-right: 2px solid $grey-light-medium;

            &:last-child {
                border-right: 0;
            }
        }
    }

    .image-info {
        width: 100%;
        height: auto;
    }

    .upload-file {
        height: 100%;
    }

    .raw-cv-wrapper ul li::marker {
        color: $primary-color !important;
    }

    .raw-cv-wrapper ul {
        color: $grey-lighter;
        list-style-position: inside;
        padding-left: 0;
    }

    .import-pdf-container {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        border: dashed $grey-lighter 2px;
        vertical-align: middle;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .text-info li::marker {
        color: $primary-color;
    }
    .text-info li {
        color: $grey-lighter;
        font-size: 14px;
        line-height: 28px;
    }
}
