@import 'style-constants.scss';

.performance-tabs-wrapper {
    padding-bottom: 30px;

    .MuiTabs-root {
        min-height: fit-content;
    }

    .MuiTabs-indicator {
        display: none;
    }

    .performance-tab {
        min-height: fit-content;
        color: $grey-lighter;
        padding: 5px 25px;
        text-transform: none;
        background-color: transparent;
        border: 1px solid $grey-border;
        border-radius: 4px;

        &.Mui-selected {
            color: $primary-color-900;
            border-color: $primary-color-900;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}
