@import "style-constants.scss";

.empty-jobs-list-wrapper {
    margin: 40px auto 0px;
    font-size: $medium;
    color: $purple-dark;
    letter-spacing: 0.11;

    .empty-jobs-list-title {
        font-weight: medium;
    }

    .empty-jobs-list-action-wrapper {
        padding-top: 18px;
        text-align: center;
        opacity: 0.79;
    }

    .empty-jobs-list-action-button {
        cursor: pointer;
        border: none;
        background-color: transparent;
        text-decoration: underline;

        font-size: $medium;
        color: $purple-dark;
        letter-spacing: 0.11;
    }
}
