@import 'style-constants.scss';

.video-card {
    display: inline-flex;
    margin: 10px;
    padding: 20px 45px;
    width: 100%;
    max-width: 600px;
    
    .row-remove-icon {
        color:$grey-dark;
        width: 23px;
        height: 23px;
        cursor: pointer;
        margin-left: 5px;
    }

    .row-remove-icon:hover {
        color: $primary-color;
    }
      
}
