@import 'style-constants.scss';

.performance-review-wrapper {

    &.performance-review-history {
        margin-top: 20px;
    }

    .actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
    }

    .buttons-wrapper {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        margin-left: 40px;

        .create-review-btn {
            margin-left: 15px;
        }
    }
}