@import "style-constants.scss";

.video-recordings {
    margin-top: 1.86rem;
    margin-inline: auto;

    .recordings-wrapper {
        .title,
        .sub-title {
            font-size: 1.25rem;
            font-weight: 500;
            letter-spacing: 0px;
            color: $purple-dark;
        }

        .recording-wrapper {
            margin-inline: auto;
            .sub-title {
                margin-top: 2.4rem;
                font-size: 1.125rem;
            }

            .question {
                display: block;
                margin-top: 1.59rem;
                font-size: 1rem;
                color: $purple-dark;
                letter-spacing: 0px;
            }
        }
    }

    .candidate-interview-face-to-face {
        display: inline;
        font-size: 16px;
        color: $grey-lighter;
        min-height: 400px;
        text-align: center;
        margin: auto;
        vertical-align: middle;

        .icon {
            display: block;
            margin: auto;
            margin-bottom: auto;
            margin-bottom: 10px;
        }
    }

    .no-videos-available-wrapper {
        padding: 30px 40px;

        .title {
            color: $purple-dark;
            letter-spacing: 0.23px;
            font-size: $medium-title;
        }

        .no-videos-available-content {
            margin-top: 24px;

            .no-videos-available-message {
                display: flex;
                align-items: center;
                margin-bottom: 34px;
                position: relative;
                justify-content: center;
            }

            .no-videos-available-text {
                font-size: $medium;
                color: rgba($purple-dark, 0.8);
                text-align: center;
            }

            .no-videos-available-icon {
                display: flex;
                margin: 0 auto;
            }
        }
    }

    .video-recordings-wrapper {
        background-color: $white;
        box-shadow: 0px 1px 3px #00000033;
        border-radius: 4px;
        padding: 30px 50px;

        .face-to-face-footer {
            background-color: $primary-color-90;
            margin-top: 20px;
            padding: 22px 35px;
            display: grid;
            grid-auto-flow: column;
            gap: 30px;
            font-size: $medium;
            justify-content: space-between;

            .bold {
                font-weight: 800;
            }
        }

        &.calendly-wrapper {
            padding: 0px;
            padding-top: 30px;
        }
    }

    .action-buttons-wrapper {
        gap: 10px;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        margin-top: 30px;
    }
}
