@import 'style-constants.scss';

.add-supplier-form {
    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .email-field,
    .supplier-name-field,
    .contact-person-field,
    .supplier-details-block {
        margin-top: 12px;
        margin-bottom: 35px;
    }

    .supplier-details-block {
        display: flex;
        gap: 27px;
        justify-content: space-between;
    }

    .supplier-details-block > *,
    .supplier-final-block > * {
        width: 100%;
    }

    .supplier-final-block {
        display: flex;
        gap: 27px;
        justify-content: space-between;
        margin-top: 12px;
    }
}
