@import 'style-constants.scss';

.candidates-checklist-table-wrapper {
    border-radius: 4px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    background-color: $white;
    padding: 30px 35px;
    margin-top: 20px;
    position: relative;
    min-height: 150px;

    .candidates-checklist-table {
        table-layout: fixed;
    }

    .candidates-checklist-table-head-cell {
        padding: 0px 0px 15px;
        font-size: $medium;
        font-weight: 500;
        letter-spacing: 0.21px;
        line-height: 19px;
        color: $purple-dark;
        border-bottom: none;
    }

    .candidates-checklist-table-body-row:last-child
        .candidates-checklist-table-body-cell {
        border: none;
    }

    .candidates-checklist-table-body-cell {
        padding: 15px 0px;
        font-size: $medium;
        line-height: 21px;
        letter-spacing: 0.28px;

        .candidate-picture-name-wrapper {
            display: flex;
            align-items: center;
            color: $grey-dark-text;

            .candidate-picture {
                width: 25px;
                height: 25px;
                margin-right: 15px;
            }

            .candidate-name {
                text-decoration: none;
                font-size: inherit;
                color: inherit;
            }
        }

        .candidate-steps-wrapper {
            position: relative;
            display: grid;
            gap: 40px;
            grid-auto-flow: column;
            grid-auto-columns: min-content;
            align-items: center;
            width: fit-content;

            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background-color: rgba($grey-light-medium, 0.7);
            }

            .step-box {
                z-index: 1;
                width: 20px;
                height: 20px;
                border: 1px solid $purple-dark;
                border-radius: 4px;
                box-shadow: 0px 1px 3px $grey-darker-button;
                display: flex;
                justify-content: center;
                align-content: center;
                color: $purple-dark;
                background-color: $white;

                &.done {
                    background-color: $purple-dark;
                }

                .step-box-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        font-size: 12px;
                        line-height: 24px;
                    }

                    .checkmark-icon {
                        width: 12px;
                        height: 12px;

                        path {
                            stroke: $white;
                            fill: $white;
                        }
                    }
                }
            }
        }

        .candidate-last-change-date-wrapper {
            color: $grey-dark-text;

            &.text-green {
                color: $secondary-color-300;
            }
        }
    }

    .candidates-checklist-table-loader {
        position: absolute;
        left: 50%;
        top: 55%;
    }

    .footer-paging-container {
        width: fit-content;

        .show-pages-container {
            width: fit-content;
        }
    }
}

.grouped-select-filter-list-button.candidates-checklist {
    background-color: transparent;
    border-color: $actions-border;

    svg path {
        fill: $grey-dark;
    }

    &.active {
        background-color: $white;
        border-color: $purple-dark;

        span {
            color: $purple-dark;
        }

        svg path {
            fill: $purple-dark;
        }
    }
}

.grouped-select-filter-list-menu.candidates-checklist {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 25px;
    }

    .MuiMenu-list {
        width: 277px;
    }

    .grouped-select-filter-list-menu-item {
        text-transform: none;
    }
}

.search-term-field.candidates-checklist {
    font-size: 14px;
    color: $grey-dark;
    border: 1px solid $actions-border;
    background-color: transparent;
    width: 288px;

    .search-icon {
        color: $grey-dark;
        margin-left: 10px;
    }

    &.Mui-focused {
        color: $purple-dark;
        border: 1px solid $purple-dark;
        background-color: $white;

        .search-icon {
            color: $purple-dark;
        }
    }

    .MuiInputBase-input {
        padding: 10px 0px;
    }
}
