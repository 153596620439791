@import 'style-constants.scss';

.invoices-table-wrapper {
    .MuiTableCell-head.less-padding {
        padding: 20px 0px;
    }

    .edit-status-cell-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .edit-sharp-icon:hover {
            cursor: pointer;
        }

        .paid {
            color: $secondary-color-300;
        }

        .overdue {
            color: $red-darker;
        }

        // small desktops and mobile
        @media screen and (max-width: $small-desktop) {
            .edit-sharp-icon {
                width: 12px;
                height: 12px;
            }
        }

        // medium desktops
        @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
            .edit-sharp-icon {
                width: 16px;
                height: 16px;
            }
        }

        // large desktops
        @media screen and (min-width: calc($medium-desktop + 1px)) {
            .edit-sharp-icon {
                width: 20px;
                height: 20px;
            }
        }
    }
}