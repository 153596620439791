@import 'style-constants.scss';

.performance-review-history-person{
    width: 100%;
    margin-top: 20px;
    .review-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
