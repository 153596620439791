@import 'style-constants.scss';

.ask-recruiter-banner-container {
    margin: auto;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $grey-medium-shadow;
    border-radius: 10px;
    padding: 30px 40px;
    display: grid;
    grid-auto-flow: column;
    gap: 14px;

    .ask-recruiter-banner-content {
        .banner-title {
            font-size: $medium-title;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.61px;
            color: $purple-dark;
        }

        .banner-body-text {
            font-size: $medium;
            line-height: 24px;
            letter-spacing: 0.11px;
            color: $purple-dark;
            padding-top: 10px;
            padding-bottom: 20px;
        }

        .remaining-time {
            font-size: 12px;
            font-weight: 300;
            line-height: 24px;
            color: $purple-dark;
        }
    }

    .ask-recruiter-banner-icon-wrapper {
        padding-top: 15px;
    }
}
