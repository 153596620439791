@import "style-constants.scss";

.approve-application-dialog {
    .MuiDialog-paper {
        width: 462px;
        padding: 0 16px;
    }
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 0.11px;

        .dialog-title-text {
            font-size: 16px;
            color: $grey-dark;
        }
    }

    .dialog-content {
        .approve-application-form-view {
            .start-date-title {
                font-size: 16px;
                font-weight: 550;
                color: $primary-color-700;
            }

            .start-date-field {
                margin-top: 25px;
                width: 382.22px;
                .MuiInputBase-root {
                    width: 382.22px;
                    .MuiInputBase-input {
                        width: 100%;
                    }
                }
            }

            fieldset {
                border-color: $grey-lighter;
            }

            .start-date-field:hover {
                .MuiIconButton-root {
                    color: $primary-color-700;
                }
                fieldset {
                    border-color: $primary-color-700;
                }
            }

            .monthly-salary-container {
                .monthly-salary-title {
                    margin-top: 30px;
                    font-size: 16px;
                    font-weight: 550;
                    color: $primary-color-700;
                }

                .form-cols {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 25px;

                    .col-1of2 {
                        width: 100%;
                        margin-bottom: 19.25px;
                    }
                    .col-2of2 {
                        width: 100%;
                    }
                    .col-1of2:hover,
                    .col-2of2:hover {
                        fieldset {
                            border-color: $primary-color-700;
                        }
                    }
                }
            }

            .hire-feedback-container {
                .hire-feedback-title {
                    margin-top: 30px;
                    margin-bottom: 25px;
                    font-size: 16px;
                    font-weight: 550;
                    color: $primary-color-700;
                }

                .hire-feedback-field {
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .hire-feedback-field:hover {
                    fieldset {
                        border-color: $primary-color-700;
                    }
                }
            }

            .additional-info-text-wrapper {
                color: $grey-dark;
                padding-bottom: 24px;
            }
        }

        .dialog-send-btn {
            margin-left: 20px;
        }
    }
}
