@import "style-constants.scss";

.business-trip-banner-wrapper {

    .business-trip-points-wrapper {
        p {
            margin-bottom: 20px;
        }

        ul {
            list-style-type: none;

            p {
                margin-bottom: 2px;
            }
        }
    }
}
