@import 'style-constants.scss';

.personal-info-wrapper {

    .personal-info-inner-wrapper {
        width: 100%;
    }

    .person-personal-form {
        width: 100%;
        position: relative;
    }

    .person-birthdate-field {
        width: 48%;
    }
}
