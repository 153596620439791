@import "style-constants.scss";

.login-wrapper {
    &.mobile {
        overflow-y: scroll;
        height: calc(100% - 180px);
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
        
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
    }

    .login-main-content-container {
        margin-bottom: 100px;
        margin-top: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login-form {
        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }

        .pwd-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 22px;

            .remember-pwd-checkbox.Mui-checked {
                color: $secondary-color-300;
            }

            .remember-pwd-label .MuiFormControlLabel-label {
                font-size: 14px;
                letter-spacing: 0.24px;
                color: #00000099;
            }

            .forgotten-pwd-link {
                text-decoration: underline;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.18px;
                color: $primary-color-900;
            }

            @media screen and (max-width: $mobile) {
                margin-top: 0px;
            }
        }

        @media screen and (max-width: $mobile) {
            .login-field {
                width: 100%;
            }
        }
    }

    .login-card-media {
        width: 142px;
        background-size: contain;
        margin: auto;
        margin-bottom: 20px;

        @media screen and (max-width: $mobile) {
            width: 83px;
        }

        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }
    }

    .title {
        color: $purple-dark;
        font-size: $large-title;
        font-weight: 800;
        letter-spacing: 0px;

        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }
    }

    .subtitle {
        color: $purple-dark;
        font-size: $medium;
        letter-spacing: 0px;

        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }
    }

    .login-mobile-btn {
        margin-top: 20px;
        width: 100%;
    }

    .sign-up-link {
        color: $grey-dark;
        font-size: $medium;
        margin-top: 15px;

        a {
            color: $primary-color-700;
            font-weight: 800;
        }
    }

    .terms-of-use {
        color: $grey-dark;
        margin-top: 30px;
        text-align: center;
    }

    .footer {
        z-index: 2;
        position: fixed;
        text-align: center;
        width: 100%;
        top: calc(100% - 62.88px);
        left: 0;
        color: $grey-dark;
        font-size: $medium;
        letter-spacing: 1.43px;
        box-shadow: 0px -6px 20px #0000001a;
        border-radius: 40px 40px 0px 0px;
        background-color: $light-shade-blue;

        .footer-title {
            padding: 20px 45px;
            text-transform: uppercase;
            box-shadow: 0px -6px 20px #0000001a;
            border-radius: 40px 40px 0px 0px;
            background-color: $white;

            &:hover {
                cursor: pointer;
            }
        }

        &.expand {
            animation: 1s fadeIn;
            animation-fill-mode: forwards;
        }

        &.shrink {
            animation: 1s fadeOut;
            animation-fill-mode: forwards;
        }
    }

    .footer-content {
        display: grid;
        grid-auto-flow: row;
        justify-content: center;
        padding: 62.88px 30px 0px;
        height: 400px;

        .image {
            height: 88px;
            width: auto;
        }

        .title {
            text-align: left;
            font-size: $medium;
            letter-spacing: 0px;
            color: $purple-dark;
        }

        .text {
            text-align: left;
            font-size: $small;
            letter-spacing: 0px;
            color: $purple-dark;
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .back-btn {
            color: $grey-dashed-border;
            text-align: left;
            font-size: $large-title;
            letter-spacing: 0px;

            &:hover {
                cursor: pointer;
            }
        }

        .next-btn {
            color: $primary-color-700;
            text-align: left;
            font-size: $large-title;
            letter-spacing: 0px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .login-btn svg {
        color: $white;
    }

    // animations below
    @keyframes fadeIn {
        0% {
            top: calc(100%);
        }
        100% {
            top: calc(100% - 400px);
        }
    }

    @keyframes fadeOut {
        0% {
            top: calc(100% - 400px);
        }
        100% {
            top: calc(100% - 62.88px);
        }
    }
}
