@import "style-constants.scss";

.profile-summary-wrapper {
    .title {
        margin-right: 10px;
        margin-bottom: 10px;
        color: $purple-dark;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.31px;
        text-transform: uppercase;
    }

    .summary {
        color: $grey-dark;
        font-size: 13px;
        letter-spacing: 0.12px;
        margin: 20px 0px 0px;
        white-space: pre-line;
        font-size: 16px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            letter-spacing: 0.13px;
        }
    }

    .header {
        display: flex;
        border-bottom: 1px $purple-dark solid;

        .item-edit-icon-wrapper {
            display: none;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            color: $grey-dark;
            background-color: $grey-light;
            border: 1px solid $grey-light;
            border-radius: 14px;
            cursor: pointer;
            margin-right: 7px;

            .item-edit-icon {
                width: 10px;
                height: 10px;
            }

            &:hover {
                border-color: $purple-dark;
                .item-edit-icon  {
                    color: $purple-dark;
                }
            }
        }
    }

    &:hover {
        .item-edit-icon-wrapper {
            display: flex;
        }
    }
}
