@import 'style-constants.scss';

.benefits-myBenefits-wrapper {
    width: 100%;

    .no-benefits-info {
        padding-left: 45px;
        color: #7E7E7E;
    }
}
