@import 'style-constants.scss';

.three-dots-options-popover {
    .three-dots-popover-list {
        padding: 5px 0;
    }

    .three-dots-popover-list-item {
        padding: 0 25px;
        color: $grey-normal-title;

        .list-item-text span {
            font-size: $small;
            font-weight: 300;
            letter-spacing: 0.24px;
        }
    }
}
