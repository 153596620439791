@import "style-constants.scss";

.application-custom-tooltip {
    position: absolute;
    color: $grey-dark;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 1px 10px $grey-darker-button;
    font-size: 14px;
    letter-spacing: 0.47px;
    padding: 15px 20px;
    z-index: 1;
}
