@import 'style-constants.scss';

.add-contract-dialog {
    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .MuiDialogContent-root.dialog-content {
        overflow-y: 'hidden'; 
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
    }

    .MuiDialogContent-root.dialog-content::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
}
