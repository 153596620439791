@import 'style-constants.scss';

.application-progress-bar-wrapper {
    margin: 30px 0;
    padding: 30px 45px;

    .title {
        color: $purple-dark;
        font-size: $medium;
        padding-bottom: 30px;
    }

    ul {
        list-style: none;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 3px;
            height: 100%;
            background: #E3E2E2;
        }

        li {
            padding: 5.5px 0;
            padding-left: 30px;
        }

        li {
            .step-content-container {
                display: flex;
                p {
                    color: $purple-dark;
                    font-size: $medium;
                    letter-spacing: 0.28px;

                    span {
                        font-weight: bold;
                    }

                    &::before{
                        content: "";
                        position: absolute;
                        background: #E1E1E1;
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        left: -5.5px;
                        z-index: 1;
                        transform: translateY(15%);
                    }
                }

                .date-content {
                    margin-right: 22px;
                    color: $purple-dark;
                    letter-spacing: 0.21px;
                    font-size: $medium;
                    opacity: 0.8;
                }
            }
        }
    }
}
