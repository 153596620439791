@import "style-constants.scss";

.historical-notes-wrapper {
    width: 100%;
    margin-bottom: 44.5px;
    
    .historical-notes-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .historical-notes-title {
            font-size: $medium;
            font-weight: 500;
            letter-spacing: 0;
            color: $purple-dark;
        }
    }

    .historical-notes-items {
        display: grid;
        gap: 20px;
    }
}
