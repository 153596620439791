@import "style-constants.scss";

.interview-invitation-table-wrapper {
    margin-top: 40px;

    .interview-invitation-table-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
}
