@import 'style-constants.scss';

.employee-profile-popup-container {
    position: fixed;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0px 1px 10px #00000033;
    border-radius: 4px;
    padding-top: 10px;
    padding-right: 10px;
    width: 200px;
    cursor: default;
    
    .employee-header-container {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-bottom: 0px;

        .employee-full-name-container {
            padding-top: 1px;
            margin-bottom: auto;
            font-size: $small;
            letter-spacing: 0.18px;
            color: $purple-dark;
            font-weight: 500;
            opacity: 1;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .employee-manage-options {
            display: flex;
            justify-content: center;
            margin: -5px -8px 0 0;

            .button-container {
                width: 30px;
                height: 30px;

                .button-icon {
                    width: 15px;
                    height: 16px;
                }
            }
        }
    }

    .employee-job-title-container {
        font-size: 10px;
        padding-left: 15px;
        color: #7E7E7E;
        letter-spacing: 0.34px;
        opacity: 0.7;
    }

    .popover-list-container {

        .popover-list-item {
            padding: 0px 0px 5px 10px;

            .popover-list-icon {
                min-width: 30px;
                padding-top: 3px;

                .hour-glass-icon-container {
                    padding-left: 5px;
                }
            }

            .popover-list-icon.money-icon {
                padding-top: 1px;
            }

            .list-item-text {
                .MuiTypography-root {
                    font-size: 12px;
                    color: $purple-dark;
                    font-weight: 400;
                }
            }
        }

        .popover-list-item:hover {
            background-color: transparent;
            cursor: default;
            
            .list-item-text {

                .MuiTypography-root {
                    color: $purple-dark;
                }
            }
        }

        .submit-feedback-container {

            .list-item-text {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .popover-list-container:last-child {
        padding-bottom: 0px;
    }
}
