@import "style-constants.scss";

.candidate-initial-journey-navigation-desktop-wrapper {
    width: $candidate-initial-journey-navigation-width;

    .MuiDrawer-paperAnchorDockedLeft {
        border: none;
    }

    .MuiDrawer-paper {
        position: relative;
        white-space: nowrap;
        background-color: $purple-dark;
    }

    .MuiDrawer-paper::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .candidate-initial-journey-navigation-content {
        display: grid;
        gap: 15px;
        height: 100%;
        margin: 0 auto;
    }

    .candidate-initial-journey-navigation-header {
        margin: 60px 0  auto 0;

        .company-logo {
            max-width: 100%;
            height: 45px;
        }
    }

    .candidate-initial-journey-stepper {
        background-color: transparent;
        padding: 0px;
        margin: auto 0;
    }

    .step-connector {
        width: 1px;
        background-color: $purple-light-nav;
        opacity: 0.5;
        min-height: 42px;
        margin-left: 18px;

        .MuiStepConnector-lineVertical {
            border-left-width: 0px;
        }
    }

    .step-label {
        .step-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 38px;
            margin-right: 8px;
            box-shadow: 0px 1px 3px #00000033;
            border: 1px solid rgba($purple-light-nav, 0.5);
            border-radius: 4px;

            .step {
                font-size: 16px;
                font-family: "Roboto";
                font-weight: 500;
                line-height: 28px;
                letter-spacing: 0px;
                color: rgba($purple-light-nav, 0.5);
            }
        }

        .MuiStepLabel-label {
            font-size: 18px;
            font-family: "Roboto";
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0px;
            color: rgba($purple-light-nav, 0.5);
        }

        &.selected {
            .step-box {
                border: 1px solid rgba($purple-light-nav, 1);
            }

            .step,
            .MuiStepLabel-label {
                color: rgba($purple-light-nav, 1);
                font-weight: 500;
            }
        }
    }

    .candidate-initial-journey-navigation-footer {
        margin: auto 0;

        .list-item {
            display: flex;
            align-items: center;
            width: fit-content;
            padding: 0px;

            .list-icon {
                color: $primary-color-100;
                min-width: 0;

                &.exit-icon {
                    margin-right: 13px;
                    margin-left: -3.665px;
    
                    svg {
                        width: 29.33px;
                        height: 29.33px;
                    }
                }
            }
    
            .list-item-text {
                font-size: 18px;
                letter-spacing: 0px;
                color: $primary-color-100;
            }

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.candidate-initial-journey-navigation-mobile-wrapper {
    .candidate-initial-journey-navigation-content {
        width: 100%;
        height: 80px;
        background-color: $purple-dark;

        .candidate-initial-journey-navigation-header {
            
            .company-logo-wrapper {
                width: fit-content;
                margin: 0 auto;
                padding-top: 13.5px;

                .company-logo {
                    max-width: 100%;
                    height: 45px;
                }
            }
        }
    }
}
