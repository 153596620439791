@import 'style-constants.scss';

.jobs-list-container {
    display: grid;
    gap: 20px;
    flex-flow: column wrap;
    align-items: center;
    width: 100%;
    z-index: 1;

    .search-term-field {
        font-size: 14px;
        border: 1px solid $purple-dark;
        border-radius: 4px;
        background-color: $white;
        width: 140px;

        .MuiInputBase-input {
            padding: 9.7px 0;
        }

        .search-icon {
            color: #00000099;
            margin-left: 15px;
        }

        &.wider {
            // small desktops and mobile
            @media screen and (max-width: $small-desktop) {
                min-width: 200px;
            }

            // medium desktops
            @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
                min-width: 300px;
            }

            // large desktops
            @media screen and (min-width: calc($medium-desktop + 1px)) {
                min-width: 400px;
            }
        }

        &.light-border {
            border: 1px solid $actions-border;
        }
    }

    .job-list-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .job-list-header-left-wrapper {
            display: grid;
            grid-auto-flow: column;
            gap: 18px;
            align-items: center;
        }

        .MuiTypography-h1 {
            color: $purple-dark;
            font-size: $medium;
            font-family: Roboto;
            font-weight: bold;
            letter-spacing: 0.11px;
            text-transform: uppercase;
        }

        @media screen and (max-width: 760px) {
            flex-wrap: wrap;

            .search-term-field.technology {
                display: none;
            }
        }

        // small desktops and mobile
        @media screen and (max-width: $small-desktop) {
            .MuiTypography-h1 {
                font-size: $smallest;
            }
        }

        // medium desktops
        @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
            .MuiTypography-h1 {
                font-size: $medium;
            }
        }

        // large desktops
        @media screen and (min-width: calc($medium-desktop + 1px)) {
            .MuiTypography-h1 {
                font-size: $medium;
            }
        }

        .action-buttons-wrapper {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
        }
    }

    .jobs-list {
        display: grid;
        gap: 20px;
        align-items: center;
    }

    .jobs-list-loader {
        margin: 50px auto 0px;
    }

    .view-all-action-btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .view-all-action-btn {
            box-shadow: 0px 1px 3px $grey-darker-button;
            border: 1px solid $primary-color-700;
            border-radius: 4px;
            background-color: $white;
            color: $primary-color-700;
            font-size: $medium;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 1.43px;
            text-transform: uppercase;
            padding: 15px 88px;
        }
    }
}