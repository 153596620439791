@import "style-constants.scss";

.cv-completion-page {
    padding: 40px 40px 30px 40px;
    background-color: $white;
    box-shadow: 0px 1px 3px #00000033;
    border-radius: 10px;
    width: 100%;

    @media screen and (max-width: $mobile) {
        padding: 40px 30px;
    }

    .cv-logo-content-image {
        text-align: end;
        display: flex;
        margin-left: auto;
        max-width: 100%;
        height: 60px;
    }

    .cv-logo-content-image.mobile {
        height: 30px;
    }
    
    .empty-category {
        margin: 20px 0px 80px;
        height: 25px;
    
        @media screen and (max-width: $mobile) {
            margin: 20px 0px 57px;
        }
    }

    .content > * {
        margin-bottom: 30px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 29px;
        }
    }
}
