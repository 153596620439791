@import "style-constants.scss";

.on-demand-history-dialog {

    .MuiDialog-paper {
        width: $small-dialog-width;
        padding: 0 16px; 
    }

    .dialog-title {
        padding-bottom: 10px;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dialog-title-text {
        color: #7E7E7E;
        font-size: 16px;
    }

    .dialog-content {
        .dialog-content-title-text {
            font-size: 20px;
            color: $primary-color-400;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }

    .dialog-content .rate-container:not(:last-child) {
        border-bottom: 1px solid $grey-darker-button;
    }

    .employee-history-wrapper .rate-container:first-child{
        font-weight: 600;
    }

    .employee-history-wrapper .rate-container:first-child .rate-dates, 
    .employee-history-wrapper .rate-container:first-child .currency{
        color: $black;
    }

    .rate-container {
        padding: 18px 0;
        display: flex;
        justify-content: space-between;

        .rate-dates {
            color: $grey-normal-title;
            font-size: 16px;
        }

        .rate-text {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }

        .rate-text {
            font-size: 18px;
            color: $grey-dark;
        }

        .rate.greater { 
            color: $secondary-color;
        } 

        .rate.smaller { 
            color: $red-darker;
        } 
    }
}
