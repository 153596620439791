@import "style-constants.scss";

.grouped-select-filter-list-wrapper {
    .grouped-select-filter-list-button {
        padding: 10px 17px;
        background-color: $white;
        border-color: $purple-dark;
        align-items: baseline;

        .filter-list-icon {
            margin-right: 10px;
        }

        span {
            font-size: $small;
            font-weight: normal;
            letter-spacing: 0.24px;
            line-height: 17px;
            text-transform: none;
            color: $grey-dark;
        }
    }
}

.grouped-select-filter-list-menu {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 9px;
    }

    .MuiMenu-list {
        padding: 13px 0px;
    }

    .group-wrapper:not(:last-child) {
        margin-bottom: 6px;
    }

    .group-name {
        padding: 7px 0px 10px 20px;
        font-size: $small;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.24px;
        color: $purple-dark;
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;
    }

    .grouped-select-filter-list-menu-item {
        padding: 7px 12px 7px 20px;
        font-size: $small;
        line-height: 17px;
        letter-spacing: 0.24px;
        color: rgba($purple-dark, 0.8);
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;

        &.selected {
            font-weight: 500;
            color: rgba($purple-dark, 1);
            background-color: rgba($primary-color-700, 0.06);
        }
    }
}
