@import 'style-constants.scss';

.slider-testimonials-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 20px 0px;
    border: 1px solid rgba($purple-dark, 0.5);
    border-radius: 4px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    background-color: $light-shade-blue;

    .slider-testimonials-item-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0px 40px 18px;
    }

    .slider-testimonials-item-image {
        position: relative;
        background-size: cover;
        background-position: center;
        width: 288px;
        height: 206px;
        border-radius: 4px;

        &::after {
            content: '';
            position: absolute;
            top: 18px;
            left: 10px;
            width: 100%;
            height: 100%;
            border: 1px solid $secondary-color-300;
            border-radius: 4px;
            z-index: -1;
        }
    }

    .col-1of2 {
        padding-right: 80px;

        h3 {
            font-size: $medium-title;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.61px;
            color: $purple-dark;
            padding-top: 37px;
            padding-bottom: 25px;
        }

        .testimonial {
            font-size: $medium;
            line-height: 21px;
            letter-spacing: 0.28px;
            color: $purple-dark;
        }

        .position-rating {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
            margin-top: 10px;

            span {
                font-size: $small;
                letter-spacing: 0.24px;
                color: $primary-color-700;
            }
        }
    }

    .slick-dots {
        position: static;
        height: fit-content;

        li {
            width: 6px;
            height: 6px;
            margin: 0px;

            .dot {
                display: flex;
                width: 100%;
                height: 100%;
                background-color: $white;
                border: 1px solid $purple-dark;
                border-radius: 50%;
            }

            &:not(:last-child) {
                margin-right: 4.5px;
            }
        }

        li.slick-active .dot {
            background-color: $purple-dark;
        }
    }
}

.slider-testimonials-wrapper.mobile {
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-radius: 4px;

    .slider-testimonials-item-wrapper {
        padding: 0;
        position: relative;
        height: 206px;
    }

    .slider-testimonials-item-image {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        padding: 30% 0%;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(rgba(#296CFB, 0.45), rgba(#296CFB, 0.45));
        }

        &::after {
            content: none;
        }
    }

    .testimonial {
        width: fit-content;
        height: fit-content;
        padding: 0px 15px;
        text-align: center;
        margin: auto;
        font-size: $small;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.24px;
        color: $white;
        z-index: 2;
    }

    .slick-dots {
        position: absolute;
        bottom: 20px;

        li .dot {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: $white;
            border: 1px solid $primary-color-700;
            border-radius: 50%;
        }

        li.slick-active .dot {
            border: 1px solid $white;
            background-color: $white;
        }
    }
}
