@import 'style-constants.scss';

.register-form {
  display: flex;
  flex-direction: column;

  .register-field {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 0px;
  }

  div.login-field {
    margin-top: 22px;
  }

  .pwd-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;

    .remember-pwd-checkbox.Mui-checked {
      color: $secondary-color-300;
    }

    .remember-pwd-label .MuiFormControlLabel-label{
      font-size: 14px;
      letter-spacing: 0.24px;
      color: #00000099;
    }

    .forgotten-pwd-link {
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.18px;
      color: $primary-color-900;
    }
  }
}
