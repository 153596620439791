@import "style-constants.scss";

.training-wrapper {
    max-width: 934px;
    margin: auto;

    .no-matches-paper {
        position: relative;
        min-height: 208px;

        &.centered-content {
            display: flex;
        }
    }

    .centered {
        display: flex;
        position: absolute;
        left: 48%;
        top: 40%;
    }
}
