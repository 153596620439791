body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
