@import "style-constants.scss";

.add-department-dialog-container {
  .dialog-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $grey-dark;

    p {
      font-size: 17px;
      letter-spacing: 0.11px;
    }

    .dialog-title-text {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
