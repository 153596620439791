@import 'style-constants.scss';

.profile-request-wrapper {
    max-width: $large-desktop;
    margin: auto;

    .matching-jobs-loader {
        margin: auto;
        margin-top: auto;
        display: flex;
        margin-top: 100px;
    }

    .matching-jobs-container {
        margin-top: 20px;

        .no-candidate-found {
            padding: 50px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            svg {
                width: 300px;
                height: 230px;
            }
            p { 
                width: 100%;
                text-align: center;
            }
        }
    }
}

.grouped-select-filter-list-menu.matching-jobs {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 25px;
    }

    .MuiMenu-list {
        width: 277px;
    }
}
