@import 'style-constants.scss';

.upload-link-dialog-form {
    .fields-block {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 50% 50%;
        gap: 10px;

        .field,
        .url-field,
        .name-field {
            width: 100%;
        }
    }
}
