@import "style-constants.scss";

.profile-info-row {
    display: flex;
    align-items: center;
    background-color: white;

    .drag-icon {
        width: 20px;
        margin-right: 20px;
    }

    .profile-row-name {
        font-size: 20px;
        font-weight: 500;
        margin-right: 22px;
        color: #707070;
        text-decoration: none;
        overflow-x: hidden;
        max-width: 570px;
    }

    .row-edit-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        color: $grey-dark;
        background-color: $grey-light;
        border: 1px solid $grey-light;
        border-radius: 14px;
        cursor: pointer;
        margin-right: 7px;

        &:after {
            content: '\270E';
            display: inline-block;
            transform: scaleX(-1);
            position: relative;
            top: -1px;
            left: -1px;
        }
    }

    .row-edit-icon-wrapper:hover {
        color: $primary-color;
        border: 1px solid $primary-color;
    }

    .row-edit-icon {
        width: 10px;
        height: 10px;
    }

    .row-remove-icon {
        background: $grey-dark;
        width: 20px;
        height: 20px;
        display: inline-block;
        position: relative;
        border-radius: 50%;
        cursor: pointer;
        border: 0;

        &:before {
            content: "";
            width: 60%;
            height: 2px;
            background: #fff;
            position: absolute;
            top: 9px;
            left: 4px;
            transform: rotate(45deg);
        }

        &:after {
            content: "";
            width: 60%;
            height: 2px;
            background: #fff;
            position: absolute;
            top: 9px;
            left: 4px;
            transform: rotate(-45deg);
        }

        &:hover {
            background: $primary-color-700;
        }
    }

    .row-remove-icon:hover {
        color: $primary-color;
    }
}
