@import "style-constants.scss";

.thank-you-banner-wrapper {
    background-color: $primary-color-90;
    margin: auto;
    padding: 35px 0;
    margin-bottom: 30px;
    border-radius: 10px;

    .thank-you-banner-container {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .thank-you-header-message-container {
            padding-top: 45px;
            width: 435px;
            height: 140px;
            background-image: url("../../../assets/journey-completion-confetti-2.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .thank-you-header-message {
                color: $purple-dark;
                letter-spacing: 0.61px;
                font-size: 18px;
                line-height: 22px;
                font-weight: bold;
            }
        }

        .thank-you-message {
            text-align: left;
            font-family: Roboto;
            font-size: 16px;
            letter-spacing: 0.14px;
            color: $purple-dark;
            opacity: 1;
        }

        .jobs-button {
            margin-top: 33px;
        }
    }
}
