@import 'style-constants.scss';

.supplier-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    height: calc(100vh - #{$header-height});

    .supplier-content-container {
        width: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .supplier-content-container::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .supplier-content {
        width: $page-content-width;
        margin: 20px auto;

        .add-member-button {
            display: block;
            margin: 0 0 0 auto;
        }
    }
}
