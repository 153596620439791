@import "style-constants.scss";

.expired-link-card {
    background: none;
    border: 0;
    box-shadow: none !important;

    width: 100%;
    max-width: 600px;
    padding: 20px;


    @media screen and (max-width: $mobile) {
        background: rgba(250, 250, 250, 0.9);
        border-radius: 8px;
    }

    .title-container {
        margin-bottom: 40px;

        .expired-link-title {
            text-align: center;
            color: $primary-color-700;
            font-weight: 600;
        }
    }

    .expired-link-content-text {
        text-align: center;
        font-size: 21px;
        color: #4d4f5c;
        opacity: 0.7;
        margin-bottom: 20px;
    }

    .main-title {
        color: $primary-color-700;
        font-size: 25px;
        margin: 0 0 20px;
        font-weight: 500;
    }

    .expired-link-card-actions {
        flex-direction: column;
    }

    .expired-link-buttons {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;

        @media screen and (max-width: 420px) {
            flex-direction: column;
        }

        a,
        button {
            @media screen and (max-width: 420px) {
                width: 100%;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &[class*="text"] {
                color: $grey-darker;
                font-weight: 800;
                font-size: 15px;
            }

            &:only-child {
                margin: 0 auto;
            }
        }
    }

    .email {
        text-decoration: none;
    }

    .card-footer {
        max-width: 600px;
        position: absolute;
        bottom: 0;

        .expired-link-footer-text {
            text-align: center;
            font-size: 21px;
            color: #4d4f5c;
            opacity: 0.7;
            margin-bottom: 20px;
        }
    }
}
