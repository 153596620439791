.edit-img-dialog {
    .edit-img-header {
        display: flex;
        justify-content: flex-end;
    }

    .img-edit-content {
        padding: 0 60px 30px;

        .dialog-actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 36px;

            .dialog-inner-position-buttons {
                display: grid;
                grid-auto-flow: column;
                gap: 8px;
            }
        }

        .ReactCrop {
            .ReactCrop__image {
                height: auto;
            }
        }
    }

    .img-cropp-container {
        display: none;
    }

    .ReactCrop__crop-selection {
        border: 1px solid #B3EDD2;
    }
}
