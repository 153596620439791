@import "style-constants.scss";

.applied-candidates-options-popover {
    border-radius: 4px;
    box-shadow: 0px 1px 3px $grey-shadow;

    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 25px;
    }

    .popover-list {
        width: 205px;
        padding: 20px;

        .popover-list-item {
            padding: 0;
            color: $grey-dark-text;

            .job-popover-icon {
                display: flex;
                margin-right: 10px;
            }

            .job-popover-text {
                align-items: center;
                font-size: $small;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.24px;
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}
