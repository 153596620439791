@import "style-constants.scss";

.profile-education-wrapper {
    .profile-education-rows-wrapper {
        .profile-info-row.global-profile-info-row {
            cursor: grab;
            padding-bottom: 30px;
            padding-top: 0;
            border-bottom: 1px solid $grey-border;
            margin-bottom: 30px;

            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}
