@import "style-constants.scss";

.nothing-to-show-wrapper {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
        width: 114px;
        height: 114px;
    }

    p {
        color: $grey-dark;
        line-height: 24px;
        margin-top: 28px;
    }

    &.table-view {
        height: 50px;
        padding-top: 24px;

        p {
            color: $grey-dark;
            line-height: 20px;
            margin-top: 0;
        }
    }

    &.section-view {
        height: 130px;

        svg {
            width: 80px;
            height: 80px;
        }
    
        p {
            margin-top: 14px;
        }
    }
}
