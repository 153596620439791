@import 'style-constants.scss';

.status-box {
    padding: 4px 14px;
    font-size: $small;
    letter-spacing: 0.24px;
    font-weight: 500;
    opacity: 1;

    &.active {
        background-color: $secondary-color-150;
        border-radius: 4px;
        color: $actions-green;
    }

    &.inactive {
        background-color: $red-light;
        border-radius: 4px;
        color: $red-darker;
    }
}
