@import 'style-constants.scss';

.reject-interview-and-application-dialog {

    .MuiDialog-paper {
        width: 462px;
        padding: 0 16px; 
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $medium;
        font-weight: 500;
        letter-spacing: 0.15px;
    }

    .dialog-title-text {
        color: $grey-dark;
        font-size: 18px;
    }

    .dialog-subtitle-text {
        color: $grey-dark;
        text-align: justify;
    }

    .dialog-subtitle-text-message {
        color: $grey-dark;
        text-align: justify;
        padding-top: 10px;
    }
    
    .feedback-msg {
        font-size: 12px;
        letter-spacing: 0.4px;
        color: $red-darker;
    }

    .reject-feedback-field {
        width: 100%;
        margin-bottom: 14px;
    }

    .dialog-actions {
        margin-top: 8px;
        margin-bottom: 14px;
        padding-right: 0;
    }
}
