@import "style-constants.scss";

.recommended-jobs-wrapper {
    background: transparent;
    padding-bottom: 4rem;

    &.mobile {
        width: auto;
    }

    .section-description-container {
        width: fit-content;
        margin: 0 auto;
    }

    .back-button-container {
        cursor: pointer;
        width: fit-content;
    }

    .custom-button-container {
        display: flex;
        align-items: center;
        color: $purple-dark;
        font-weight: 600;
        font-size: 16px;

        span {
            margin-left: 0.5rem;
        }
    }

    h6 {
        font-weight: 500;
    }
    p,
    h6 {
        font-size: 1rem;
        color: $purple-dark;
        letter-spacing: 0.28px;
    }

    .header {
        max-width: 100%;

        .tab-buttons-container {
            display: flex;
            border: 1px solid $medium-dark-shade-blue;
            border-radius: 4px;
            box-shadow: 0px 3px 6px #00000029;
            margin: 31px auto 0px;
            max-width: fit-content;
            .tab-button {
                padding: 0.2rem 2.3rem;
                text-transform: none;
                letter-spacing: 1.43px;
                color: $purple-dark;
                opacity: 1;
                font-weight: normal;
                min-width: 190px;
            }
            .button-selected {
                box-shadow: 0px 3px 6px #00000029;
                font-weight: 500;
            }
            .button-unselected {
                outline: none;
                border-radius: 0px;
            }
        }
        .section-description {
            color: $purple-dark;
            text-align: center;
            margin-top: 1.4rem;
            letter-spacing: 0px;
            font-weight: 500;
        }
        .job-positions-wrapper {
            margin-top: 2.2rem;
            border: 1px solid white;
        }
    }
    .job-positions-wrapper {
        display: grid;
        gap: 1.2rem;
        width: 100%;
        margin: 2.2rem auto;
        background: transparent 0% 0% no-repeat padding-box;
    }
}
