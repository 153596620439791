
@import 'style-constants.scss';

.select-employment-options-dialog {
    .MuiDialogContent-root {
        padding: 0;
        overflow-y: visible;
    }

    .MuiDialog-paper {
        width: $small-dialog-width;
        padding: 0 40px 0 40px; 
    }

    .dialog-title {
        padding: 22px 0px 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        letter-spacing: 0.11px;
        color: $grey-dark;
        font-size: 16px;
    }

    .dialog-close-btn {
        padding: 0;
    }

    .dialog-actions {
        margin-bottom: 22px;
        padding-right: 0;
    }

    .helper-text-wrapper {
        margin-bottom: 7px;

        span {
            text-align: left;
            font-size: 12px;
            color: $grey-dark;
        }
    }
}
