@import "style-constants.scss";

.cv-completion-page-wrapper {
    margin-top: 31px;
    .cv-completion-page-container {
        background-color: transparent;
        margin-inline: auto;
      
        &.m-nopadding {
            margin-top: 0px;
        }

        .paper-content-flex {
            display: flex;
            flex-direction: column;
    
            & > div:not(:last-child) {
                margin-bottom: 20px;
            }
        }
     
        &.paper-element-container {
            padding: 0;
            display: block;
        }

        &.MuiPaper-elevation1 {
            box-shadow: none;

        }

        .cv-section {
            background-color: transparent;
            border-radius: 10px;
        }

        @media screen and (max-width: $mobile) {
            .paper-element-content {
                .cv-completion-page {
                    margin-top: 20px;

                    .title {
                        color: $purple-dark;
                        font-weight: bold;
                        font-size: 20px;
                        letter-spacing: 0.31px;
                        margin-bottom: 20px;
                        
                        @media screen and (max-width: $mobile) {
                            font-size: 16px;
                            letter-spacing: 0.21px;
                        }
                    }
                }
            }
        }
    }

}
