@import 'style-constants.scss';

.location-label-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    border-top-right-radius: 4px;
    overflow: hidden;
}
