@import "style-constants.scss";

.cv-section {
    background-color: $primary-color-90;
    border-radius: 10px;

    .export-cv-btn {
        display: flex;
        width: min-content;
        align-self: flex-end;
        margin-bottom: 15px;
    }

    .candidate-cv {
        padding-top: 1.30rem;
        display: flex;
        flex-direction: column;
        width: $paper-element-m;
        margin: 0 auto;
    }
}
