@import "style-constants.scss";

.info-message-with-icon-dialog {
    position: relative;

    .MuiDialog-paper {
        padding: 30px 40px;
        width: $paper-element-m-nopadding;
        max-width: $paper-element-m-nopadding;
    }

    .dialog-title {
        padding: 0;
        padding-right: 30px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            color: $grey-dark;
            font-size: 16px;
            font-weight: 500;
        }

        .dialog-close-btn {
            position: absolute;
            top: 16px;
            right: 24px;
        }
    }

    .dialog-content {
        padding: 0px 0px 20px;
    }

    .dialog-actions-container {
        padding: 0px;
    }

    .dialog-icon-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .dialog-text {
        font-size: $medium;
        line-height: 26px;
        letter-spacing: 0.11px;
        color: rgba($purple-dark, 0.9);
    }
}
