.add-project-employee-form {
    .add-project-employee-field {
        margin-bottom: 20px;
    }

    .add-project-employee-field.autocomplete {
        margin-bottom: 36px;
    }

    .add-project-employee-field.currency {
        margin-bottom: 28px;
    }

    .add-project-employee-field.billing-type {
        margin: 14px 0;
    }

    .dialog-actions-container {
        display: flex;
        justify-content: flex-end;

        .dialog-actions {
            display: flex;
            justify-content: space-between;
            width: 188px;
        }
    }
}
