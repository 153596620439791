@import "style-constants.scss";

.terms-and-conditions-label {
    margin-left: 0px;
    margin-right: 0px;

    .MuiFormControlLabel-label {
        font-size: $small;
        letter-spacing: 0.24px;
        color: $grey-normal-title;
    }

    a {
        color: #3f51b5;
    }
}
