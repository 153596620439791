@import 'style-constants.scss';

.all-tasks {
    position: relative;

    &.admin-dashboard {
        margin-top: 20px;
    }

    .task-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .tasks-loader {
        position: absolute;
        top: 150px;
        left: 50%
    }
}
