@import 'style-constants.scss';

.team-table-wrapper {
    .logo-name-wrapper {
        display: flex;
    }

    .employee-history-action {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .history-icon {
            color: $actions-green;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .warning-icon {
        width: 16px;
        height: 16px;

        &:hover {
            cursor: pointer;
        }
    }

    .avatar-logo-image {
        width: 30px;
        height: 30px;
        margin-right: 11px;
        pointer-events: none;
        border: 1.5px solid $secondary-color-600;
        background-color: #edecec;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .avatar-logo-image.deleted {
        pointer-events: visible;
        border: 1.5px solid $red-darker;
    }
}
