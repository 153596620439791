@import "style-constants.scss";

.step-wrapper {
    padding: 0px;

    @media screen and (max-width: $mobile) {
        padding: 0;
    }

    &.MuiPaper-elevation1 {
        background-color: transparent;
        box-shadow: none;
    }

    .top-inputs-block {
        display: flex;
        justify-content: space-between;

        .profile-info-form-field {
            margin-bottom: 35px;
            width: 48%;
        }
    }
}
