@import 'style-constants.scss';

.global-table-filter-options-popover {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        min-width: 209px;
        margin-top: 11px;
        margin-left: 0px;
        background-color: $purple-dark;
        color: $white;
        border-radius: 10px;
    }

    .MuiCollapse-root {
        width: 100%;
    }

    .MuiTable-root {
        table-layout: fixed;
    }

    .popover-list-item {
        width: 100%;
        display: flex;
        flex-direction: column;

        &.direction-row {
            flex-direction: row;
        }
    }

    .table-filter-category {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .table-filter-list .MuiListItem-root {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .table-filter-item {
        padding-right: 3px;
        text-transform: capitalize;
    }

    .bold-text {
        font-weight: bold;

        .MuiTypography-body1 {
            font-weight: bold;
        }
    }

    .check-icon {
        width: 17px;
        height: 17px;
    }

    .table-filters-divider {
        background-color: $white;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-h1 {
            font-size: $smallest;
        }

        .MuiTypography-body1 {
            font-size: $tiny;
        }

        .MuiTypography-body2 {
            font-size: $tiny;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }
}

.table-wrapper {
    width: 100%;
    scroll-margin-top: 100px;

    &.with-margin {
        margin: 20px 0;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-h1 {
            font-size: $smallest;
        }

        .MuiTypography-body1 {
            font-size: $tiny;
        }

        .MuiTypography-body2 {
            font-size: $tiny;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }
}