@import 'style-constants.scss';

.add-edit-project-form {

    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .project-name-field, .project-client-field  {
        width: 300px;
    }
    .project-position-field{
        margin-top: 12px;
        margin-bottom: 35px;
    }

    .project-description-field{
        margin-top: 12px;
        margin-bottom: 35px;
    }
    
    .add-project-top-block {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 32px;
    }

    .switch-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 15px -4px 14px 0;

        .MuiFormControlLabel-label {
            font-size: 14px;
            letter-spacing: 0.24px;
            color: #00000099;
        }
    }

    .switch-label {
        margin: 0 10px;
        border: 1px solid #FFF;
        padding: 2px 5px;
    }

    .date-fields-block {
        display: flex;
        justify-content: space-between;
    }

    .start-date-field {
        margin-right: 20px;
    }

    .calendar-icon {
        cursor: pointer;
        color: $grey-dark;
    }

    .disabled-calendar-icon {
        color: $grey-disabled;
    }

    .add-tech-container{
        margin-bottom: 28px;

        .project-technologies-header{
            font-size: 16px;
            color: #7E7E7E;
            letter-spacing: 0.15px;
        }

        .tech-box {
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            padding: 8px 14px;
            height: 32px;  
            border: 1px solid #7E7E7E;
            border-radius: 20px;
            width: auto;
            margin-bottom: 10px;
            margin-right: 15px;
    
            .tech-name {
                color:#7E7E7E;
                font-size: 14px;
                letter-spacing: 0.25px;
            }
            .delete-tech-icon {
                cursor: pointer;
                color:#7E7E7E;
            }
    
            .delete-tech-icon:hover {
                color:$primary-color;
            }
        }
    }
}
