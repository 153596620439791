@import "style-constants.scss";

.submenu-mobile {
    display: none;

    @media screen and (max-width: $mobile) {
        display: flex;
        align-items: center;

        .submenu-mobile-list {
            width: 100%;
            display: flex;
        }

        .tab-mobile.active {
            color: $primary-color-900;
        }

        .MuiTabs-scrollable {
            width: 80%;
            height: 100%;
        }

        .MuiTabs-scrollButtons {
            height: 100%;
        }
    }
}
