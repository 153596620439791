@import 'style-constants.scss';

.candidate-hiring-details {
    .candidate-hiring-details-inner {
        padding: 30px 45px;
        color: $grey-dark;
        .section-head {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;

            .MuiTypography-h3 {
                font-weight: 400;
                font-size: 20px;
                margin-top: 0;
                margin-bottom: 15px;
                color: $grey-lighter;
            }
        }

        .section-foot {
            padding-top: 10px;
            text-align: right;
        }

        .details-container {

            .details-information {
                font-size: 16px;
                margin: 0 0 3px;
                border-bottom: 1px solid $grey-lighter;
                margin-bottom: 20px;
                padding: 0 0 20px;
                display: flex;
                align-items: center;

                .icon-container {
                    width: 25px;
                    height: 25px;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text-container {
                    display: flex;
                    flex-direction: column;
                    padding-left: 20px;
                    font-size: 18px;
                    font-weight: 500;
                    color: $grey-lighter;
                    border-radius: 10px;

                    .information {
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
