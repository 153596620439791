@import "style-constants.scss";

.client-your-account-manager-information-wrapper {
    padding-bottom: 33px;

    .client-your-account-manager-title {
        font-size: $medium;
        font-weight: 500;
        letter-spacing: 0.11px;
        color: $purple-dark;
        padding-bottom: 20px;
    }

    .client-your-account-manager-content {
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0px 1px 10px $grey-darker-button;
        padding: 30px 40px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .information-section {
            .title {
                font-size: $medium;
                font-weight: 500;
                letter-spacing: 0.21px;
                color: $purple-dark;
                padding-bottom: 20px;
            }

            .icon-text-wrapper {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 10px;
                }

                .text {
                    font-size: $medium;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                }
            }
        }
    }
}
