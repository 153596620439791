@import 'style-constants.scss';

.workspace-migration-dialog {
    .MuiDialogContent-root {
        padding: 0px 42px 34px 42px;
    }

    .MuiDialogTitle-root {
        padding: 34px 42px 20px 42px;
    }

    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dialog-title-text{
            color: $purple-dark;
            text-align: center;
            font-size: $medium-title;
            letter-spacing: 0.61px;
            font-weight: 500;
        }
    }

    .workspace-dialog-content {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;
    }

    .benefits-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 9px;
        justify-content: flex-start;
        align-items: center;
    }

    .buttons-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 15px;
        justify-content: flex-end;
    }

    p {
        color: $purple-dark;
        text-align: left;
        font-size: $medium;
        letter-spacing: 0px;

        &.title,
        a {
            color: $purple-dark;
            font-weight: 500;
        }
    }
}
