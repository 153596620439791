.training-add-files-dialog {

    .MuiDialog-container {
        .MuiDialog-paper {
            min-height: 400px;
            width: 500px;
        }
    }

    .dialog-header {

        .dialog-close-btn {
            float: right;
            margin: -5px -5px 0 0;
        }
    }

    .dialog-actions {
        padding-right: 0;
    }
}
