@import "style-constants.scss";

.candidate-contact-details {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;

    a {
        letter-spacing: 0.15px;
        color: $medium-dark-shade-blue;
        text-decoration: none;
    }

    .MuiTypography-h1 {
        color: $purple-dark;
        font-size: $medium;
        font-family: Roboto;
        font-weight: bold;
        letter-spacing: 0.11px;
        text-transform: uppercase;
    }

     // small desktops and mobile
     @media screen and (max-width: $small-desktop) {
        .MuiTypography-h1 {
            font-size: $smallest;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h1 {
            font-size: $medium;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h1 {
            font-size: $medium;
        }
    }


    .divider {
        width: 100%;
        margin: 0;
        margin-top: 1.2rem;
        border: 1px solid $grey-light-amount;
    }

    .contact-form {
        margin: auto;
        margin-top: 20px;
        padding: 30px 40px;
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 10px;
        background-color: $white;

        .assignee-information {
            display: flex;
            justify-content: space-between;
            padding-top: 1.5rem;

            .row-title {
                flex-basis: 30%;
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                align-items: center;
                gap: 0.878125rem;
                color: $purple-dark;

                svg {
                    width: 25.33px;
                }

                .title {
                    flex-basis: 80%;
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
            .row-value {
                flex-basis: 20%;
                font-size: 1rem;
                letter-spacing: 0.15px;
                color: $medium-dark-shade-blue;
            }

            .edit-icon-container {
                width: 24px;
                height: 24px;

                .edit-button {
                    background-color: $grey-light;
                    width: 24px;
                    height: 24px;
                    padding: 0;

                    .edit-sharp-icon {
                        width: 15px;
                        height: 15px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
