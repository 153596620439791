@import 'style-constants.scss';

.explore-talents-employee-card-wrapper {
    margin-bottom: 20px;

    .employee-card-container {
        margin: 1px 2px;

        .employee-card {
            display: flex;
            padding: 30px 40px;
            position: relative;
        }

        .employee-profile-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 23px;

            .role-badge-container {
                width: 84px;
                height: 30px;
                background-color: #777b8a;
                display: flex;
                position: absolute;
                clip-path: polygon(1% 1%, 99% 1%, 76% 99%, 1% 99%);
                top: 0px;
                left: -1px;

                .role-badge-wrapper {
                    display: flex;
                    margin: auto;
                    margin-left: 0;
                    align-items: center;
                    justify-content: center;
                    padding-left: 6px;
                    padding-right: 20px;
                    width: 82px;
                    height: 28px;
                    background-color: #808495;
                    clip-path: polygon(1% 1%, 98% 1%, 76% 99%, 1% 99%);

                    .role-text {
                        color: $white;
                        font-size: 12px;
                        letter-spacing: 0.22px;
                    }
                }
            }

            .avatar-status-wrapper {
                position: relative;
            }

            .employee-avatar {
                width: 88px;
                height: 88px;
            }

            .status-orb {
                position: absolute;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                border: 2px solid $white;
                right: 10px;
                bottom: 0px;

                &.available {
                    background-color: $secondary-color-300;
                }

                &.occupied {
                    background-color: $grey-dark;
                }

                &.default {
                    background-color: $grey-dark;
                }

                &.unavailable {
                    background-color: $red;
                }
            }

            .vetted-profile {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 8px;
                cursor: default;

                svg {
                    margin-right: 3px;
                    width: 17px;
                    height: 13px;
                }

                .vetted-status-text {
                    line-height: 19px;
                    letter-spacing: 1.25px;
                }

                &.vetted {
                    color: $secondary-color-300;
                }

                &.not-vetted {
                    color: $grey-dark;

                    path {
                        fill: $grey-dark;
                    }

                    span {
                        width: 79px;
                    }
                }
            }
        }

        .employee-profile-info-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-right: 20px;

            .employee-profile-header-wrapper {
                display: flex;
                align-items: baseline;
            }

            .employee-name-info {
                margin-right: 40px;

                .employee-name-link-wrapper {
                    text-decoration: none;

                    .employee-name {
                        font-weight: 700;
                        line-height: 32px;
                        letter-spacing: 0px;
                        color: $primary-color-700;
                    }
                }
            }

            .employee-company-info,
            .employee-location-info {
                display: flex;
                align-items: center;
                margin-right: 30px;
                color: $grey-dark;

                .icon-wrapper {
                    width: 18px;
                    height: 18px;
                    margin-right: 8px;
                }

                span {
                    line-height: 21px;
                    letter-spacing: 0.24px;
                }
            }

            .employee-job-title-wrapper {
                .job-title-text {
                    font-weight: 300;
                    line-height: 26px;
                    letter-spacing: 0px;
                    color: $grey-dark;
                }
            }

            .employee-skills-container {
                width: fit-content;
            }

            .explore-talents-profile-info-divider {
                margin: 12px 0px;
                background-color: $grey-light-amount;
            }

            .employee-skills-list {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: -5px;

                .employee-skill-list-item {
                    padding: 0px;
                    margin-bottom: 5px;
                    width: fit-content;

                    &:not(:last-child) {
                        margin-right: 12.5px;
                    }
                }

                .employee-skill {
                    padding: 5px 0px;
                    border-radius: 4px;
                    color: $grey-dark;
                    letter-spacing: 0.25px;
                }

                .rest-skills-count {
                    padding: 0;
                    color: $grey-dark;
                    width: fit-content;
                    min-width: 55px;

                    &:hover {
                        cursor: pointer;
                    }

                    span {
                        font-size: 13px;
                        line-height: 16px;
                        letter-spacing: 0.22px;
                    }
                }

                .single-technology-box {
                    padding: 3px 8px;
                    text-align: center;
                    border: 1px solid rgba($grey-dark, 0.26);
                    border-radius: 4px;
                    margin-right: 10px;
                    margin-bottom: 5px;

                    &.no-border {
                        color: $purple-dark;
                        font-weight: 400;
                        text-align: left;
                        padding: 3px 8px;
                        border: none;
                        border-radius: 0;
                    }

                    .technology-text {
                        line-height: 19px;
                        letter-spacing: 0.25px;
                    }
                }

                .rest-skills-container {
                    position: relative;
                    align-self: center;
                }

                .rest-skills-popup {
                    position: absolute;
                    top: 150%;
                    z-index: 999;
                    display: grid;
                    grid-template-columns: auto auto auto;
                    flex-wrap: wrap;
                    width: max-content;
                    max-width: 700px;
                    background-color: $white;
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 1px 6px #00000038;
                    box-shadow: 0px 1px 6px #00000038;
                    padding: 25px 25px;
                }
            }
        }

        .employee-profile-actions-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            a {
                text-decoration: none;
            }

            .button-container {
                &.edit-profile {
                    margin-top: 10px;
                }
            }
        }
    }
}