@import 'style-constants.scss';

.jobs-calendar {
    width: 100%;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 10px;
    padding: 24px;
    max-width: 298px;
    margin-bottom: 40px;

    .calendar-loader {
        display: flex;
        margin: auto;
        margin-top: 15px;
    }

    .job-calendar-title {
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.11px;
        color: $purple-dark;
        margin-bottom: 10px;
    }

    .job-calendar-title {
        display: flex;
        align-items: center;

        .job-calendar-title-icon {
            margin-right: 10px;
        }

        .job-calendar-title-text {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.11px;
            color: $purple-dark;
        }
    }

    .MuiPickersStaticWrapper-staticWrapperRoot {
        width: 100%;
        min-width: unset;
        border-bottom: 1px solid $grey-light;

        .MuiPickersBasePicker-container {
            max-height: 282px;

            .MuiPickersBasePicker-pickerView {
                min-width: unset;
                max-width: unset;
                width: 100%;

                .MuiPickersCalendarHeader-switchHeader {
                    margin-bottom: 20px;
                }

                .MuiPickersCalendarHeader-daysHeader {
                    margin-bottom: 15px;

                    .MuiPickersCalendarHeader-dayLabel {
                        font-size: 0;
                    }

                    .MuiPickersCalendarHeader-dayLabel::first-letter {
                        font-size: 11px;
                        color: $purple-dark;
                    }
                }

                .MuiPickersCalendarHeader-transitionContainer {
                    text-align: center;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    color: $purple-dark;
                }

                .MuiPickersCalendar-transitionContainer {
                    min-height: 190px;
                    margin-top: 0;
                }

                .interview-date-box span p {
                    border: 1px solid $purple-dark;
                    border-radius: 4px;
                    padding: 3px 5px;
                }

                .jobs-calendar-current-day,
                .jobs-calendar-current-day span p {
                    color: $purple-dark;
                    background-color: $light-shade-blue !important;
                    border-radius: 4px;
                }

                .MuiPickersDay-daySelected {
                    color: $white !important;
                    font-weight: 500;
                    background-color: $purple-dark !important;
                    border-radius: 4px;

                    span p {
                        border: none;
                        color: $white;
                        background-color: $purple-dark !important;
                    }
                }

                .jobs-calendar-past-day {
                    color: $grey-light-amount !important;

                    &:hover {
                        background-color: transparent;
                        cursor: default;
                    }
                }

                .MuiPickersDay-day {
                    width: 30px;
                    height: 30px;
                    opacity: 1;
                    color: $purple-dark;
                }

                .MuiPickersDay-dayDisabled {
                    color: $grey-light-amount;
                }
            }
        }
    }

    .jobs-calendar-events-list {

        .jobs-calendar-events-list-date {
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            letter-spacing: 0px;
            color: $purple-dark;
            margin: 23px 0;
        }

        .job-calendar-event-box {
            background-color: $primary-color-light;
            padding: 20px;
            border-radius: 4px;
            margin-bottom: 14px;

            .job-calendar-event-box-position {
                text-align: left;
                font-size: 12px;
                line-height: 30px;
                font-weight: 400;
                letter-spacing: 0.15px;
                color: $grey-dark;
            }

            .job-calendar-event-box-information {
                display: flex;
                justify-content: space-between;

                .job-calendar-event-box-applicant-name {
                    text-align: left;
                    font-size: 16px;
                    line-height: 30px;
                    font-weight: 500;
                    letter-spacing: 0.21px;
                    color: $purple-dark;
                }

                .job-calendar-event-box-event-time {
                    text-align: left;
                    font-size: 12px;
                    line-height: 30px;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    color: $purple-dark;
                }
            }
        }

        .job-calendar-event-box-no-events {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            letter-spacing: 0px;
            color: $purple-dark;
        }
    }
}
