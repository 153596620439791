@import 'style-constants.scss';

.submit-feedback-dialog {
    .MuiDialog-paper {
        padding: 0 40px 0 40px; 
    }

    .dialog-title {
        padding: 17px 0px 9px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-close-btn {
        padding: 0;
    }

    .rating-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 21px;

        .rating-criteria-title {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.26px;
            color: #000000DE;
            margin: 12px 0;
        }

        .rating-scale-title {
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $grey-dark;
            margin-bottom: 30px;
        }

        .rating-scale {
            font-size: 16px;
            letter-spacing: 19px;
            color: $grey-dark;
            margin-left: auto;
            margin-bottom: 19.5px;
            text-align: end;
        }

        .rating-criteria-row {
            display: flex;
            justify-content: space-between;
            padding-right: 14px;

            .rating-criterion {
                font-size: 14px;
                color: $grey-normal-title;
                letter-spacing: 0.24px;
                padding-right: 16px;
                padding-left: 0px;
            }

            .rating-criterion-error {
                color: $red-darker;
            }

            .MuiRating-root {
                color: $primary-color-400;
            }
            
            .MuiRating-icon {
                padding: 0px 0px 23px 26px;
            }

            .MuiRating-iconActive {
                transform: scale(1);
            }
        }

        .rating-icon {
            transform: scale(0.9);
        }

        .error-message {
            font-size: 14px;
            text-align: right;
            color: $red-darker;
            justify-content: space-between;
            padding-right: 14px;
        }
    }

    .feedback-msg {
        font-size: 12px;
        letter-spacing: 0.4px;
        color: $red-darker;
        margin-bottom: 10px;
    }

    .details {
        width: 100%;
        margin-bottom: 30px;
    }
}
