@import "style-constants.scss";

.explore-talent-wrapper {
    display: flex;
    height: calc(100vh - #{$header-height});

    .explore-talent-content-container {
        width: 100%;
        overflow: auto;
        padding: 40px 30px;

        .explore-talent-content {
            min-width: 700px;
            padding-right: 30px;
            margin-right: -30px;
        }
    }
}

.explore-talents-loader {
    position: absolute;
    top: 45%;
    left: 54%;
}
