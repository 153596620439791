@import "style-constants.scss";

.cv-completion-container {
    padding: 40px 40px 30px 40px;
    background-color: $white;
    margin: auto;
    margin-bottom: 11px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #79797929;
    
    @media screen and (max-width: $mobile) {
        padding: 32px 30px;
    }

    .title {
        color: $purple-dark;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 0.31px;
        margin-bottom: 20px;
        
        @media screen and (max-width: $mobile) {
            font-size: 18px;
            letter-spacing: 0.14px;
        }
    }
    
    .summary {
        color: $purple-dark;
        font-size: 18px;
        letter-spacing: 0.16px;
        margin: 10px 0px 30px;
        
        @media screen and (max-width: $mobile) {
            margin: 0;
            font-size: 16px;
            letter-spacing: 0.14px;
        }
    }
}
