@import 'style-constants.scss';

.thank-you-card {
    background: none;
    border: 0;
    box-shadow: none !important;

    padding: 20px;
    max-width: 600px;
    width: 100%;
    
    @media screen and (max-width: $mobile) {
        background: #FFFFFF;
        border-radius: 8px;
        height: 95%;
    }

    .card-content {
        margin-top: -140px;
        margin-right: 27px;
    }

    .title-container {
        margin-bottom: 40px;

        .thank-you-title {
            text-align: center;
            color: $primary-color-700;
            font-weight: 600;

            @media screen and (max-width: $mobile) {
                font-size: 20px;
                color: $purple-dark;
                opacity: 1;
                letter-spacing: 0.34px;
            }
        }
    }
    
    .thank-you-content-text {
        text-align: center;
        font-size: 21px;
        color: #4D4F5C;
        opacity: 0.7;
        margin-bottom: 20px;
        
        @media screen and (max-width: $mobile) {
            font-size: 16px;
            color: $purple-dark;
            opacity: 1;
            letter-spacing: 0.15px;
        }
    }

    .main-title {
        color: $primary-color-700;
        font-size: 25px;
        margin: 0 0 20px;
        font-weight: 500;
    }

    .thank-you-card-actions {
        flex-direction: column;
    }

    .thank-you-buttons {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;

        @media screen and (max-width: 420px) {
            flex-direction: column;
        }

        a,
        button {
            background-color: $primary-color;
            padding: 15px 40px;

            @media screen and (max-width: 420px) {
                width: 100%;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            
            &[class*="contained"] {
                background-color: $primary-color-700;
                font-size: 22px;
            }
            
            &[class*="text"] {
                color: $grey-darker;
                font-weight: 800;
                font-size: 15px;
            }

            &:only-child {
                margin: 0 auto;
            }
        }  

        .MuiButton-label {
            font-size: $medium;
            font-family: "Roboto";
            color: $white;
            letter-spacing: 1.43px;
        }
    }
}
