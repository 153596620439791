@import 'style-constants.scss';

.reports-kpis-wrapper {
    margin: 20px 0px;

    .kpi-list {
        .kpi-item-title,
        .kpi-item-percentage-change-text {
            color: $purple-dark;
        }

        .kpi-item-amount {
            color: $primary-color-400;
        }
    }
}
