@import "style-constants.scss";

.projects-overview-table-wrapper {
    display: grid;

    .projects-overview-inner-table-wrapper {
        padding: 30px 30px 40px;
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 10px;
        row-gap: 30px;

        .performance-title,
        .candidates-title,
        .employees-title,
        .positions-title,
        .resourse-title {
            color: $primary-color-900;
            margin-bottom: 30px;
        }

        @media screen and (max-width: $tablet) {
            .performance-title,
            .resourse-title {
                margin-bottom: 15px;
            }
        }

        .performance-single-title,
        .candidates-single-title,
        .employees-single-title,
        .positions-single-title,
        .consultant-single-title,
        .management-team-title {
            font-size: 14px;
            text-align: left;
            font-weight: 500;
            letter-spacing: 0.24px;
            color: $grey-normal-title;
            font-family: "Regular", "Roboto";
            line-height: 1.6;
            margin-bottom: 4px;
        }

        .performance-single-amount,
        .candidates-single-amount,
        .positions-single-amount,
        .employees-single-amount,
        .consultant-single-amount,
        .ositions-single-amount.hot,
        .management-team-amount {
            font-size: 36px;
            text-align: left;
            font-weight: 500;
            letter-spacing: 0.46px;
            color: $primary-color-400;
            font-weight: bold;
        }

        .overall-consultants,
        .with-tooltip {
            position: relative;

            &:hover::after {
                content: attr(tooltip);
                position: absolute;
                bottom: 100%;
                left: 0;
                font-size: 10px;
                font-weight: 400;
                color: $grey-lighter;
                box-shadow: 0px 1px 10px #00000033;
                background: #fafafa;
                width: 132px;
                margin-bottom: 10px;
                padding: 13px 10px;
                border-radius: 4px;
                z-index: 12;
            }
        }

        .overall-performance,
        .overall-consultants {
            border-left: 1px solid $grey-border;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h5 {
                text-align: center;
            }

            h4 {
                margin-top: auto;
            }
        }

        .overall-performance > * {
            flex-shrink: 0;
        }

        .overall-performance:first-child,
        .overall-consultants:first-child {
            border-left: 0px solid $grey-border;
            padding-left: 0;
        }

        .overall-performance:last-child,
        .overall-consultants:last-child {
            padding-right: 0;
        }

        .performance,
        .resourse,
        .candidates,
        .positions {
            display: flex;
        }

        .employees.data-field {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 30px;
            width: 90%;

            .overall-performance {
                border-left: 0px solid $grey-border;

                &:nth-child(3n + 1) {
                    padding-left: 0;
                    padding-right: 20px;
                }

                &:nth-child(3n + 2) {
                    border-left: 1px solid $grey-border;
                    border-right: 1px solid $grey-border;
                }

                &:nth-child(3n + 3) {
                    padding-right: 0;
                }
            }
        }

        .data-field > * {
            flex-basis: 30%;
            flex-shrink: 0.2;
        }

        .performance-single-amount.active,
        .employees-single-amount.active,
        .positions-single-amount.active,
        .candidates-single-amount.active {
            color: $secondary-color-300;
        }

        .consultant-single-amount.active {
            color: $secondary-color-300;
        }

        .employees-single-amount.zero,
        .positions-single-amount.zero,
        .candidates-single-amount.zero,
        .performance-single-amount.past,
        .candidates-single-amount.rejected,
        .consultant-single-amount.inactive {
            color: $grey-light-amount;
        }
    }

    .reports-btn {
        margin: auto;
        margin-bottom: 30px;
    }
}
