@import 'style-constants.scss';

.agency-team-notifications-settings {
    .team-notifications-settings-content {
        padding: 40px;

        form {
            display: grid;
            grid-auto-flow: row;
            gap: 20px;
        }
        
        .checkbox-text {
            font-size: 1rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            color: rgba(0, 0, 0, 0.6);
        }
    }
    
    .information-icon {
        color: $actions-green;
    }
}
