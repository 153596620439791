@import "style-constants.scss";

.job-page-wrapper {
    margin: 0 auto;
    width: 100%;
    
    .previous-button {
        margin-bottom: 35px;
        padding: 0;
    }
}
