@import "style-constants.scss";

.profile-projects-wrapper {
    .profile-projects-rows-wrapper {
        background: $white;
        border-radius: 4px;
        box-shadow: 0px 1px 3px #00000033;

        > div:not(:last-child) {
            border-bottom: 1px solid $grey-border;
        }

        .global-profile-info-row {
            cursor: grab;
            padding: 15px 0;
        }
    }

    .use-template-button {
        margin-right: 20px;
    }
}

.global-profile-info-row {
    border-bottom: 0;
    margin-bottom: 0;
}
