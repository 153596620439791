@import "style-constants.scss";

.candidate-hiring-checklist-wrapper {
    display: block;
    margin: 20px auto;
    padding: 25px 55px 35px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0px 1px 3px $grey-medium-shadow;

    .candidate-hiring-checklist-container {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .step-box-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 13px;

            .step-box {
                width: 20px;
                height: 20px;
                border: 1px solid $purple-dark;
                border-radius: 4px;
                box-shadow: 0px 1px 3px $grey-darker-button;
                display: flex;
                justify-content: center;
                align-content: center;
                color: $purple-dark;
                user-select: none;

                &.with-action:hover {
                    cursor: pointer;
                    border: 1px solid transparent;
                    background-color: rgba($purple-dark, 0.2);
                }

                &.done:not(.inactive) {
                    cursor: default;
                    background-color: $purple-dark;
                }

                &.inactive {
                    opacity: 0.5;
                }

                .step-box-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        font-size: 12px;
                        line-height: 24px;
                    }

                    .checkmark-icon {
                        width: 12px;
                        height: 12px;

                        path {
                            stroke: $white;
                            fill: $white;
                        }
                    }

                    .close-icon {
                        color: $white;
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            .arrow-icon {
                margin: 0px 30px;
            }
        }

        .step-title-action-wrapper {
            position: relative;
            width: 100%;
            max-width: 75px;

            .edit-icon-wrapper {
                cursor: pointer;
                position: absolute;
                width: 16px;
                height: 16px;
                right: 15px;
                top: 55%;
            }

            .edit-icon {
                width: 100%;
                height: 100%;
            }
        }

        .step-title {
            font-size: $small;
            font-weight: 300;
            color: $purple-dark;

            &.inactive {
                opacity: 0.5;
            }
        }

        .step-completion {
            font-size: 12px;
            line-height: 20px;
            color: $secondary-color;

            &.text-red {
                color: $red-darker;
            }
        }
    }

    .action-buttons-wrapper {
        gap: 10px;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        margin-top: 30px;
    }
}
