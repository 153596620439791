@import "style-constants.scss";

.candidate-details-container {
    margin-top: 30.07px;
    padding: 42.91px 42.91px 20.99px 42.91px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 4px;
    position: relative;
    background-color: $white;

    .applied-candidate-label {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 33px;
        padding: 15px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;

        .applied-candidate-text {
            text-align: center;
            font-size: 16px;
            line-height: 17px;
            font-weight: 400;
            letter-spacing: 0.11px;
        }
    }

    .applied-candidate-label.pending-review,
    .applied-candidate-label.pending-admin-review,
    .applied-candidate-label.interview-stage,
    .applied-candidate-label.not-vetted {
        background-color: rgba($grey-dark, 0.1);

        .applied-candidate-text {
            color: $grey-dark;
        }
    }

    .applied-candidate-label.hired {
        background-color: rgba($secondary-color-300, 0.1);

        .applied-candidate-text {
            color: $secondary-color-300;
        }
    }

    .applied-candidate-label.rejected {
        background-color: rgba($red-darker, 0.1);

        .applied-candidate-text {
            color: $red-darker;
        }
    }

    .section-divider {
        margin-top: 31px;
        margin-bottom: 31px;
    }

    .added-by-container {
        .added-by-header {
            position: absolute;
            top: 10px;
        }

        .added-by {
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;
            font-weight: 500;
        }
    }

    .rejection-reason-container {
        display: block;

        .rejection-reason-header {
            display: flex;
            align-items: center;
        }

        .feedback {
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;
            font-weight: 500;
            padding: 20.17px 11.88px;
        }

        .comment {
            display: flex;
            font-size: $medium;
            color: $purple-dark;
            padding-left: 11.88px;
            margin-bottom: 29.96px;
        }

        .comment::before {
            width: 17.05px;
            height: 17.05px;
            content: "";
        }
    }
}
