@import 'style-constants.scss';

.employment-contract-details-wrapper {
    width: 100%;
    position: relative;

    .inner-content-wrapper {
        padding: 40px 30px;

        .employment-contract-details-form {
            .MuiFormControl-root {
                width: 100%;
                color: $grey-dark;
            }
        }
    }
}
