@import 'style-constants.scss';

.candidates-statuses-count-boxes {
    .candidates-status-count {
        display: flex;
        justify-content: center;
        max-width: 32px;
        background-color: transparent;
        padding: 5px 12px;
        color: $white;
        border-radius: 4px;
        max-height: 31px;
        transition: all 0.1s ease-in-out;
        
    }
    .new-applications {
        background-color: rgba($white, 0.5);
        border: 1px solid rgba($purple-dark, 0.5);
        color: $purple-dark;
        font-weight: 500;

        &:hover {
            max-height: 100px;
            transform: scale(1.2, 1.19);
            background-color: $white;
            border: 1px solid $purple-dark;
            box-shadow: 0px 1px 6px $grey-darker-button;
        }
    }

    .pending-applications {
        background-color: $grey-creamy;

        &:hover {
            max-height: 100px;
            transform: scale(1.2, 1.2);
            background-color: $grey-dark;
            box-shadow: 0px 1px 10px $grey-darker-button;
        }
    }

    .pending-admin-applications {
        background-color: $grey-creamy;

        &:hover {
            max-height: 100px;
            transform: scale(1.2, 1.2);
            background-color: $grey-dark;
            box-shadow: 0px 1px 10px $grey-darker-button;
        }
    }

    .not-vetted-applications {
        background-color: $grey-creamy;

        &:hover {
            max-height: 100px;
            transform: scale(1.2, 1.2);
            background-color: $grey-dark;
            box-shadow: 0px 1px 10px $grey-darker-button;
        }
    }

    .rejected-applications {
        background-color: $grey-creamy;

        &:hover {
            max-height: 100px;
            transform: scale(1.2, 1.2);
            background-color: $grey-dark;
            box-shadow: 0px 1px 10px $grey-darker-button;
        }
    }

    .approved-applications {
        background-color: $grey-creamy;

        &:hover {
            max-height: 100px;
            transform: scale(1.2, 1.2);
            background-color: $grey-dark;
            box-shadow: 0px 1px 10px $grey-darker-button;
        }
    }

    .jobs-candidates-status-tooltip.hide {
        visibility: hidden;
    }

    .jobs-candidates-status-tooltip.show {
        visibility: visible;
    }

    .jobs-candidates-status-tooltip {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50px;
        z-index: 999;
        display: flex;
        flex-wrap: wrap;
        width: max-content;
        max-width: 700px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px $grey-shadow;
        border-radius: 4px;
        padding: 15px 20px;
        text-align: left;
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        letter-spacing: 0.24px;
        color: $grey-dark-text;

        .status-type-indication-box {
            width: 16px;
            height: 16px;
            box-shadow: 1px 1px 2px $grey-darker-button;
            border-radius: 2px;
            margin-right: 10px;
            
            &.new-applications-indicator {
                background-color: $white;
                border: 1px solid $purple-dark;
            }
            
            &.pending-applications-indicator {
                background-color: $grey-dark;
            }
            
            &.rejected-applications-indicator {
                background-color: $grey-dark;
            }
            
            &.approved-applications-indicator {
                background-color: $grey-dark;
            }

            &.not-vetted-applications-indicator {
                background-color: $grey-dark;
            }

            &.pending-admin-applications-indicator {
                background-color: $grey-dark;
            }
        }
    }
}
