@import 'style-constants.scss';

.guest-content-body {
    .sign-up-field-link-label,
    .go-home-link-label,
    .register-label {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        color: #0D37C9;
    }

    .go-home-link-label,
    .register-label {
        font-size: 12px;
    }

    .register-card.mobile {
        width: 100%;
        align-self: end;
        background: transparent;

        .sign-up-container.mobile a MuiButton-label {
            display: flex;
            justify-content: space-between;
        }

        .sign-up-container.mobile a {
            background-color: $white;
            padding: 7px 12px;
        }
    }

    
    .sign-up-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        a,
        button {
            border: 2px solid #0D37C9;
            border-radius: 3px;
            font-size: 14px;
            color: #0D37C9;
            font-weight: 500;
            margin-bottom: 24px;
            padding: 7px 25px;
            width: 100%;
            max-width: 347px;

            &:last-of-type {
                margin-bottom: 0;
            }

            span[class*="label"] {
                position: relative;

                > span[class*="startIcon"] {
                    position: absolute;
                    left: 0;

                    svg {
                        width: 19px;
                    }
                }
            }
        }
    }  
    
    
    .sign-up-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;

        &.mobile {
            padding-top: 0;

            .MuiLink-root {
                font-weight: 800;
                text-decoration: none;
            }
        }

        p {
            color: $grey-dark;
            margin-top: 10px;

            a {
                font-weight: 500;
            }
        }

        p.divider-content {
            margin-top: 0px;
        }

        [class*="Button-text"] {
            font-size: 12px;
            color: #0D37C9;

            svg {
                width: 19px;
            }
        }
    }    
}
