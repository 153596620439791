@import "style-constants.scss";

.client-dashboard-invoices-wrapper {
    .titles-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .invoices-cards-wrapper {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;
        width: 100%;
    }

    .view-all-action-btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}
