@import "style-constants.scss";

.employees-table-wrapper {
    .employees-table-container {
        .actions-header {
            display: flex;
            justify-content: center;

            .action-button.hire {
                background-color: $secondary-color-300;
            }

            .action-button {
                margin-bottom: 20px;
                width: 260px;
                height: 63px;

                svg {
                    margin-right: 13px;
                }
            }
        }

        .project-name-wrapper {
            .employee-type-label-container {
                pointer-events: all;
                padding: 0px 7px;
                transform: skew(-20deg);
                text-align: center;
                font-size: 12px;
                overflow-wrap: normal;

                .content {
                    font-weight: normal;
                    transform: skew(20deg);
                }
            }

            .On-demand {
                background-color: rgba($secondary-color, 0.1);
                color: $secondary-color;
            }

            .Dedicated {
                background-color: rgba($primary-color, 0.1);
                color: $primary-color;
            }
        }

        .employee-type-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        .logo-name-wrapper {
            display: inline-flex;
            align-items: center;
            pointer-events: none;
            width: 100%;

            .avatar-logo-image {
                width: 30px;
                height: 30px;
                margin-right: 11px;
                background-color: #edecec;

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }

                &.full-time {
                    border: 2px solid $green-light;
                }

                &.freelance {
                    border: 2px solid $yellow-light;
                }
        
                &.contractor {
                    border: 2px solid $grey-dark;
                }
            }

            .additional-label-container {
                pointer-events: all;
                background-color: #000000;
                color: white;
                padding: 0px 7px;
                transform: skew(-20deg);
                text-align: center;
                display: inline-block;
                font-size: 12px;

                .content {
                    font-weight: normal;
                    transform: skew(20deg);
                }

                &.contractor {
                    background-color: $primary-color-400;
                }
            }

            .MuiAvatar-colorDefault {
                background-color: #bdbdbd;
            }
        }

        .employees-table {
            padding: 0 30px 24px;
            overflow: hidden;
        }

        .employees-table table {
            min-height: 100px;
        }

        .employees-table-loader {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 999;
        }

        .table-head-cell {
            color: #0415ac;
            font-size: 16px;
            font-weight: 500;

            .MuiTableSortLabel-root.MuiTableSortLabel-active {
                color: $primary-color;

                .MuiTableSortLabel-icon {
                    color: $primary-color;
                }
            }

            .MuiTableSortLabel-icon {
                opacity: 0.3;
            }
        }

        .table-head-checkbox {
            color: $primary-color;

            &.Mui-checked {
                color: $secondary-color-300;
            }
        }

        .logo-name-wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .logo-name-wrapper > *:nth-child(1) {
            flex: 0 0 30px;
        }

        .logo-name-wrapper > *:nth-child(3) {
            flex: 1 1 50px;
        }

        .table-body-cell {
            padding: 0;
            color: #00000099;

            .edit-status-button {
                width: 25px;
                height: 25px;
                margin: 0 0 0 8px;
                padding: 0;

                .edit-sharp-icon {
                    width: 20px;
                }
            }

            .recruiter-cell {
                display: flex;
                justify-content: flex-start;
                font-weight: 500;
                white-space: nowrap;
                width: fit-content;
            }

            .recruiter-value {
                line-height: 24px;
            }

            .email {
                text-decoration: none;
                color: #00000099;

                &:hover {
                    text-decoration: underline;
                }
            }

            .pending-holidays-count {
                background-color: $primary-color-400;
                padding-top: 2px;
                color: $white;
                text-align: center;
                width: 137px;
                height: 24px;
                border-radius: 4px;
                opacity: 1;
            }
        }
    }

    @-moz-document url-prefix() {
        td {
            height: auto;
        }
    }
}
