@import 'style-constants.scss';

.agency-settings-wrapper {
    margin-bottom: 20px;

    .agency-settings-content {
        padding: 40px;
    }

    .agency-basic-settings-form-field {
        margin-bottom: 34px;
        text-align: left;

        &.last {
            margin-bottom: 0;
        }
    }
}
