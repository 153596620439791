@import 'style-constants.scss';

.job-form-dialog-view {
    .job-image-field {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        justify-content: space-between;

        .job-image-container {
            position: relative;
            width: 108px;
            height: 108px;
            border-radius: 50%;
            background-color: $grey-progress;

            &.show-actions {

                .job-image {
                    opacity: 0.8;
                }
            }
        }

        .job-image {
            width: 100%;
            height: 100%;

            .MuiAvatar-img {
                object-fit: contain;
            }
        }

        .upload-image {
            .upload-image-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &:hover {
                    background-color: transparent;
                }

                .upload-image-icon {
                    width: 22px;
                    height: 22px;

                    path {
                        stroke: $purple-dark;
                    }
                }
            }
        }

        .job-image-actions {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: space-between;
            z-index: 1;
            width: 50px;

            .icon-button {
                width: 18px;
                height: 18px;
                padding: 0;
            }

            .job-delete-button {
                background-color: $grey-dark;
                color: $white;

                .close-icon {
                    width: 15px;
                    height: 15px;
                    margin-bottom: 5px;
                }
            }

            .job-edit-button {
                background-color: $white;
                border: 2px $purple-dark solid;
                color: $purple-dark;

                .edit-sharp-icon {
                    width: 12px;
                    height: 12px;
                    margin-bottom: 6px;
                }
            }
        }

        .assistive-text {
            font-size: $small;
            line-height: 20px;
            letter-spacing: 0.18px;
            color: $grey-dark-text;
            margin-left: 14px;
            flex: 1;
        }
    }

    .skill-label {
        color: $purple-dark;
        font-size: 18px;
        font-weight: 500;
    }

    .add-skill-container {
        .add-skill-form {
            flex-direction: column;
            .add-skill-input {
                width: 100%;
            }
            .MuiFormControl-root {
                width: 100%;
            }
            .MuiOutlinedInput-input {
                width: 100%;
                height: 30px;
                border: 1px solid $grey-dark-text;
            }

            .add-skill-button {
                display: none;
            }

            .sort-list {
                width: 100%;
            }

            .skill-box {
                border: 1px solid $actions-border;
            }
        }
    }

    .location-section {
        .location-section-title {
            margin: 20px 0 20px 0;
            letter-spacing: 0.12px;
            font-family: Roboto;
            font-size: 18px;
            color: $purple-dark;
            font-weight: 450;
        }

        .countries-container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .grid-row-span-2 { 
                grid-row: span 2;

                .country-checkbox {
                    align-self: flex-start;
                }
            }
            
            .country-wrapper {
                display: flex;
                align-items: center;
                .country-checkbox {
                    color: $grey-light-short-text;

                    &.Mui-checked {
                        color: $purple-dark;
                    }
                }

                .country {
                    font-weight: 420;
                    color: $purple-dark;
                    font-size: 16px;
                }
            }
        }
    }
}
