@import 'style-constants.scss';

.add-edit-certificate-form{

    .add-title-field {
        width: 637px;
    }

    .date-url-block {
        display: flex;
        justify-content: space-between;
    }

    .add-document-url-field {
        width: 308px;
    }

    .certificate-issue-date-field {
        width: 308px;
        margin-top: 16px;

        .MuiFormControl-root {
            margin-bottom: 8px;
        }
    }

    .datepicker-field-error {
        
        .MuiFormLabel-root {
            color: #f44336;
        }
    }

    .calendar-icon {
        cursor: pointer;
        color: $grey-dark;
    }
}
