@import "style-constants.scss";

.invoice-options-popover {
    border-radius: 4px;
    box-shadow: 0px 1px 10px $grey-shadow;

    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 30px;
    }

    .popover-list {
        padding: 25px 20px;

        .popover-list-item {
            padding: 0;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .invoice-popover-icon {
                display: flex;
                margin-right: 13px;

                svg {
                    width: 15px;
                    height: 15px;
                }

                svg path {
                    stroke: $secondary-color-300;
                }
            }

            .invoice-popover-text {
                font-size: $small;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.24px;
                color: $grey-dark-text;
            }
        }
    }
}
