@import "style-constants.scss";

.job-type-label-contract,
.job-type-label-full-time {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93px;
    height: 33px;
    padding: 5px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;

    .job-type-text {
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0.11px;
    }
}

.job-type-label-contract {
    background-color: $primary-color-70;

    .job-type-text {
        color: $primary-color-400;
    }
}

.job-type-label-full-time {
    background-color: $orange-light;

    .job-type-text {
        color: $orange;
    }
}
