@import "style-constants.scss";

.cv-certificates-wrapper {
    .header {
        display: flex;
        border-bottom: 1px $purple-dark solid;

        .title {
            margin-right: 10px;
            margin-bottom: 10px;
            color: $purple-dark;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.21px;
            text-transform: uppercase;
        }

        .item-edit-icon-wrapper {
            display: none;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            color: $grey-dark;
            background-color: $grey-light;
            border: 1px solid $grey-light;
            border-radius: 14px;
            cursor: pointer;
            margin-right: 7px;

            .item-edit-icon {
                width: 10px;
                height: 10px;
            }

            &:hover {
                border-color: $purple-dark;
                .item-edit-icon {
                    color: $purple-dark;
                }
            }
        }
    }

    &:hover {
        .item-edit-icon-wrapper {
            display: flex;
        }
    }

    .dates-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 20px 0px;

        a {
            text-decoration: none;
        }

        .row-heading {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.19px;
            color: $blue-darkest;
        }

        .row-subheading {
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.17px;
            margin-top: 8px;
            color: $grey-dark;
        }

        .dates-wrapper {
            display: flex;
            align-items: center;
            margin-left: 10px;
            white-space: nowrap;

            .material-icons {
                font-size: 18px;
                color: $blue-darkest;
            }

            .dates {
                font-size: 14px;
                margin-left: 8px;
                letter-spacing: 0.18px;
                color: $blue-darkest;
            }
        }
    }
}
