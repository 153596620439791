@import "style-constants.scss";

.candidate-initial-journey-mobile-stepper {
    display: grid;
    justify-content: center;
    gap: 12px;
    background-color: transparent;
    overflow-x: scroll;
    grid-auto-flow: column;
    
    .step-connector {
        flex: unset;

        span {
            border: none;
        }
    }

    &.MuiStepper-root {
        padding: 0 0 29px 0;
    }
    
    .step-container {
        border-radius: 4px;
        width: 118px;
        min-width: 118px;
        height: 89px;
        padding: 0;
        box-shadow: 0px 0px 10px $black-grey-shadow;
        background-color: $white;

        .step-label {
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            color: $purple-light-nav;
            padding: 11px 14px;
            cursor: pointer;

            .MuiStepLabel-labelContainer {
                width: 50%;
            }

            .MuiStepLabel-label {
                font-size: 14px;
                font-family: "Roboto";
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: $purple-light-nav;
            }

            .step-box {
                margin-bottom: 7px;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                border: 1px solid $purple-light-nav;
                border-radius: 4px;

                svg {
                    width: 10px;
                }
            }

            &.selected {
                border-radius: 4px;
                box-shadow: 0px 0px 10px $black-grey-shadow;
                background-color: $purple-dark;
            }
        }
    }
        
    justify-content: flex-start;
    width: 100%;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

.candidate-initial-journey-mobile-stepper::-webkit-scrollbar {
    width: 0px;
    display: none;
}
