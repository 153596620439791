@import 'style-constants.scss';


.pdf-viewer-dialog {
    .dialog-title {
        display: flex;
        justify-content: flex-end;
    }

    .pdf-file-name {
        color: #7E7E7E;
        font-size: 16px;
    }

    .pdf-file-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 420px;
    }

    .dialog-content {
        width: 100%;
        padding: 0 40px 30px 40px;
    }

    .MuiDialog-paperScrollPaper {
        width: $paper-element-m-plus;
        max-width: none;
    }

    .rpv-core__page-layer--single {
        margin: 0;
    }

    .rpv-core__page-layer {
        width: 100% !important;
    }

    .rpv-core__viewer {
        border: 5px solid $grey-dark;
        min-height: 840px;
        margin-bottom: 20px;
    }
}
