.custom-switch {
    margin-right: 14.71px;
    width: 28.5px;
    height: 16.5px;
    display: flex;
    padding: 0;

    .MuiSwitch-switchBase {
        color: #fafafa;
        padding: 2px;

        &.Mui-checked {
            transform: translateX(12px);
            color: #fafafa;
        }
    }

    span.MuiSwitch-switchBase.Mui-checked + span.MuiSwitch-track {
        opacity: 1;
        background-color: #16D696;
        border-color: #FBFBFB;
    }

    .MuiSwitch-track {
        border: 1px solid #fff;
        border-radius: 8px;
        background-color: #CBCBCB;
        opacity: 1;
    }

    .MuiSwitch-thumb {
        width: 13px;
        height: 13px;
        box-shadow: none;
    }
}

.custom-switch-control {
    margin-left: 0px;
}
