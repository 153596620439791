@import "style-constants.scss";

.add-tech-container {
    .add-skill-form {
        display: flex;
        align-items: center;

        .tech-input div {
            color: #7e7e7e;
            border: 1px solid #7e7e7e;
            border-radius: 4px;
        }

        .add-tech-button {
            cursor: pointer;
            width: 62px;
            height: 32px;
            background-color: #16d696;
            border: 2px solid #16d696;
            color: white;
            border-radius: 5px;
            margin-left: 20px;
            align-self: center;
        }

        .add-skill-input {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;

            label {
                background: white;
                padding: 0 4px;
            }
        }

        fieldset {
            display: none;
        }
    }

    .tech-skills-container {
        margin: 10px 0;
    }
}
