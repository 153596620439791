@import "style-constants.scss";

.candidate-customer-support-request-container {
    .candidate-customer-support-form-content {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 10px;
        padding: 30px 40px;
        background-color: $white;
        margin-bottom: 30px;
    }

    .title {
        font-size: $medium-title;
        font-weight: bold;
        letter-spacing: 0.61px;
        color: $purple-dark;
        margin-bottom: 20px;
    }

    .helper-text {
        font-size: $medium;
        font-weight: normal;
        letter-spacing: 0px;
        color: $purple-dark;
        margin-bottom: 40px;
    }

    .candidate-customer-support-request-form {
        display: grid;
        gap: 40px;

        .candidate-customer-support-request-field {
            font-size: $medium;
            letter-spacing: 0.15px;
            color: $blue-darkest;

            fieldset {
                border-color: $grey-dark-text;
                border-width: 1px;
            }

            label {
                color: $grey-dark-text;
            }
        }

        .description {
            
            textarea {
                white-space: pre-wrap;
                
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
    }
}
