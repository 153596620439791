@import "style-constants.scss";

.flag-container {
    display: flex;
    align-items: center;

    .flag {
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        margin-right: 5px;

        &.none {
            border: 1px solid $grey-lighter;
        }

        &.missing-timesheet {
            background-color: $red-darker;
        }

        &.approved-for-sending {
            background-color: $secondary-color-300;
        }

        &.sent-to-client {
            background-color: $grey-lighter;
        }

        &.awaiting-approval {
            background-color: $flag-awaiting-approval;
        }

        &.missing-information-from-acc-manager {
            background-color: $flag-missing-account-manager;
        }
    }

    .flag-name {
        margin-left: 10px;
    }

    .cut-text {
        .MuiOutlinedInput-input {
            max-width: 70px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .flag-edit-button {
        padding: 5px;

        .edit-sharp-icon {
            width: 20px;
        }
    }
}

.flag-tooltip-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.change-status-dialog {
    .MuiDialog-paper {
        width: 517px;
        padding: 0 16px; 
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: #7E7E7E;
        font-size: 16px;
    }
    
    .dialog-actions {
        margin-bottom: 22px;
        padding-right: 0;
    }

    .MuiDialogContent-root.dialog-content {
        overflow-y: 'hidden'; 
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
    }

    .MuiDialogContent-root.dialog-content::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
}
