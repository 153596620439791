@import "style-constants.scss";

.employees-table-container {
    .status-cell {
        display: flex;
        align-items: center;

        .edit-status-button {
            margin-left: 5px;
            width: 12px;
            height: 12px;

            .edit-sharp-icon {
                width: 15px;
            }
        }
    }

    .user-link {
        text-decoration: none;
        color: $grey-dark;
        cursor: pointer;

        .name-text {
            cursor: pointer;
        }
    }

    .user-link:hover {
        text-decoration: none;
        color: $grey-dark;
        cursor: pointer;
    }

    .status-cell {
        display: flex;
        justify-content: flex-start;
        font-weight: 500;
        width: fit-content;

        .Billable {
            padding: 2px 9px;
            border-radius: 4px;
            background-color: rgba($secondary-color, 0.1);
            color: $secondary-color;
        }

        .Non-billableProject {
            padding: 2px 9px;
            border-radius: 4px;
            background-color: rgba($primary-color, 0.1);
            color: $primary-color;
        }

        .Bench {
            padding: 2px 9px;
            border-radius: 4px;
            background-color: rgba($red-darker, 0.1);
            color: $red-darker;
        }

        .ProjectEnds {
            padding: 2px 9px;
            border-radius: 4px;
            background-color: rgba($orange, 0.1);
            color: $orange;
        }

        .LeadershipTeam {
            padding: 2px 9px;
            border-radius: 4px;
            background-color: rgba($grey-background, 0.5);
            color: $grey-lighter;
        }
    }
}