@import "style-constants.scss";

.options-popover {
    border-radius: 4px;
    box-shadow: 0px 1px 10px $grey-darker-button;

    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 8px;
    }

    .MuiDivider-root {
        margin-bottom: 10px;
    }

    .popover-list {
        width: 205.45px;
        padding: 22.2px 20.32px 21.62px 20.13px;
        box-shadow: 0px 1px 3px $grey-shadow;
       
        a {
            text-decoration: none;
        }

        .popover-list-item {
            padding: 0;
            margin-bottom: 16.23px;
            color: $grey-normal-title;

            .info-icon {
                font-size: 11px;
                fill: $grey-normal-title;
                position: absolute;
                right: 28px;
                top: 0px;
            }

            .popover-icon {
                display: flex;
                margin-right: 10px;
            }

            .popover-text {
                align-items: center;
                font-size: 14px;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.24px;
            }
        }
    }
    .status-options {
        margin-top: 16.4px;
        width: 164px;
        height: 30px;
        outline: 1px solid #dddddd;
        border-radius: 4px;
        display: flex;

        button {
            flex-basis: 50%;
            border: none;
            letter-spacing: 1.25px;
            font-size: $small;
            color: $grey-dark;
            text-transform: capitalize;
            cursor: pointer;
        }

        .white-bg {
            background-color: $white;
        }

        .green-bg {
            background-color: $secondary-color-300;
            color: $white;
            box-shadow: 0px 3px 6px $black-grey-shadow;
            border-radius: 4px;
        }

        .grey-bg {
            box-shadow: 0px 3px 6px $black-grey-shadow;
            border-radius: 4px;
        }
    }
}
