@import 'style-constants.scss';

.track-time-upload-file-dialog {
    position: relative;

    .track-time-upload-image {
        position: absolute;
        left: 220px;
        top: 13px;
        width: 30px;
        cursor: pointer;
    }

    .track-time-upload-text-field {
        width: 265px;
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: $grey-dark;
        font-size: 16px;
    }

    .dialog-actions {
        display: flex;
        justify-content: flex-end;
        padding: 0; 
    }

    .upload-files-form {
        margin: 0px 36px;
    }
}
