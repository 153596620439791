@import 'style-constants.scss';

.navigation {
    font-size: $small;
    width: $navigation-width;
    transition: width 0.3s;
    z-index: 1003;
    transform: translate3d(0, 0, 0);

    .MuiDrawer-paper {
        position: relative;
        white-space: nowrap;
        background-color: $purple-dark;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        overflow: hidden;
    }

    .navigation-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 9.5px 7px 25px;
        transition: all 0.3s;

        .navigation-header-content {
            display: flex;
            align-items: center;
            transition: all 0.3s;
            max-height: 200px;
            margin-bottom: 6px;
            opacity: 1;
            visibility: visible;
            white-space: pre-line;
        }

        .company-logo {
            width: 33px;
            height: 33px;
            margin-right: 14px;
            background-color: $white;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .header-title {
            font-size: $small;
            font-weight: bold;
            line-height: 19px;
            letter-spacing: 0.24px;
            color: $white;
            width: 99px;
        }

        .navigation-header-divider-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .header-divider {
                width: 146px;
                height: 1px;
                background-color: $white;
            }

            .header-arrow-icon {
                cursor: pointer;
                color: $white;
            }
        }

        .MuiAvatar-colorDefault {
            background-color: $grey-avatar-background;
        }
    }

    .list-item {
        color: $white;
        text-transform: uppercase;
        padding: 15px 9.5px 15px 25px;
        transition: padding 0.3s;
        position: relative;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;

        span {
            font-size: $small;
            line-height: 19px;
            letter-spacing: 0.24px;
        }

        .MuiListItemText-root {
            margin: 0;
            min-width: 125.5px;
        }

        &.expandable {
            .MuiListItemText-root {
                min-width: 101.5px;
            }
        }

        &.Mui-selected {
            background-color: $purple-darker;

            &.expanded {
                border-bottom-right-radius: 0px;
            }
        }
    }

    .list-item-icon-wrapper {
        display: flex;
        width: 33px;
        margin-right: 14px;
    }

    .list-item-icon {
        color: $white;
        width: 24px;
        height: 24px;

        svg {
            width: 100%;
            height: auto;

            &:not(.talent-icon):not(.hottest-talents-icon) path {
                fill: currentColor;
            }
        }
    }

    .MuiListItemIcon-root {
        min-width: 24px;
    }

    .navigation-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 9.5px 30px 25px;
        transition: all 0.3s;
        margin-top: auto;

        img {
            width: 64px;
            transition: width 0.3s;
            height: auto;
        }
    }

    .additional-menu-btn-icon {
        margin-right: 13px;
        padding-top: 10px;
        padding-bottom: 10px;

        &.grey {
            color: $purple-light-nav;
        }

        &.blue {
            color: $primary-color-400;
        }
    }

    .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
        white-space: normal;
    }

    .navigation-footer-mobile-wrapper {
        display: none;
    }

    .close-mobile-navigation-button {
        display: none;
    }

    @media screen and (min-width: $desktop) {
        &.navigation-close .navigation-header {
            .header-title {
                display: none;
            }

            .header-divider {
                width: 32px;
            }

            .header-arrow-icon {
                transform: rotate(180deg);
            }
        }

        &.navigation-close {
            width: $navigation-width-closed;

            .list-item {
                padding: 15px 9.5px 15px 30px;

                .MuiListItemText-root {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            .list-item-icon-wrapper {
                margin-right: 3px;
            }
        }

        &.navigation-close .navigation-footer {
            padding: 20px 20px 30px 18px;
            justify-content: center;
            flex-direction: column-reverse;

            .additional-menu-btn-icon {
                margin-right: 0px;
                padding-top: 13px;
                padding-bottom: 13px;
            }

            >img {
                width: 52px;
                height: auto;
            }

            >div {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        width: 100%;
        position: relative;
        z-index: 2000;
        background-color: $purple-dark;

        .MuiDrawer-paper {
            height: auto;
        }

        .close-mobile-navigation-button {
            display: flex;
            position: absolute;
            right: 32px;
            top: 36px;
            z-index: 2001;
            cursor: pointer;

            svg {
                width: 28px;
                height: 28px;
                color: $purple-light-nav;
            }
        }

        .navigation-header {
            margin-bottom: 6px;
            padding: 36px 0px 7px 48px;
            position: relative;

            .navigation-header-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
            }

            .header-title {
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 4px;
                width: 100%;
                color: $grey-light;
            }

            .company-logo {
                width: 79px;
                height: 79px;
                margin-bottom: 13px;
                margin-right: 0;
            }

            .navigation-header-divider-wrapper {
                display: none;
            }
        }

        .nav-main-list {
            padding-bottom: 0;
        }

        .list-item {
            padding: 10px 38px 10px 38px;
            position: relative;
            transition: padding .3s;
            color: $purple-light-nav;
            text-transform: none;
            border-radius: 0;

            .MuiListItemText-root {
                margin: 4px 0px;
            }

            span {
                font-size: $medium-title;
                line-height: 1.5;
            }
        }

        .nested-list .nested-list-item {
            span {
                font-size: $medium-title;
                font-weight: 400;
                line-height: 1.5;
            }
        }

        .list-item-icon-wrapper {
            display: none;
        }

        .list-item-icon {
            display: none;
        }

        .navigation-footer {
            display: none;
        }

        .additional-menu-btn-icon {
            display: none;
        }

        .navigation-footer-mobile-wrapper {
            display: block;
        }

        &.navigation-mobile-hide {
            display: none;
        }
    }
}