@import 'style-constants.scss';

.reject-holiday-dialog {
    .MuiDialog-paper {
        width: 510px;
        padding: 0 16px;
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dialog-title-text {
        color: $grey-dark;
        font-size: 16px;
    }

    .dialog-actions {
        margin-top: 22px;
        margin-bottom: 14px;
        padding-right: 0;
    }

    .profile,
    .request-details {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        color: $grey-normal-title;
    }

    .profile-name {
        margin-left: 18px;
        font-size: 20px;
        color: $primary-color-400;
    }

    .request-details-value {
        color: $primary-color-400;
        margin-right: 20px;
    }

    .request-details-days,
    .request-details-no-days {
        color: $primary-color-400;
    }

    .red {
        color: red;
    }

    .request-reason {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        padding-bottom: 28px;
        color: $grey-normal-title;
        border-bottom: 0.5px solid $grey-light-amount;
    }

    .avatar-logo-image {
        background-color: #edecec;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
