@import "style-constants.scss";

.client-customer-support-request-container {
    .client-customer-support-request-title {
        font-size: $medium;
        font-weight: 500;
        letter-spacing: 0.11px;
        color: $purple-dark;
        padding-bottom: 20px;
    }

    .client-customer-support-form-content {
        box-shadow: 0px 0px 10px #79797929;
        border-radius: 10px;
        padding: 30px 40px;
        background-color: $white;
    }

    .client-customer-support-icon-wrapper {
        display: flex;
        margin: auto;
        align-items: center;
        margin-left: 40px;
    }

    .content-title {
        font-size: $large-title;
        font-weight: bold;
        letter-spacing: 0.34px;
        color: $purple-dark;
        margin-bottom: 20px;
    }

    .content-helper-text {
        font-size: $medium;
        letter-spacing: 0.11px;
        color: $purple-dark;
        margin-bottom: 20px;
    }

    .client-customer-support-request-form {
        display: grid;
        gap: 20px;

        .client-customer-support-request-field {
            font-size: $medium;
            letter-spacing: 0.15px;
            color: $grey-dark;

            fieldset {
                border-color: $grey-dark-text;
                border-width: 1px;
            }

            label {
                color: $grey-dark-text;
            }
        }

        .description {
            textarea {
                white-space: pre-wrap;

                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
    }
}