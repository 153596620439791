@import "style-constants.scss";

.job-details-wrapper {
    position: relative;
    padding-top: 53px;

    .job-details-header {
        display: flex;

        .job-main-details {
            display: grid;
            align-items: center;
            gap: 10px;
            width: 100%;
        }

        .job-image {
            width: 55px;
            height: 55px;
            margin-right: 13px;
            background-color: $grey-progress;

            .MuiAvatar-img {
                object-fit: contain;
            }
        }

        .row-items {
            display: grid;
            justify-content: space-between;
            align-items: center;
            grid-auto-flow: column;

            &.justify-start {
                gap: 20px;
                justify-content: flex-start;
            }
        }

        .row-item {
            display: grid;
            gap: 8px;
            justify-content: flex-start;
            align-items: center;
            grid-auto-flow: column;

            &.location {
                align-items: flex-start;
                padding-top: 10px;
            }

            font-size: $medium;
            line-height: 21px;
            letter-spacing: 0.28px;
            color: $purple-dark;

            a {
                text-decoration: none;
                color: inherit;
            }

            svg path {
                fill: $purple-dark;
            }
        }

        .job-position-wrapper {
            .job-position-link {
                text-decoration: none;
            }

            .job-position {
                color: $purple-dark;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.26px;
            }
        }


        .job-details-actions {
            display: grid;
            align-items: center;
            grid-auto-flow: column;
            gap: 30px;

            .share-button-action {
                cursor: pointer;
                width: 18px;
                height: 21px;
            }

            .job-three-dots-action {
                color: $grey-dark;
            }

            .job-three-dots-action:hover {
                cursor: pointer;
            }
        }
    }

    .job-details-profiles {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 81px;
        background-color: $grey-background-light;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 0 40px;
        position: relative;
        margin: 30px -38px;

        .flex-wrapper {
            display: flex;
            align-items: center;
        }

        .total-profiles-count {
            display: grid;
            gap: 13px;
            grid-auto-flow: column;
            margin-right: 60px;

            .total-profiles-count-number {
                text-align: left;
                font-size: 35px;
                font-weight: 900;
                line-height: 21px;
                letter-spacing: 0.61px;
                color: $purple-dark;
            }
        }

        .candidates-statuses-count-boxes {
            position: relative;
            display: grid;
            gap: 40px;
            grid-auto-flow: column;
            justify-content: space-evenly;
            align-items: center;
        }

        .job-state-details {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            gap: 15px;

            .box {
                padding: 4px 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                font-size: $small;
                font-weight: 500;
                text-align: center;
                text-transform: capitalize;

                &.open-status {
                    background-color: rgba($secondary-color-300, 0.1);
                    color: $actions-green;
                }

                &.published-privacy {
                    background-color: rgba($primary-color-700, 0.1);
                    color: $primary-color-700;
                }

                &.high-priority {
                    background-color: rgba($red-darker, 0.1);
                    color: $red-darker;
                }

                &.medium-priority {
                    background-color: rgba($yellow, 0.1);
                    color: $yellow;
                }

                &.low-priority,
                &.closed-status,
                &.unpublished-privacy {
                    background-color: $background-label;
                    color: $grey-dark;
                }
            }
        }
    }

    .job-section {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 15px;
        }

        .job-section-title {
            color: $purple-dark;
            font-size: 18px;
            font-weight: 600;
        }

        .job-section-text {
            color: $purple-dark;
            font-size: 16px;
            font-family: Roboto;
            white-space: pre-line;
        }

        .skills-container {
            display: flex;
            flex-wrap: wrap;

            .skill-container {
                align-items: center;
                padding: 7px 14px;
                border: 1px solid #dddddd;
                border-radius: 4px;
                margin-bottom: 10px;
                margin-right: 15px;
                background-color: white;
                cursor: pointer;

                .skill {
                    color: $grey-dark-text;
                    width: calc(100% - 23px);
                    display: inline;
                    font-size: 14px;
                }
            }
        }
    }
}
