@import 'style-constants.scss';

.require-action-banner {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 0px 10px $grey-medium-shadow;
    padding: 30px 40px;
    margin: 0px auto 30px;
    display: flex;
    justify-content: space-between;
    max-width: 936px;

    .require-action-banner-content {
        display: grid;
        gap: 10px;

        .require-action-banner-title {
            font-size: $medium-title;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.61px;
            color: $purple-dark;
        }

        .require-action-banner-sub-title {
            font-size: $small;
            line-height: 16px;
            letter-spacing: 0px;
            color: $purple-dark;
        }

        .require-action-banner-view-btn {
            width: fit-content;
            margin-top: 8px;
        }
    }

    .require-action-banner-icon-wrapper {
        display: flex;
        height: fit-content;
        margin-top: -40px;
    }
}
