@import "style-constants.scss";

.job-details-page-wrapper {
    position: relative;

    .job-opportunity-details {
        margin-top: 33px;
        padding: 30px 40px 30px 40px;

        pre {
            font-family: "Roboto", sans-serif;
            color: #7e7e7e;
            font-size: 16px;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
        }

        p {
            font-family: "Roboto", sans-serif;
            color: #7e7e7e;
            font-size: 16px;
        }

        .job-card-privacy {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 130px;
            height: 40px;
            font-size: 16px;
            font-weight: 500;
            padding: 5px 10px;

            &.not-visible {
                opacity: 0;
            }

            .edit-privacy-button {
                margin-left: 10px;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .edit-privacy-icon {
                display: block;
                margin: 3px auto;
            }
        }

        .job-privacy-wrapper {
            position: relative;
            top: -30px;
            left: -40px;
            display: flex;
            justify-content: space-between;
        }

        .Published {
            background-color: $secondary-color;
            color: $white;

            .edit-privacy-icon {
                path {
                    stroke: $white;
                }
            }
        }

        .Draft {
            background-color: $grey-light-amount;
            color: $grey-dark;
        }

        .job-opportunity-details-card-header {
            display: flex;
            margin-bottom: 16px;

            .job-image-wrapper {
                margin-right: 15px;
                margin-right: 15px;
                img {
                    border-radius: 4px;
                    width: 86px;
                    height: 88px;
                    object-fit: contain;
                    text-align: center;
                    text-indent: 10000px;
                }
            }

            .job-opportunity-details-card-header-information-wrapper {
                width: 100%;

                .title-block {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .title {
                        color: $primary-color-400;
                        font-size: 20px;
                        font-weight: 600;
                        width: calc(100% - 76px);
                        padding-right: 20px;
                    }

                    .apply-to-job-opportunity {
                        background-color: $actions-green;
                        width: 76px;
                    }
                }

                .information-content {
                    display: flex;
                    padding: 20px 0;

                    .information-container {
                        display: flex;
                        align-items: center;
                        margin-right: 45px;

                        :first-child {
                            margin-right: 10px;
                        }

                        .information-icon {
                            color: #7e7e7e;
                        }
                    }
                }
            }

            & + .MuiDivider-middle {
                margin: 0;
            }
        }

        .details-block {
            padding: 23px 0px;

            &:last-child {
                padding-bottom: 0;
            }

            & + hr {
                margin: 0;
            }

            .job-section {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 15px;
                }
                .job-section-title {
                    color: #0d37c9;
                    font-size: 20px;
                    font-weight: 600;
                }
                sup {
                    color: $red-darker;
                }

                .skills-container {
                    display: flex;

                    .skill-container {
                        align-items: center;
                        padding: 7px 14px;
                        border: 1px solid #7e7e7e;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        margin-right: 15px;
                        background-color: white;
                        cursor: pointer;

                        .skill {
                            color: #7e7e7e;
                            width: calc(100% - 23px);
                            display: inline;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .job-opportunity-banner {
        margin-top: 41px;
        border-radius: 5px;

        position: relative;
        background-color: #e3e4f1;
        display: flex;
        justify-content: center;

        .three-stars {
            position: absolute;
            top: 6%;
            left: 1.5%;
        }

        .two-stars {
            position: absolute;
            top: 6%;
            right: 10%;
        }

        .one-star {
            position: absolute;
            top: 70%;
            right: 2%;
        }

        .banner-content {
            display: flex;
            max-width: 70%;

            .banner-action-container {
                margin-right: 100px;
                margin-top: 6%;

                .request-button {
                    background-color: $actions-green;
                    margin-top: 20px;
                }
            }

            .puzzle {
                margin-top: -3.5%;
                flex-grow: 1;
                flex-shrink: 0;
            }
        }
    }
    .job-edit-button-container {
        position: relative;
        left: 80px;
        .job-edit-button {
            background-color: $grey-light;
            width: 35px;
            height: 35px;

            .edit-sharp-icon {
                width: 20px;
                height: 20px;
                margin-bottom: 5px;
            }
        }
    }
    .job-opportunity-loader {
        position: absolute;
        top: 250px;
        left: 50%;
    }
}
