@import 'style-constants.scss';

.recruiter-employee-profile-wrapper {

  .additional-actions-wrapper {
    margin-bottom: 0px;
    display: flex;

    .switch-block {
      display: flex;
      flex: 1 1 60%;

      .switch {
        display: inline-block;
        font-family: "Roboto";
        font-size: 12px;
        color: #7E7E7E;

        &.currently-available {
          padding-left: 35.96px;
        }
      }
    }

    .buttons-wrapper {
      margin-left: auto;
      width: 40%;
      display: flex;
      justify-content: space-between;
    }
  }
}
