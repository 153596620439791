@import 'style-constants.scss';

.project-dialog {

    .project-start-date-field {
        margin-bottom: 20px;
    }

    .project-dates-options {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;

        .custom-switch-control {
            flex-direction: row;
            margin-right: 0px;

            .custom-switch {
                height: 17px;
                margin-left: 0px;
                margin-right: 6px;
            }

            &:not(:first-child) {
                margin-left: 16px;
            }

            .MuiSwitch-switchBase {
                padding: 0;
                margin: 3px;

                &.Mui-checked {
                    transform: translateX(11px);
                }
            }

            .MuiSwitch-thumb {
                width: 11px;
                height: 11px;
            }

            .MuiFormControlLabel-label {
                font-size: 14px;
                font-weight: 300;
                line-height: 19px;
                color: $grey-dark-text;
            }
        }
    }
}
