@import "style-constants.scss";

.register-wrapper {
    &.mobile {
        overflow-y: scroll;
        height: calc(100% - 180px);

        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
    }

    .register-main-content-container {
        margin-bottom: 100px;
        margin-top: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 50px;
    }

    .register-form {
        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }

        .pwd-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 22px;

            .remember-pwd-checkbox.Mui-checked {
                color: $secondary-color-300;
            }

            .remember-pwd-label .MuiFormControlLabel-label {
                font-size: 14px;
                letter-spacing: 0.24px;
                color: #00000099;
            }

            .forgotten-pwd-link {
                text-decoration: underline;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.18px;
                color: $primary-color-900;
            }

            @media screen and (max-width: $mobile) {
                margin-top: 0px;
            }
        }

        @media screen and (max-width: $mobile) {
            .register-field {
                width: 100%;
            }
        }
    }

    .register-card-media {
        width: 142px;
        background-size: contain;
        margin: auto;
        margin-bottom: 20px;

        @media screen and (max-width: $mobile) {
            width: 83px;
        }

        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }
    }

    .title {
        color: $purple-dark;
        font-size: $large-title;
        font-weight: 800;
        letter-spacing: 0px;

        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }
    }

    .subtitle {
        color: $purple-dark;
        font-size: $medium;
        letter-spacing: 0px;
        text-align: left;
        width: 100%;

        &.blur {
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }
    }

    .sign-up-mobile-btn {
        margin: 20px 0;
        width: 100%;
    }

    .sign-up-link {
        color: $grey-dark;
        font-size: $medium;
        margin-top: 15px;

        a {
            color: $primary-color-700;
            font-weight: 800;
        }
    }

    .terms-of-use {
        color: $grey-dark;
        margin: 20px 0 50px 0;
        text-align: center;
    }

    .footer {
        z-index: 2;
        position: fixed;
        text-align: center;
        width: 100%;
        top: calc(100% - 62.88px);
        left: 0;
        color: $grey-dark;
        font-size: $medium;
        letter-spacing: 1.43px;
        box-shadow: 0px -6px 20px #0000001a;
        border-radius: 40px 40px 0px 0px;
        background-color: $light-shade-blue;

        .candidate-footer-content {
            padding: 40px 10px 0;
        }

        .benefits-list {
            margin-top: 30px;

            .item {
                list-style-type: none;
                display: grid;
                grid-auto-flow: column;
                gap: 10px;
                justify-content: flex-start;
            }

            .item-text {
                display: flex;
                align-self: center;
                text-align: left;
                font-size: $medium;
                letter-spacing: 0.11px;
                color: $purple-dark;
                opacity: 1;
            }
        }

        .footer-content-title {
            color: $purple-dark;
            text-transform: uppercase;
            letter-spacing: 0px;
            font-size: $medium;
            font-weight: 500;
        }

        .partner-logos {
            margin-top: 25px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 13px;
            margin-left: 8px;

            .image-wrapper {
                width: 137px;
            }

            .logo-image {
                display: flex;
                align-self: center;
                margin-bottom: 15px;
            }
        }

        &.expand {
            animation: 1s fadeIn;
            animation-fill-mode: forwards;
        }

        &.shrink {
            animation: 1s fadeOut;
            animation-fill-mode: forwards;
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .back-btn {
            color: $grey-dashed-border;
            text-align: left;
            font-size: $large-title;
            letter-spacing: 0px;

            &:hover {
                cursor: pointer;
            }
        }

        .next-btn {
            color: $primary-color-700;
            text-align: left;
            font-size: $large-title;
            letter-spacing: 0px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    // animations below
    @keyframes fadeIn {
        0% {
            top: calc(100%);
        }
        100% {
            top: calc(100% - 400px);
        }
    }

    @keyframes fadeOut {
        0% {
            top: calc(100% - 400px);
        }
        100% {
            top: calc(100% - 62.88px);
        }
    }
}
