@import "style-constants.scss";

.slider-wrapper {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;

    .slides-container {
        white-space: nowrap;
        transition: ease 1000ms;

        .slide {
            display: inline-block;
            padding: 5px;

            height: 400px;
            width: 100%;
            border-radius: 40px;

            cursor: grab;
        }
    }

    .slideshow-dot {
        display: inline-block;
        height: 12px;
        width: 12px;
        border-radius: 50%;

        cursor: pointer;
        margin: 40px 4px 0px;

        background-color: $primary-color-blue-lighter;
        opacity: 0.3;
    }

    .slideshow-dot.active {
        opacity: 1;
    }
}
