@import "style-constants.scss";

.profile-info-content-wrapper {
    margin-bottom: 20px;

    .profile-info-wrapper {
        margin-bottom: 20px;

        .actions-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;

            .action-buttons {
                display: flex;
                justify-content: space-between;
                height: 30px;
                width: 180px;
            }

            .save-button,
            .basic-info-save-button {
                margin-right: 20px;
            }
        }

        .profile-info-content {
            padding: 40px;
            border-radius: 10px;
        }
    }
}

.profile-info-content-wrapper::-webkit-scrollbar {
    width: 0px;
    display: none;
}
