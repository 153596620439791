@import 'style-constants.scss';

.attached-files-container {
    overflow-wrap: break-word;

    .attached-list-title {
        color: $grey-dark;
        font-size: 16px;
        letter-spacing: 0.15px;
        margin-bottom: 2px;
    }

    .MuiListItem-container {

        .MuiListItem-root {
            padding-left: 0px;
            padding-bottom: 5px;
            color: #0415AC;

            .MuiListItemAvatar-root {
                min-width: 40px;
                color: $actions-green;
            }

            @media screen and (max-width: $mobile) {
                .MuiListItemText-root {
    
                    .MuiListItemText-primary {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 150px;
                        font-size: 14px;
                    }
                }
            }
        }

        .MuiListItemSecondaryAction-root {
            right: 8px;

            button {
                padding: 0px;
            }
        }
    }
}
