@import "style-constants.scss";

.invoice-card-wrapper {
    width: 100%;
    background-color: $white;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 10px;

    .invoice-card-link-wrapper {
        text-decoration: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cell {
        display: table-cell;
        padding: 40px 10px;
        vertical-align: middle;

        &:first-child {
            padding-left: 40px;
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            padding-left: 10px;
            padding-right: 40px;
            border-radius: 0 4px 4px 0;
        }
    }

    .invoice-project-name-wrapper {
        font-size: $medium-title;
        font-weight: bold;
        line-height: 30px;
        letter-spacing: 0.23px;
        color: $purple-dark;
    }

    .invoice-number-wrapper span,
    .invoice-dates-wrapper span {
        cursor: default;
    }

    .invoice-number-wrapper,
    .invoice-dates-wrapper,
    .invoice-amount-wrapper {
        font-size: $medium;
        line-height: 19px;
        letter-spacing: 0.15px;
        color: $medium-dark-shade-blue;
    }

    .invoice-status-label {
        border-radius: 4px;
        padding: 4px 8px;
        text-align: center;
        font-size: $small;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.24px;
        width: fit-content;

        &.pending {
            background-color: rgba($grey-dark, 0.1);

            span {
                color: $grey-dark;
            }
        }

        &.overdue {
            background-color: rgba($red-darker, 0.1);

            span {
                color: $red-darker;
            }
        }
    }

    .actions-icon-wrapper {
        display: flex;
        justify-content: center;
        width: fit-content;
        margin: 0 auto;

        svg {
            color: $grey-dark;
        }

        &:hover {
            cursor: pointer;
            border-radius: 4px;
            background-color: rgba($grey-dark, 0.1);
        }
    }
}