@import "style-constants.scss";

.upcoming-interviews-card-wrapper {
    padding: 30px 40px 40px 40px;
    width: 48%;

    .upcoming-interview-content {
        border-bottom: $grey-light-amount solid 1px;
        padding-bottom: 15px;

        .upcoming-interview-title {
            color: $grey-dark;
            font-size: 14px;
        }

        .upcoming-interview-text {
            color: $primary-color-400;
            font-size: 14px;
        }

        .upcoming-interview-main-title {
            font-size: 20px;
            font-weight: 550;
            color: $primary-color-400;
        }

        .upcoming-interview-content-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;

            .upcoming-interview-company-container,
            .upcoming-interview-position-container {
                display: flex;
                gap: 5px;
            }
        }

        .upcoming-interview-location-container {
            display: flex;
            gap: 5px;
            margin-top: 15px;
        }
    }

    .upcoming-interview-date-wrapper {
        background-color: #0D37C9;
        color: $white;
        margin-top: 15px;

        .upcoming-interview-date-container {
            display: flex;
            justify-content: space-evenly;
            padding: 17px 0;

            .upcoming-interview-content-container {
                display: flex;
                gap: 10px;
            }
        }

        .upcoming-interview-icon {
            rect, path {
                fill: white;
            }
        }

        .upcoming-interview-icon.date {
            width: 20px;
        }

        .upcoming-interview-icon.hour {
            width: 30px;
            margin: 0 -5px;
        }
    }
    
    .details-button-container {
        margin-top: 37px;

        .details-button {
            display: block;
            margin: 0 auto;
        }
    }
}
