@import "style-constants.scss";

.profile-fit-table-wrapper {
    margin-top: 30px;
    position: relative;

    .matching-consultants-loader {
        position: absolute;
        left: 50%;
        top: 250%;
    }

    .no-candidate-found {
        padding: 0px 0 50px;
        margin-top: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .icon { width: 200px; height: 200px; }

        p {
            margin-top: -15px;
            width: 100%; text-align: center;
        }
    }
}

.grouped-select-filter-list-menu.matching-consultants {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 25px;
    }

    .MuiMenu-list {
        width: 277px;
    }
}
