@import "style-constants.scss";

.unfinished-profile-warning {
    position: fixed;
    margin: auto;
    background: $white;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 4px;
    max-width: 21.62rem;
    z-index: 1;
    .inner {
        padding: 1.5rem 1.86rem 1.77rem 1.86rem;
        display: flex;
        flex-direction: column;
        .header {
            display: flex;
            align-items: flex-end;

            .warning-icon,
            p {
                color: $red-darker;
            }
            p {
                margin-left: 0.5rem;
                letter-spacing: 0.29px;
                font-weight: 400;
            }
            .warning-icon {
                width: 1.8rem;
                height: 1.8rem;
            }
        }
        .message {
            color: $grey-dark-text;
            margin-top: 1.64rem;
        }
        .finishi-profile-button {
            align-self: center;
            margin-top: 1.5rem;
        }
    }
}
