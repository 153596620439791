@import "style-constants.scss";

.dashboard-section-wrapper {
    .MuiTypography-h1 {
        color: $purple-dark;
        font-size: $medium;
        font-family: Roboto;
        font-weight: bold;
        letter-spacing: 0.11px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .MuiTypography-body1 {
        font-size: $small;
        line-height: 16px;
    }

     // small desktops and mobile
     @media screen and (max-width: $small-desktop) {
        .MuiTypography-h1 {
            font-size: $smallest;
        }

        .MuiTypography-body1 {
            font-size: $tiny;
        }

        .MuiTypography-body2 {
            font-size: $tiny;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }

    .dashboard-section-content {
        width: 100%;
        margin-top: 20px;
        
        &.list {
            display: grid;
            gap: 20px;

            &.column {
                grid-auto-flow: column;
                gap: 30px;
            }
        }
    }
}
