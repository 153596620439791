@import 'style-constants.scss';

.candidate-journey-layout-wrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: auto;

    .candidate-journey-layout-body-wrapper {
        width: 100%;
        background-color: $primary-color-90;            
    }
    
    .candidate-journey-layout-content {
        position: relative;
        height: 100vh;
        overflow: auto;
        padding: 60px 30px 30px;
    }

    @media screen and (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: unset;
    
        .candidate-journey-layout-body-wrapper {
            width: 100%;
            background-color: $primary-color-90;
            overflow: auto;            

            width: 100%;
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
        }

        .candidate-journey-layout-body-wrapper::-webkit-scrollbar {
            width: 0px;
            display: none;
        }
        
        .candidate-journey-layout-content {
            height: 100vh;
            width: 100%;
            padding: 31px 16px;
            overflow: unset;
        }
    }
}
