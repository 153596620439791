@import 'style-constants.scss';

.department-members-wrapper {
    .logo-name-wrapper {
        display: flex;
        align-items: center;
        pointer-events: none;

        .avatar-logo-image {
            width: 30px;
            height: 30px;
            margin-right: 15px;
            background-color: $grey-creamy;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .MuiAvatar-colorDefault {
            background-color: $grey-creamy;
        }
    }

    .pending-holidays-count {
        display: flex;
        width: fit-content;
        padding: 2px 10px;
        border-radius: 4px;
        color: $white;
        background-color: $primary-color-400;
        text-align: center;
    }
}
