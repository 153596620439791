.holiday-allowance-wrapper {
    .inner-content-wrapper {
        padding: 40px 30px 6px 30px;

        .holiday-allowance-form {
            
            display: flex; 
            flex-wrap: wrap; 
            margin: 0 -10px;

            .holiday-allowance-input { 
                width: 50%; 
                padding: 0 10px 34px 10px;  
            }

            .MuiFormControl-root {
                width: 100%;
                color: #7E7E7E;
            }
        }
    }
}
