@import "style-constants.scss";

.paper-element-container {
    &.how-to-record-banner {
        box-shadow: 0px 1px 10px $black-grey-shadow;
    }

    &.lower-t-margin {
        margin-top: 31px;
    }

    &.no-border-radius {
        border-radius: 0px;
    }

    .paper-element-content {
        .accordion-wrapper {
            padding: 0;
        }

        .accordion-summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            min-height: 0;

            .MuiAccordionSummary-content {
                margin: 0;
            }

            .MuiAccordionSummary-expandIcon {
                padding: 0px;
                margin-right: -5px;
                color: $purple-dark;

                &.Mui-expanded {
                    transform: rotate(90deg);
                }
            }

            .col-1of2 {
                display: flex;
                align-items: center;
            }

            .light-icon-wrapper {
                margin-left: 5px;
                margin-right: 12px;
            }

            .summary-text {
                font-size: $medium;
                font-weight: normal;
                letter-spacing: 0.27px;
                color: $purple-dark;
            }
        }

        .details-wrapper {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: -30px;
        }

        .column-items {
            display: grid;
        }
        
        .info-item {
            display: flex;
            margin-bottom: 30px;
            align-items: center;

            svg {
                margin-right: 7px;
            }

            p {
                font: normal normal normal $medium Roboto;
                letter-spacing: 0px;
                color: $purple-dark;
            }
        }
    }
}
