@import 'style-constants.scss';

.performance-review-dialog {
    .dialog-close-btn {
        padding: 0;
    }

    .reviewer {
        width: 100%; 

        .Mui-disabled {
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .rating-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .rating-criteria-title {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.26px;
            color: #000000DE;
            margin: 12px 0;
        }

        .rating-scale-title {
            font-size: 12px;
            letter-spacing: 0.4px;
            color: #7E7E7E;
            margin-bottom: 30px;
        }

        .general-msg {
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $grey-dark;
            margin-bottom: 20px;
        }

        .feedback-msg {
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $red-darker;
            margin-bottom: 10px;
        }

        .settings-link {
            color: $primary-color-500;
            text-decoration: underline;
        }

        .rating-scale {
            font-size: 16px;
            letter-spacing: 19px;
            color: #7E7E7E;
            margin-left: auto;
            margin-bottom: 19.5px;
        }

        .rating-criteria-row {
            display: flex;
            justify-content: space-between;
            padding-right: 14px;

            .rating-criterion {
                font-size: 14px;
                color: #00000099;
                letter-spacing: 0.24px;
                padding-right: 16px;
                padding-left: 0px;
                margin-left: auto;
            }

            .rating-criterion-error {
                color: $red-darker;
            }

            .MuiRating-root {
                color: $primary-color-400;
            }
            
            .MuiRating-icon {
                padding: 0px 0px 23px 26px;
            }

            .MuiRating-iconActive {
                transform: scale(1);
            }
        }

        .rating-icon {
            transform: scale(0.9);
        }

    }

    .overall-performance {
        margin-bottom: 30px;
        width: 100%;
    }

    .dialog-actions {
        margin-bottom: 30px;
    }

    .invalid-field-message {
        font-size: 14px;
        text-align: right;
        color: $red-darker;
        padding-right: 14px;
        margin-top: -20px;
        margin-bottom: 20px;
    }
}
